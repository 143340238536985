import React, { ReactElement } from 'react';

import SelectAllLayoutsButton, { SelectAllLayoutsButtonProps } from '../button/select-all-layouts-button';
import GenerationStatusSelector, { GenerationStatusSelectorProps } from '../layout/layouts/generation-status-selector';
import LayoutSortBySelect, { LayoutSortBySelectProps } from '../layout/layouts/layout-sort-by-select';
import StatusSelector, { StatusSelectorProps } from '../layout/layouts/status-selector';
import LayoutActionsSelector, { LayoutActionsSelectorProps } from '../selectors/layout-actions-selector';

export type LayoutsPanelProps = StatusSelectorProps &
    GenerationStatusSelectorProps &
    LayoutSortBySelectProps &
    Omit<SelectAllLayoutsButtonProps, 'allLayoutIds'> &
    Omit<LayoutActionsSelectorProps, 'selectedLayouts'> & {
        layouts: LayoutActionsSelectorProps['selectedLayouts'];
    };

export default function LayoutsPanel({
    status,
    statusChange,
    renderingProgress,
    renderingProgressChange,
    sortBy,
    sortByChange,
    layouts,
    layoutsChange,
    selectAll,
    selectNone,
    selectedLayoutIds,
}: LayoutsPanelProps): ReactElement {
    return (
        <div className='mb-[25px] flex w-full flex-wrap'>
            <div className='block basis-[40%]'>
                <StatusSelector status={status} statusChange={statusChange} />
                <GenerationStatusSelector
                    renderingProgress={renderingProgress}
                    renderingProgressChange={renderingProgressChange}
                />
            </div>
            <div className='block basis-[60%] text-right'>
                <SelectAllLayoutsButton
                    allLayoutIds={layouts.map((l) => l.id)}
                    selectedLayoutIds={selectedLayoutIds}
                    selectAll={selectAll}
                    selectNone={selectNone}
                />
                <LayoutActionsSelector
                    layoutsChange={layoutsChange}
                    selectedLayouts={layouts.filter((l) => selectedLayoutIds.includes(l.id))}
                />
                <LayoutSortBySelect sortBy={sortBy} sortByChange={sortByChange} />
            </div>
        </div>
    );
}
