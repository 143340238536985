import React, { ReactElement } from 'react';

type Props = {
    children: ReactElement | ReactElement[] | null;
};

export default function Panel(props: Props): ReactElement {
    const { children } = props;

    return <div className='shadow-xl flex h-full w-full flex-col rounded-xl bg-white'>{children}</div>;
}
