import { QueryResult, gql, useQuery } from '@apollo/client';

import { FieldFilterDefinition, ProductsQuery, ProductsQueryVariables } from '../generated/gql/graphql';

import { ArrayElement } from './utils';

export type Product = ArrayElement<ProductsQuery['products']>;

const GET_PRODUCTS = gql`
    query Products($query: ProductQuery) {
        products(query: $query) {
            id
            name
            thumbnailUrl
            animations {
                id
                type
                productId
                name
                thumbnailUrl
                url
            }
            perspectives {
                id
                type
                productId
                name
                thumbnailUrl
                url
            }
        }
    }
`;

type Props = {
    searchQuery: string;
    filters: FieldFilterDefinition[];
};

export default function useProducts(props: Props): QueryResult<ProductsQuery> {
    const { searchQuery, filters } = props;
    return useQuery<ProductsQuery, ProductsQueryVariables>(GET_PRODUCTS, {
        variables: {
            query: {
                filter: {
                    name: {
                        contains: searchQuery,
                    },
                    fields: filters.map((filter) => ({
                        fieldName: filter.fieldName,
                        anyOf: filter.options.map((option) => option.value),
                    })),
                },
            },
        },
    });
}
