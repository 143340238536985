import React, { ReactElement, ReactNode } from 'react';

type Props = {
    icon: ReactNode;
    onClick: () => void;
};

export default function IconButton(props: Props): ReactElement {
    const { icon, onClick } = props;
    return (
        <button className='flex cursor-pointer flex-row items-center space-x-3' onClick={onClick} type='button'>
            {icon}
        </button>
    );
}
