import React, { ReactElement } from 'react';

import MainContainer from '../components/layout/main-container';
import MainHeader from '../components/layout/main-header';
import MainSidebar from '../components/layout/main-sidebar';
import Sidebar from '../components/layout/projects/sidebar';
import ViewContainer from '../components/layout/view-container';
import WorkspacesContainer from '../components/layout/workspaces/workspaces-container';
import WorkspacesScene from '../components/layout/workspaces/workspaces-scene';
import WorkspacesList from '../components/lists/project/workspaces-list';
import '../styles/accordion.css';
import useWorkspace from '../hooks/use-workspace';

export default function WorkspacesPage(): ReactElement {
    const { workspaces } = useWorkspace();

    return (
        <MainContainer>
            <MainSidebar />
            <ViewContainer>
                <MainHeader />
                <WorkspacesContainer>
                    <Sidebar>
                        <WorkspacesList />
                    </Sidebar>
                    <WorkspacesScene workspaces={workspaces} />
                </WorkspacesContainer>
            </ViewContainer>
        </MainContainer>
    );
}
