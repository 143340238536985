import React, { ReactElement, ReactNode } from 'react';

type Props = {
    icon: ReactNode;
    label: string;
    onClick: () => void;
};

export default function ResetButton(props: Props): ReactElement {
    const { icon, label, onClick } = props;
    return (
        <button
            className='flex cursor-pointer flex-row items-center space-x-3 text-[14px] uppercase'
            onClick={onClick}
            type='button'
        >
            {icon} {label}
        </button>
    );
}
