import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Box, OutlinedInput } from '@mui/material';
import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';

import Button from '../components/button/button';
import { AspectRatio } from '../components/composer/aspect-ratio';
import Modal from '../components/modal/modal';
import useLocale from '../hooks/use-locale';

type Props = {
    open: boolean;
    onClose(): void;
    setRatio: Dispatch<SetStateAction<AspectRatio[]>>;
    getRatio: AspectRatio[];
};

const initialState = {
    first: {
        x: '',
        y: '',
    },
    second: {
        x: '',
        y: '',
    },
    third: {
        x: '',
        y: '',
    },
};

export default function AspectRatioModal(props: Props): ReactElement {
    const { open, onClose, setRatio, getRatio } = props;
    const [state, setState] = useState(initialState);

    useEffect(() => {
        setState({
            first: {
                x: `${getRatio[0]?.value.x ?? ''}`,
                y: `${getRatio[0]?.value.y ?? ''}`,
            },
            second: {
                x: `${getRatio[1]?.value.x ?? ''}`,
                y: `${getRatio[1]?.value.y ?? ''}`,
            },
            third: {
                x: `${getRatio[2]?.value.x ?? ''}`,
                y: `${getRatio[2]?.value.y ?? ''}`,
            },
        });
    }, [open]);

    const { getText } = useLocale();

    function handleDelete(object: {}): void {
        setState({ ...state, ...object });
    }

    function handleSubmit(): void {
        const collectRatios: AspectRatio[] = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const [, value] of Object.entries(state)) {
            if (value.x && value.y) {
                collectRatios.push({
                    name: `${value.x}:${value.y}`.toString(),
                    value: {
                        x: Number.parseInt(value.x, 10),
                        y: Number.parseInt(value.y, 10),
                    },
                });
            }
        }

        setRatio(collectRatios);
        onClose();
    }

    return (
        <Modal open={open} headline='Set Custom Ratio' onClose={() => onClose()} className='max-w-xl'>
            <div className='mt-[20px]'>
                <Box className='w-full pb-5 text-left'>
                    <OutlinedInput
                        className='w-[55px]'
                        placeholder='-'
                        value={state.first.x}
                        onChange={(e) => setState({ ...state, first: { ...state.first, x: e.target.value } })}
                        sx={{ '& > .MuiInputBase-input': { padding: '10px' } }}
                    />
                    <span className='px-5'>:</span>
                    <OutlinedInput
                        className='w-[55px]'
                        placeholder='-'
                        value={state.first.y}
                        onChange={(e) => setState({ ...state, first: { ...state.first, y: e.target.value } })}
                        sx={{ '& > .MuiInputBase-input': { padding: '10px' } }}
                    />
                    <span onClick={() => handleDelete({ first: { x: '', y: '' } })}>
                        <DeleteForeverOutlinedIcon className='ml-[15px] inline-block cursor-pointer text-[#c4c4c4] hover:text-[#555353]' />
                    </span>
                </Box>
                <Box className='w-full pb-5 text-left'>
                    <OutlinedInput
                        className='w-[55px]'
                        placeholder='-'
                        value={state.second.x}
                        onChange={(e) => setState({ ...state, second: { ...state.second, x: e.target.value } })}
                        sx={{ '& > .MuiInputBase-input': { padding: '10px' } }}
                    />
                    <span className='px-5'>:</span>
                    <OutlinedInput
                        className='w-[55px]'
                        placeholder='-'
                        value={state.second.y}
                        onChange={(e) => setState({ ...state, second: { ...state.second, y: e.target.value } })}
                        sx={{ '& > .MuiInputBase-input': { padding: '10px' } }}
                    />
                    <span onClick={() => handleDelete({ second: { x: '', y: '' } })}>
                        <DeleteForeverOutlinedIcon className='ml-[15px] inline-block cursor-pointer text-[#c4c4c4] hover:text-[#555353]' />
                    </span>
                </Box>
                <Box className='w-full pb-5 text-left'>
                    <OutlinedInput
                        className='w-[55px]'
                        placeholder='-'
                        value={state.third.x}
                        onChange={(e) => setState({ ...state, third: { ...state.third, x: e.target.value } })}
                        sx={{ '& > .MuiInputBase-input': { padding: '10px' } }}
                    />
                    <span className='px-5'>:</span>
                    <OutlinedInput
                        className='w-[55px]'
                        placeholder='-'
                        value={state.third.y}
                        onChange={(e) => setState({ ...state, third: { ...state.third, y: e.target.value } })}
                        sx={{ '& > .MuiInputBase-input': { padding: '10px' } }}
                    />
                    <span onClick={() => handleDelete({ third: { x: '', y: '' } })}>
                        <DeleteForeverOutlinedIcon className='ml-[15px] inline-block cursor-pointer text-[#c4c4c4] hover:text-[#555353]' />
                    </span>
                </Box>
            </div>
            <div className='mt-6 flex flex-row justify-end space-x-3'>
                <Button
                    onClick={() => onClose()}
                    label={getText('sections.adaption.cancel')}
                    type='secondary'
                    outline={false}
                />
                <Button onClick={() => handleSubmit()} label='CONFIRM' outline={false} />
            </div>
        </Modal>
    );
}
