import AddIcon from '@mui/icons-material/Add';
import { Divider, IconButton } from '@mui/material';
import React, { ReactElement, useState } from 'react';

import useProjectScene from '../../hooks/use-project-scene';
import AspectRatioModal from '../../modals/aspect-ratio-modal';
import { classNames } from '../../utils/classes';
import Brightness from '../icons/fill/brightness';

import { AspectRatio } from './aspect-ratio';

export default function BackgroundAspectRatioActions(): ReactElement {
    const { selection } = useProjectScene();

    const [customAspectRatio, setCustomAspectRatio] = useState<AspectRatio[]>([]);
    const [aspectRatioModal, setAspectRatioModal] = useState<{
        isOpen: boolean;
        customAspectRatio: AspectRatio[];
    }>({
        isOpen: false,
        customAspectRatio,
    });

    return (
        <>
            <Divider />
            <IconButton
                className={classNames('!py-[5px]', selection.aspectRatio === 1 / 1 ? '!text-[#7a7676]' : '')}
                onClick={() => selection.setAspectRatio(1 / 1)}
            >
                1:1
            </IconButton>
            <IconButton
                className={classNames('!py-[5px]', selection.aspectRatio === 16 / 9 ? '!text-[#7a7676]' : '')}
                onClick={() => selection.setAspectRatio(16 / 9)}
            >
                16:9
            </IconButton>
            <IconButton
                className={classNames('!py-[5px]', selection.aspectRatio === 9 / 16 ? '!text-[#7a7676]' : '')}
                onClick={() => selection.setAspectRatio(9 / 16)}
            >
                9:16
            </IconButton>
            <IconButton
                className={classNames('!py-[5px]', selection.aspectRatio === 3 / 4 ? '!text-[#7a7676]' : '')}
                onClick={() => selection.setAspectRatio(3 / 4)}
            >
                3:4
            </IconButton>
            <IconButton
                className={classNames('!py-[5px]', selection.aspectRatio === 4 / 3 ? '!text-[#7a7676]' : '')}
                onClick={() => selection.setAspectRatio(4 / 3)}
            >
                4:3
            </IconButton>
            <Divider />
            {customAspectRatio.length > 0 &&
                customAspectRatio.map((ratio, index) => (
                    <IconButton
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={classNames(
                            '!py-[5px]',
                            selection.aspectRatio === ratio.value.x / ratio.value.y ? '!text-[#7a7676]' : '',
                        )}
                        onClick={() => selection.setAspectRatio(ratio.value.x / ratio.value.y)}
                    >
                        {ratio.name}
                    </IconButton>
                ))}
            <Divider style={{ marginTop: `${(3 - customAspectRatio.length) * 29.5}px` }} />
            <IconButton onClick={() => setAspectRatioModal({ ...aspectRatioModal, isOpen: true })}>
                <AddIcon className='!text-[#7a7676]' />
            </IconButton>
            <Divider />
            <IconButton>
                <input
                    className='absolute cursor-pointer opacity-0'
                    type='color'
                    onChange={(e) => selection.selectBackgroundColor(e.target.value)}
                />
                <Brightness />
            </IconButton>
            <AspectRatioModal
                open={aspectRatioModal?.isOpen}
                onClose={() => setAspectRatioModal({ ...aspectRatioModal, isOpen: false })}
                setRatio={setCustomAspectRatio}
                getRatio={customAspectRatio}
            />
        </>
    );
}
