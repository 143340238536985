import React, { ReactElement, useState } from 'react';

import { FieldFilterOption } from '../../generated/gql/graphql';
import { classNames } from '../../utils/classes';
import ArrowDownIcon from '../icons/outlines/arrow-down';
import CheckIcon from '../icons/outlines/check';

type Props = {
    categoryName: string;
    selections: FieldFilterOption[];
    options: FieldFilterOption[];
    onSelect(option: FieldFilterOption): void;
};

export default function MultiDropdownSelector(props: Props): ReactElement {
    const { categoryName, selections, options, onSelect } = props;

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    function toggleDropdown(): void {
        setIsDropdownOpen(!isDropdownOpen);
    }

    return (
        <div className='relative w-full'>
            <button
                type='button'
                className={classNames(
                    'flex w-full min-w-[150px] items-center justify-between space-x-1 rounded-lg px-4 py-1 outline outline-1 hover:bg-companyLightGrey hover:outline-company',
                    isDropdownOpen ? 'outline-company' : 'outline-companyLightGrey',
                )}
                onClick={() => toggleDropdown()}
            >
                <span className='text-sm text-companyDarkGrey'>{categoryName}</span>
                <ArrowDownIcon
                    className={classNames('fill-companyMediumGrey transition', isDropdownOpen ? 'rotate-180' : '')}
                />
            </button>

            {isDropdownOpen && (
                <>
                    <ul
                        className={classNames(
                            'border-companyGray shadow-lg absolute left-0 z-20 mt-2 flex max-h-60 w-full flex-col overflow-y-auto rounded-lg border bg-white py-2',
                            isDropdownOpen ? '' : 'hidden',
                        )}
                    >
                        {options.map((option) => (
                            <li key={option.value}>
                                <button
                                    type='button'
                                    className='flex w-full items-center space-x-3 px-4 py-2 hover:bg-gray-100'
                                    onClick={() => onSelect(option)}
                                >
                                    <div>
                                        <CheckIcon
                                            className={classNames(
                                                selections.includes(option) ? 'fill-company' : 'opacity-0',
                                            )}
                                            width={15}
                                            height={15}
                                        />
                                    </div>
                                    <span className='w-full text-left text-companyDarkGrey'>{option.label}</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div
                        className='fixed left-0 top-0 z-10 h-screen w-screen bg-black bg-opacity-10'
                        onClick={() => setIsDropdownOpen(false)}
                    />
                </>
            )}
        </div>
    );
}
