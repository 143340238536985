import { ZoomIn, ZoomOut } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Divider, IconButton, Slider, Typography } from '@mui/material';
import React, { ReactElement } from 'react';

import { Vector2 } from '../../types/vector';

export type BackgroundZoomProps = {
    /** Current scale */
    scale: number;

    /** Immediatly called when scale changed, only use for local state changes */
    scaleChange: (value: number) => void;

    /** Called when scale changing ended, e.g. releasing the mouse button after using a slider */
    scaleChangeEnd: (value: number) => void;

    /** Called when scale & offset changed */
    scaleOffsetChange: (scale: number, offset: Vector2) => void;
};

export default function BackgroundZoom(props: BackgroundZoomProps): ReactElement {
    const { scale, scaleChange, scaleChangeEnd, scaleOffsetChange } = props;

    const handleSliderChange = (_event: Event, value: any): void => {
        scaleChange(value);
    };

    const handleSliderChangeCommitted = (_event: React.SyntheticEvent | Event, value: number | number[]): void => {
        const newScale = Array.isArray(value) ? value[0] : value;
        scaleChange(newScale);
        scaleChangeEnd(newScale);
    };

    const handleScaleIncrease = (): void => {
        if (scale >= 4) {
            return;
        }
        const newScale = scale + 0.1;
        scaleChange(newScale);
        scaleChangeEnd(newScale);
    };

    const handleScaleDecrease = (): void => {
        if (scale <= 1) {
            return;
        }
        const newScale = scale - 0.1;
        scaleChange(newScale);
        scaleChangeEnd(newScale);
    };

    const handleResetClick = (): void => {
        scaleOffsetChange(1, { x: 0, y: 0 });
    };

    return (
        <>
            <IconButton className='flex-col items-center' onClick={handleScaleIncrease}>
                <ZoomIn className='!text-[#7a7676]' />
            </IconButton>
            <Typography className='block !text-[11px] text-[#7a7676]' component='span'>
                {`${Math.round(scale * 100)}%`}
            </Typography>
            <Slider
                className='my-[5px] !h-[200px] !text-[#7a7676] text-slate-500'
                sx={{
                    '&': {
                        color: '#64748b',
                    },
                    '& .MuiSlider-thumb': {
                        borderRadius: 0,
                        height: '5px',
                    },
                    '& input[type="range"]': {
                        WebkitAppearance: 'slider-vertical',
                    },
                }}
                max={4}
                min={1}
                step={0.1}
                orientation='vertical'
                value={scale}
                onChange={handleSliderChange}
                onChangeCommitted={handleSliderChangeCommitted}
            />
            <Typography className='block !text-[11px] text-[#7a7676]' component='span'>
                100%
            </Typography>
            <IconButton onClick={handleScaleDecrease}>
                <ZoomOut className='!text-[#7a7676]' />
            </IconButton>
            <Divider />
            <IconButton onClick={handleResetClick}>
                <RefreshIcon style={{ transform: 'scaleX(-1)' }} className='!text-[#7a7676]' />
            </IconButton>
        </>
    );
}
