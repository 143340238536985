import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function SearchIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} width={width} height={height} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M16 8C16 8.30302 15.8288 8.58003 15.5578 8.71554L1.1578 15.9155C0.881762 16.0536 0.550953 16.0205 0.3077 15.8306C0.0644444 15.6406 -0.0478692 15.3277 0.0190801 15.0265L1.58051 8L0.0190801 0.97355C-0.0478692 0.672281 0.0644444 0.359369 0.3077 0.169442C0.550953 -0.0204859 0.881762 -0.0535541 1.1578 0.0844642L15.5578 7.28446C15.8288 7.41997 16 7.69698 16 8ZM3.04176 8.8L1.94559 13.7328L11.8112 8.8H3.04176ZM11.8112 7.2L3.04176 7.2L1.94559 2.26721L11.8112 7.2Z'
            />
        </svg>
    );
}

SearchIcon.defaultProps = {
    width: 24,
    height: 24,
    className: '',
};
