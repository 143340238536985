import { useMutation } from '@apollo/client';

import { graphql } from '../generated/gql';

import useNotification from './use-notification';
// import { RenderLayoutPreviewMutation } from '../generated/gql/graphql';

type LayoutApi = {
    // loading: boolean;
    // error?: ApolloError;
    // data?: RenderLayoutPreviewMutation;
    renderPreviewLayoutById(id: string): Promise<void>;
};

const RENDER_REVIEW_LAYOUT = graphql(`
    mutation RenderLayoutPreview($input: RenderLayoutPreviewInput!) {
        renderLayoutPreview(input: $input) {
            ... on RenderLayoutPreviewSuccess {
                job {
                    id
                }
            }
            ... on RenderLayoutPreviewError {
                message
            }
        }
    }
`);

export default function usePreviewLayout(): LayoutApi {
    const notification = useNotification();
    const [renderLayoutPreview] = useMutation(RENDER_REVIEW_LAYOUT);

    // const { data, loading, error } = useMutation(RENDER_REVIEW_LAYOUT, {
    //     fetchPolicy: 'network-only',
    //     variables: {
    //         input: {
    //             layoutId
    //         }
    //     }

    // });

    async function renderPreviewLayoutById(layoutId: string): Promise<void> {
        const result = await renderLayoutPreview({
            variables: {
                input: {
                    layoutId,
                },
            },
        });

        if (result.errors && result.errors.length > 0) {
            notification.addError('Something was wromg.');
            throw result.errors;
        }
    }

    return {
        renderPreviewLayoutById,
    };
}
