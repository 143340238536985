import { ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItemButton,
    ListItemText,
    ListSubheader,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { Link, useParams } from 'react-router-dom';

import useWorkspace, { Workspace } from '../../../hooks/use-workspace';
import { Project } from '../../../types/project';

type WorkspacesProps = {
    name: string;
    projectId: string | undefined;
    workspaces: Workspace[];
};

function Workspaces(props: WorkspacesProps): ReactElement {
    const { name, projectId, workspaces } = props;

    const { id: workspaceId } = useParams();

    return (
        <List
            sx={{ bgcolor: 'background.paper' }}
            className='width-full mb-[25px] ml-[45px] max-w-[320px] overflow-hidden rounded-[15px] pt-[8px] shadow'
            component='nav'
            subheader={
                <ListSubheader component='div' id='nested-list-subheader'>
                    {name}
                </ListSubheader>
            }
        >
            {workspaces.map((ws: Workspace) => (
                <Accordion
                    key={ws.id}
                    className='accordion'
                    defaultExpanded={ws.id === projectId || !!ws.projects?.find((p: any) => p.id === projectId)?.id}
                >
                    <AccordionSummary
                        style={{ background: ws.id === workspaceId ? '#1976d214' : 'none' }}
                        className='m-[0px]'
                        expandIcon={<ExpandMore />}
                    >
                        <Link key={ws.id} to={`/projects/${ws.id}`} state={{ workspaceName: ws.name }}>
                            <ListItemText primary={`${ws.name} (${ws?.projects?.length || 0})`} />
                        </Link>
                    </AccordionSummary>
                    <AccordionDetails className='p-[0px]'>
                        <List component='div' disablePadding>
                            {ws.projects &&
                                ws.projects.map((project: Project) => (
                                    <Link key={project.id} to={`/project/${project.id}`}>
                                        <ListItemButton
                                            selected={project.id === projectId}
                                            className='border-t-1 border-solid border-slate-300 pl-[30px]'
                                            sx={{ pl: 6 }}
                                        >
                                            <ListItemText primary={project.name} />
                                        </ListItemButton>
                                    </Link>
                                ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            ))}
        </List>
    );
}

export default function WorkspacesList(): ReactElement {
    const { id: projectId } = useParams();
    const { workspaces } = useWorkspace();

    if (!workspaces) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    const yourWorkspaces = workspaces?.filter((ws) => !ws.readOnly);
    const otherWorkspaces = workspaces?.filter((ws) => ws.readOnly);

    return (
        <>
            <Workspaces name='Your Workspaces' projectId={projectId} workspaces={yourWorkspaces} />
            <Workspaces name='Other Workspaces' projectId={projectId} workspaces={otherWorkspaces} />
        </>
    );
}
