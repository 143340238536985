import React, { ReactElement } from 'react';

import { classNames } from '../../../utils/classes';

type Props = {
    children: ReactElement | ReactElement[];
    classNames?: string;
};

export default function PanelHeader(props: Props): ReactElement {
    const { children } = props;

    return (
        <div
            className={classNames(
                'flex items-center justify-between space-x-5 border-b border-companyLightGrey px-5 py-2',
                props.classNames || '',
            )}
        >
            {children}
        </div>
    );
}

PanelHeader.defaultProps = {
    classNames: '',
};
