import { SubscriptionResult, useSubscription } from '@apollo/client';

import { graphql } from '../generated/gql';
import {
    LayoutRenderingProgressChangedSubscription,
    LayoutThumbnailChangedSubscription,
} from '../generated/gql/graphql';

export type LayoutUpdated = LayoutRenderingProgressChangedSubscription['layoutRenderingProgressChanged'];

const LAYOUT_THUMBNAIL_CHANGED_SUBSCRIPTION = graphql(`
    subscription LayoutThumbnailChanged($layoutId: ID) {
        layoutThumbnailChanged(id: $layoutId) {
            id
            thumbnailUrl
        }
    }
`);

export default function useLayoutThumbnailChangedSubscription(
    layoutId: string,
): SubscriptionResult<LayoutThumbnailChangedSubscription> {
    return useSubscription(LAYOUT_THUMBNAIL_CHANGED_SUBSCRIPTION, {
        variables: {
            layoutId,
        },
    });
}
