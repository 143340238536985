import React, { ChangeEvent, ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import useProjectScene from '../../hooks/use-project-scene';
import useProjects from '../../hooks/use-projects';
import Search from '../search/search';

export default function SearchProject(): ReactElement {
    const { id } = useParams();
    const { searchProjects } = useProjects(id);
    const { selectProjects } = useProjectScene().selection;

    const handleSearchProjects = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
        selectProjects(await searchProjects(e.target.value));
    };

    return <Search label='Search Projects' search={handleSearchProjects} />;
}
