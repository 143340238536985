import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

export default function PanelArea(props: Props): ReactElement {
    const { children } = props;
    return <div className='flex w-1/2 flex-col space-y-3'>{children}</div>;
}
