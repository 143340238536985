import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

export default function ViewContainer(props: Props): ReactElement {
    const { children } = props;
    return (
        <div className='relative flex max-h-screen min-h-screen flex-grow flex-col overflow-y-scroll bg-companyLightGrey p-8'>
            {children}
        </div>
    );
}
