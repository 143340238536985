import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import MainContainer from '../components/layout/main-container';
import MainHeader from '../components/layout/main-header';
import MainSidebar from '../components/layout/main-sidebar';
import ViewContainer from '../components/layout/view-container';
import useLayout from '../hooks/use-layout';

import Editor from './editor';

export default function EditorPage(): ReactElement {
    const { id } = useParams();
    const { layout } = useLayout(id ?? '');

    return (
        <MainContainer>
            {layout && <MainSidebar layout={layout} workspaceId={layout.project?.workspace?.id} />}
            <ViewContainer>
                <MainHeader />
                {layout && <Editor layout={layout} />}
            </ViewContainer>
        </MainContainer>
    );
}
