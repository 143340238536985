import React, { ReactElement } from 'react';

import { Layout } from '../../generated/gql/graphql';

import CanvasPanelContent, { CanvasPanelContentProps } from './canvas-panel-content';
import CanvasPanelHeader, { CanvasPanelHeaderProps } from './canvas-panel-header';

export type CanvasPanelProps = Pick<CanvasPanelHeaderProps, 'selectedCanvasObject' | 'selectedCanvasObjectChange'> &
    Pick<
        CanvasPanelContentProps,
        | 'layout'
        | 'backgroundId'
        | 'scale'
        | 'scaleChange'
        | 'scaleChangeEnd'
        | 'offset'
        | 'offsetChange'
        | 'scaleOffsetChange'
        | 'selectedCanvasObject'
        | 'selectedCanvasObjectIndex'
        | 'canvasObjects'
        | 'objectChanged'
        | 'objectDuplicated'
        | 'objectDeleted'
        | 'objectMoved'
        | 'objectSelected'
        | 'handleProductInputsChange'
    > & {
        layout: Pick<Layout, 'name'>;
    };

export default function CanvasPanel({
    layout,
    backgroundId,
    scale,
    scaleChange,
    scaleChangeEnd,
    offset,
    offsetChange,
    scaleOffsetChange,
    selectedCanvasObject,
    selectedCanvasObjectChange,
    selectedCanvasObjectIndex,
    canvasObjects,
    objectChanged,
    objectDuplicated,
    objectDeleted,
    objectMoved,
    objectSelected,
    handleProductInputsChange,
}: CanvasPanelProps): ReactElement {
    return (
        <section className='shadow-xl flex h-full w-full max-w-screen-lg flex-col rounded-xl bg-white'>
            <CanvasPanelHeader
                title={layout.name ?? 'Editor'}
                selectedCanvasObject={selectedCanvasObject}
                selectedCanvasObjectChange={selectedCanvasObjectChange}
            />
            <CanvasPanelContent
                layout={layout}
                backgroundId={backgroundId}
                scale={scale}
                scaleChange={scaleChange}
                scaleChangeEnd={scaleChangeEnd}
                offset={offset}
                offsetChange={offsetChange}
                scaleOffsetChange={scaleOffsetChange}
                selectedCanvasObject={selectedCanvasObject}
                selectedCanvasObjectIndex={selectedCanvasObjectIndex}
                canvasObjects={canvasObjects}
                objectChanged={objectChanged}
                objectDeleted={objectDeleted}
                objectDuplicated={objectDuplicated}
                objectMoved={objectMoved}
                objectSelected={objectSelected}
                handleProductInputsChange={handleProductInputsChange}
            />
        </section>
    );
}
