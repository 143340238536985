import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

export default function CanvasArea(props: Props): ReactElement {
    const { children } = props;
    return <div className='flex max-h-[800px] max-w-[800px] flex-grow basis-1/2 flex-row'>{children}</div>;
}
