import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

export default function WorkspacesContainer(props: Props): ReactElement {
    const { children } = props;

    return <div className='flex w-full flex-row gap-[25px]'>{children}</div>;
}
