import { Checkbox, FormControlLabel } from '@mui/material';
import React, { ReactElement } from 'react';

export type SelectAllLayoutsButtonProps = {
    allLayoutIds: string[];
    selectedLayoutIds: string[];
    selectAll: () => void;
    selectNone: () => void;
};

export default function SelectAllLayoutsButton({
    allLayoutIds,
    selectedLayoutIds,
    selectAll,
    selectNone,
}: SelectAllLayoutsButtonProps): ReactElement {
    const handleSelectAll = (_e: unknown, checked: boolean): void => {
        const newSelectAll = checked;
        if (newSelectAll) {
            selectAll();
        } else {
            selectNone();
        }
    };

    const allSelected = allLayoutIds.every((layoutId) => selectedLayoutIds.includes(layoutId));
    const someSelected = allLayoutIds.some((layoutId) => selectedLayoutIds.includes(layoutId));

    return (
        <FormControlLabel
            sx={{
                '.MuiFormControlLabel-label': {
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                },
            }}
            label='Select All'
            control={
                <Checkbox
                    checked={allLayoutIds.length > 0 && allSelected}
                    indeterminate={!allSelected && someSelected}
                    onChange={handleSelectAll}
                />
            }
        />
    );
}
