import React, { ChangeEvent, ReactElement } from 'react';

import Search from '../../search/search';

export type SearchLayoutProps = {
    submit: (search: string) => void;
};

export default function SearchLayout({ submit }: SearchLayoutProps): ReactElement {
    const handleSearchChange = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
        submit(e.target.value);
    };

    return <Search label='Search Layouts' search={handleSearchChange} />;
}
