import { useMutation } from '@apollo/client';

import { graphql } from '../generated/gql';

import useNotification from './use-notification';

type ProjectApi = {
    updateProjectById(id: string, name: string): void;
};

const UPDATE_PROJECT = graphql(`
    mutation UpdateProject($updateProjectId: ID!, $data: UpdateProjectInput!) {
        updateProject(id: $updateProjectId, data: $data) {
            id
        }
    }
`);

export default function useCreateProject(): ProjectApi {
    const notification = useNotification();
    const [updateProject] = useMutation(UPDATE_PROJECT);

    async function updateProjectById(id: string, name: string): Promise<void> {
        const result = await updateProject({
            variables: {
                updateProjectId: id,
                data: {
                    name,
                },
            },
        });

        if (result.errors && result.errors.length > 0) {
            notification.addError('Something was wromg.');
            throw result.errors;
        }
    }

    return {
        updateProjectById,
    };
}
