import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { ReactElement } from 'react';

import { Workspace } from '../../generated/gql/graphql';

type Props = {
    workspaces: Workspace[];
    workspaceId: string;
    title: string;
    errorMessage?: string;

    onChange: (event: SelectChangeEvent) => void;
};

export default function WorkspaceSelector(props: Props): ReactElement {
    const { workspaces, title, workspaceId, errorMessage, onChange } = props;

    return (
        <Box>
            <FormControl className='mb-[15px] min-w-[220px]' size='small' error={!workspaceId}>
                <InputLabel>{title}</InputLabel>
                <Select
                    className='rounded-[15px] border-0 text-left text-[14px]'
                    value={workspaceId || ''}
                    label={title}
                    onChange={onChange}
                    error={!workspaceId}
                >
                    {workspaces?.map((ws) => (
                        <MenuItem key={ws.id} value={ws.id}>
                            {ws.name}
                        </MenuItem>
                    ))}
                </Select>
                {!workspaceId && errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControl>
        </Box>
    );
}

WorkspaceSelector.defaultProps = {
    errorMessage: undefined,
};
