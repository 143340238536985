import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import React, { ReactElement } from 'react';

import ReplacePerspectivesIcon from '../icons/outlines/replace-perspectives';
import ReplaceProductIcon from '../icons/outlines/replace-product';
import TrashIcon from '../icons/outlines/trash';

type Props = {
    position: any;
    onDelete(): void;
    onMoveUp(): void;
    onMoveDown(): void;
    onChangePresentation(): void;
    onChangeProduct(): void;
    onDuplicateProduct(): void;
};

export default function PackshotMenu(props: Props): ReactElement {
    const { position, onDelete, onMoveUp, onMoveDown, onChangePresentation, onChangeProduct, onDuplicateProduct } =
        props;

    return (
        <Paper
            style={{ left: `${position.x}px`, top: `${position.y}px` }}
            className='absolute top-[50%] z-50 mt-[-98px] w-[40px] cursor-pointer bg-white'
        >
            <MenuList>
                <MenuItem
                    onClick={() => onDelete()}
                    className='h-[30px] !justify-center !border-b-[1px] !border-solid !border-slate-300 !p-[5px]'
                >
                    <TrashIcon className='!text-[20px]' />
                </MenuItem>
                <MenuItem
                    onClick={() => onMoveUp()}
                    className='h-[30px] !justify-center !border-b-[1px] !border-solid !border-slate-300 !p-0'
                >
                    <MoveUpIcon className='!text-[20px]' />
                </MenuItem>
                <MenuItem
                    onClick={() => onMoveDown()}
                    className='h-[30px] !justify-center !border-t-[1px] !border-solid !border-slate-300 !p-0'
                >
                    <MoveDownIcon className='!text-[20px]' />
                </MenuItem>
                <MenuItem onClick={() => onChangePresentation()} className='h-[30px] !justify-center !p-[5px]'>
                    <ReplacePerspectivesIcon className='!text-[20px]' />
                </MenuItem>
                <MenuItem
                    onClick={() => onChangeProduct()}
                    className='h-[30px] !justify-center !border-t-[1px] !border-solid !border-slate-300 !p-[5px]'
                >
                    <ReplaceProductIcon className='!text-[20px]' />
                </MenuItem>
                <MenuItem
                    onClick={() => onDuplicateProduct()}
                    className='h-[30px] !justify-center !border-t-[1px] !border-solid !border-slate-300 !p-[5px]'
                >
                    <ContentCopyIcon className='!text-[20px]' />
                </MenuItem>
            </MenuList>
        </Paper>
    );
}
