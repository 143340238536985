import React, { ReactElement, ReactNode } from 'react';

import variables from '../../styles/variables.json';
import { classNames } from '../../utils/classes';
import LoadingIcon from '../icons/outlines/loading';

type Props = {
    type: 'primary' | 'secondary' | 'tertiary';
    label: string;
    icon: ReactNode;
    iconPosition?: 'left' | 'right';
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
};

export default function TextIconButton(props: Props): ReactElement {
    const { type, label, icon, iconPosition, onClick, disabled, loading } = props;

    function getLoadingIcon(): ReactElement {
        return (
            <LoadingIcon
                width={16}
                height={16}
                bgColor={variables.colors.company.lightGrey}
                lineColor={variables.colors.company.primary}
            />
        );
    }

    return (
        <>
            {type === 'primary' && (
                <button
                    className={classNames(
                        'flex h-8 flex-row items-center space-x-3 rounded-xl pb-1 pl-3 pr-3 pt-1 outline outline-1 hover:outline-2',
                        disabled
                            ? 'pointer-events-none cursor-default outline outline-companyGrey [&>svg]:fill-companyGrey'
                            : 'cursor-pointer outline-company [&>svg]:fill-company',
                    )}
                    onClick={onClick}
                    type='button'
                >
                    {iconPosition === 'left' && !loading && icon}
                    {iconPosition === 'left' && loading && getLoadingIcon()}
                    {iconPosition === 'left' && (
                        <div className={classNames('h-4 w-px', disabled ? 'bg-companyGrey' : 'bg-company')} />
                    )}
                    <p className={classNames('text-xs', disabled ? 'text-companyGrey' : 'text-company')}>{label}</p>
                    {iconPosition === 'right' && (
                        <div className={classNames('h-4 w-px', disabled ? 'bg-companyGrey' : 'bg-company')} />
                    )}
                    {iconPosition === 'right' && !loading && icon}
                    {iconPosition === 'right' && loading && getLoadingIcon()}
                </button>
            )}
            {type === 'secondary' && (
                <button className='flex cursor-pointer flex-row items-center space-x-3' onClick={onClick} type='button'>
                    {iconPosition === 'left' && !loading && icon}
                    {iconPosition === 'left' && loading && getLoadingIcon()}
                    <p className='text-base uppercase text-companyDarkGrey'>{label}</p>
                    {iconPosition === 'right' && !loading && icon}
                    {iconPosition === 'right' && loading && getLoadingIcon()}
                </button>
            )}
            {type === 'tertiary' && (
                <button
                    className={classNames(
                        'flex h-8 flex-row items-center space-x-3 rounded-xl pb-1 pl-3 pr-3 pt-1 outline outline-1 hover:outline-2',
                        disabled
                            ? 'pointer-events-none cursor-default outline outline-companyGrey [&>svg]:fill-companyGrey'
                            : 'cursor-pointer outline-companyDarkGrey [&>svg]:fill-companyDarkGrey',
                    )}
                    onClick={onClick}
                    type='button'
                >
                    {iconPosition === 'left' && !loading && icon}
                    {iconPosition === 'left' && loading && getLoadingIcon()}
                    <p className={classNames('text-xs', disabled ? 'text-companyGrey' : 'text-companyDarkGrey')}>
                        {label}
                    </p>
                    {iconPosition === 'right' && !loading && icon}
                    {iconPosition === 'right' && loading && getLoadingIcon()}
                </button>
            )}
        </>
    );
}

TextIconButton.defaultProps = {
    disabled: false,
    iconPosition: 'right',
    loading: false,
};
