import React, { ReactElement, ReactNode } from 'react';

import { classNames } from '../../utils/classes';

type Props = {
    icon: ReactNode;
    active?: boolean;
    activeTooltip: string;
    inactiveTooltip: string;
};

export default function IconTooltip(props: Props): ReactElement {
    const { icon, activeTooltip, inactiveTooltip, active } = props;
    return (
        <div className='group relative flex justify-center'>
            <span
                className={classNames(
                    'shadow-sm rounded text-sm text-white',
                    active ? '[&>svg]:fill-company' : '[&>svg]:fill-companyGrey',
                )}
            >
                {icon}
            </span>
            <span
                className={classNames(
                    'absolute bottom-8 z-50 scale-0 whitespace-nowrap rounded bg-companyMediumGrey p-1 text-xs text-white group-hover:scale-100',
                )}
            >
                {active ? activeTooltip : inactiveTooltip}
            </span>
        </div>
    );
}

IconTooltip.defaultProps = {
    active: false,
};
