import { FormControlLabel, Switch } from '@mui/material';
import React from 'react';

import useLocale from '../../hooks/use-locale';
import useProjectScene from '../../hooks/use-project-scene';

export default function ShadowsReflectionsSwitch(): React.ReactElement {
    const { getText } = useLocale();
    const { selection } = useProjectScene();

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={!selection.disableShadowsAndReflections}
                    onChange={(ev) => selection.setDisableShadowsAndReflections(!ev.target.checked)}
                />
            }
            label={
                <p className='text-xs text-companyDarkGrey'>
                    {getText('sections.imageComposer.showShadowsReflections')}
                </p>
            }
        />
    );
}
