import { ZoomOutMap } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { ReactElement } from 'react';

import useLocale from '../../hooks/use-locale';
import { CanvasObject } from '../../types/canvas-object';
import { Size } from '../../types/size';
import { Vector2 } from '../../types/vector';

// Paddings to use (in %)
const padding = { left: 2, right: 2, top: 4.57, bottom: 6.57 };

export interface SpanPackshotButtonProps {
    canvasSize: Size;
    canvasObject: Pick<CanvasObject, 'packshotSize'> | undefined;
    canvasObjectChange: (changes: Partial<CanvasObject>) => void;
}

export default function SpanPackshotButton({
    canvasSize,
    canvasObject,
    canvasObjectChange,
}: SpanPackshotButtonProps): ReactElement {
    const { getText } = useLocale();

    function handleSpanPackshotClick(): void {
        const packshotSize = canvasObject?.packshotSize;
        if (!packshotSize) {
            return;
        }

        // Calculate available area, defined by canvas size and padding
        const maxWidth = (canvasSize.width * (100 - padding.left - padding.right)) / 100;
        const maxHeight = (canvasSize.height * (100 - padding.top - padding.bottom)) / 100;

        // Calculate scale (use maximum scale that stays in defined area)
        const scaleX = maxWidth / packshotSize.width;
        const scaleY = maxHeight / packshotSize.height;
        const scale = Math.min(scaleX, scaleY);

        // Calculate offset (should be placed horizontally center and vertically bottom)
        const packshotWidth = packshotSize.width * scale;
        const packshotHeight = packshotSize.height * scale;
        const offset: Vector2 = {
            x: (canvasSize.width - packshotWidth) / 2,
            y: canvasSize.height - (padding.bottom / 100) * canvasSize.height - packshotHeight,
        };

        canvasObjectChange({ scale, offset });
    }

    return (
        <Tooltip title={getText('sections.canvas.header.spanPackshot.tooltip')}>
            <IconButton onClick={() => handleSpanPackshotClick()}>
                <ZoomOutMap />
            </IconButton>
        </Tooltip>
    );
}
