import React, { ReactElement } from 'react';

import useLocale from '../../hooks/use-locale';
import SearchIcon from '../icons/outlines/send';

import TextIconButton from './text-icon-button';

export type GeneratorButtonProps = {
    disabled: boolean;
    loading: boolean;
    submit: () => void;
};

export default function GeneratorButton(props: GeneratorButtonProps): ReactElement {
    const { disabled, loading, submit } = props;
    const { getText } = useLocale();

    return (
        <TextIconButton
            label={getText('sections.imageComposer.generate')}
            type='primary'
            onClick={async () => {
                submit();
            }}
            disabled={disabled}
            icon={<SearchIcon width={16} height={16} />}
            loading={loading}
        />
    );
}
