import { CloseRounded } from '@mui/icons-material';
import React, { ReactElement, ReactNode } from 'react';

import { classNames } from '../../utils/classes';
import IconButton from '../button/icon-button';

type Props = {
    children: ReactNode;
    open: boolean;
    headlines: string[];
    activeIndex: number;
    onClose(): void;
    onSelectTab(index: number): void;
    className?: string;
};

export default function ModalWithTabNavigation(props: Props): ReactElement {
    const { children, open, headlines, activeIndex, onClose, onSelectTab, className } = props;

    if (!open) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    return (
        <div className='fixed bottom-0 left-0 right-0 top-0 z-50 !mt-0 flex bg-black bg-opacity-30 p-52'>
            <div className={classNames('mx-auto w-full rounded-xl bg-white p-6', className || '')}>
                <div className='mb-3 flex flex-row justify-between'>
                    <div className='space-x-3'>
                        {headlines.map((headline, i) => (
                            <button
                                key={`${i.toString()}`}
                                type='button'
                                onClick={() => onSelectTab(i)}
                                disabled={i === activeIndex}
                            >
                                <p
                                    className={classNames(
                                        'font-bold uppercase',
                                        i === activeIndex ? 'text-company underline' : 'text-companyMediumGrey',
                                    )}
                                >
                                    {headline}
                                </p>
                            </button>
                        ))}
                    </div>
                    <IconButton icon={<CloseRounded className='!fill-companyMediumGrey' />} onClick={onClose} />
                </div>
                <div className='flex h-full w-full flex-col justify-between'>{children}</div>
            </div>
        </div>
    );
}

ModalWithTabNavigation.defaultProps = {
    className: '',
};
