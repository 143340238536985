import { TextField } from '@mui/material';
import React, { ReactElement, useState } from 'react';

import Button from '../components/button/button';
import Modal from '../components/modal/modal';
import ProjectSelector from '../components/selectors/project-selector';
import WorkspaceSelector from '../components/selectors/workspace-selector';
import { Layout, Project, Workspace } from '../generated/gql/graphql';
import useDuplicateLayout from '../hooks/use-duplicate-layout';
import useWorkspace from '../hooks/use-workspace';

export type CreateLayoutModalProps = {
    open: boolean;
    layout: Pick<Layout, 'id' | 'name'>;
    project: Pick<Project, 'id' | 'workspaceId' | 'readOnly'> | undefined;

    layoutsChange: () => void | Promise<void>;
    onClose(): void;
};

export default function DuplicateLayoutModal(props: CreateLayoutModalProps): ReactElement {
    const { open, layout, project, onClose, layoutsChange } = props;

    const [name, setName] = useState<string>(layout.name ?? '');
    const [workspaceId, setWorkspaceId] = useState<string | undefined | null>(
        project?.readOnly ? '' : project?.workspaceId,
    );
    const [projectId, setProjectId] = useState<string | undefined>(project?.id);

    const { workspaces } = useWorkspace();
    const { duplicateLayoutById } = useDuplicateLayout();

    const writebleWorkspaces = workspaces?.filter((ws) => !ws.readOnly);
    const currentWorkspace = workspaces?.find((ws) => ws.id === workspaceId);

    async function onSubmit(): Promise<void> {
        await duplicateLayoutById(layout.id, projectId, name);
        await layoutsChange();

        onClose();
    }

    // Validate inputs
    const nameTooShort = name.length < 3;
    const submitDisabled = nameTooShort || !workspaceId || !projectId;

    return (
        <Modal open={open} headline='Duplicate layout' onClose={() => onClose()} className='h-fit max-w-xl'>
            <>
                <TextField
                    className='mb-[20px]'
                    margin='dense'
                    type='text'
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    fullWidth
                    focused
                    variant='standard'
                    error={nameTooShort}
                    label='Layout name'
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)}
                    helperText={nameTooShort && 'Please give your layout a name with at least 3 characters.'}
                />
                <WorkspaceSelector
                    workspaceId={workspaceId || ''}
                    workspaces={writebleWorkspaces as Workspace[]}
                    title='Duplicate to Workspace'
                    errorMessage='Please select the workspace to duplicate the layout to'
                    onChange={(e) => {
                        setWorkspaceId(e.target.value as string);
                        setProjectId(undefined);
                    }}
                />
                {currentWorkspace?.projects && (
                    <ProjectSelector
                        title='Duplicate to Project'
                        errorMessage='Please select a project to duplicate the layout to'
                        initialProjectId={projectId ?? ''}
                        projects={currentWorkspace.projects}
                        onChange={(e) => setProjectId(e.target.value as string)}
                    />
                )}
                <div className='flex flex-row justify-between pt-6'>
                    <button type='button' onClick={() => onClose()}>
                        Cancel
                    </button>
                    <Button label='Confirm' onClick={() => onSubmit()} disabled={submitDisabled} />
                </div>
            </>
        </Modal>
    );
}
