import { useMutation } from '@apollo/client';
import { useContext } from 'react';

import { graphql } from '../generated/gql';
import { RenderLayoutsInput } from '../generated/gql/graphql';
import { GeneratorContext } from '../providers/generator-provider';

import useLocale from './use-locale';
import useNotification from './use-notification';

type RenderLayoutsApi = {
    renderLayouts(input: RenderLayoutsInput): Promise<void>;
};

const renderLayoutsDocument = graphql(`
    mutation RenderLayouts($input: RenderLayoutsInput!) {
        renderLayouts(input: $input) {
            ... on RenderLayoutsSuccess {
                jobs {
                    id
                }
            }
            ... on RenderLayoutsError {
                messages
            }
        }
    }
`);

export default function useRenderLayouts(): RenderLayoutsApi {
    const generator = useContext(GeneratorContext);

    const [renderLayoutsMutation] = useMutation(renderLayoutsDocument);
    const notification = useNotification();
    const { getText } = useLocale();

    async function renderLayouts(input: RenderLayoutsInput): Promise<void> {
        generator.setIsRunning(true);

        try {
            const result = await renderLayoutsMutation({
                variables: {
                    input,
                },
            });

            if (result.data?.renderLayouts.__typename === 'RenderLayoutsError') {
                generator.setIsRunning(false);
                throw new Error(result.data.renderLayouts.messages.join(','));
            }

            notification.addSuccess(
                getText('notifications.jobSubmission.successful.body'),
                getText('notifications.jobSubmission.successful.title'),
            );
        } catch (error) {
            notification.addError(
                getText('notifications.jobSubmission.failed.body'),
                getText('notifications.jobSubmission.failed.title'),
            );
            // eslint-disable-next-line no-console
            console.error(error);
        }

        generator.setIsRunning(false);
    }

    return {
        renderLayouts,
    };
}
