import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import React, { ReactElement, ReactNode } from 'react';

import { getEnv } from '../utils/environment-variables';

type Props = {
    children: ReactNode;
};

const keycloak = new Keycloak({
    realm: getEnv('REACT_APP_KEYCLOAK_REALM'),
    url: getEnv('REACT_APP_KEYCLOAK_AUTH_URL'),
    clientId: getEnv('REACT_APP_KEYCLOAK_CLIENT_ID'),
});

export default function KeycloakProvider(props: Props): ReactElement {
    const { children } = props;

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{ onLoad: 'login-required' }}
            isLoadingCheck={(client) => !client.authenticated}
            LoadingComponent={<div />}
        >
            {children}
        </ReactKeycloakProvider>
    );
}
