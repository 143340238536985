import { useMutation } from '@apollo/client';

import { graphql } from '../generated/gql';
import { CreateLayoutInput, CreateLayoutMutation, CreateLayoutMutationVariables } from '../generated/gql/graphql';

import useNotification from './use-notification';

type ProjectApi = {
    createNewLayout(input: CreateLayoutInput): Promise<CreateLayoutMutation['createLayout'] | undefined>;
};

const CREATE_LAYOUT = graphql(`
    mutation CreateLayout($input: CreateLayoutInput!) {
        createLayout(input: $input) {
            name
            id
            createdAt
            status
            projectId
        }
    }
`);

export default function useCreateLayout(): ProjectApi {
    const notification = useNotification();
    const [createLayout] = useMutation(CREATE_LAYOUT);

    async function createNewLayout(
        input: CreateLayoutMutationVariables['input'],
    ): Promise<CreateLayoutMutation['createLayout'] | undefined> {
        const result = await createLayout({
            variables: {
                input,
            },
        });

        if (result.errors && result.errors.length > 0) {
            notification.addError('Something was wromg.');
            throw result.errors;
        }

        return result.data?.createLayout;
    }

    return {
        createNewLayout,
    };
}
