import { SelectChangeEvent, TextField } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '../components/button/button';
import Modal from '../components/modal/modal';
import WorkspaceSelector from '../components/selectors/workspace-selector';
import { Workspace } from '../generated/gql/graphql';
import useDuplicateProject from '../hooks/use-duplicate-project';
import useProjects from '../hooks/use-projects';
import useWorkspace from '../hooks/use-workspace';
import { Project } from '../types/project';

type Props = {
    headline: string;
    open: boolean;
    project?: Project;
    onClose(): void;
};

export default function ProjectDuplicateModal(props: Props): ReactElement {
    const { headline, open, project, onClose } = props;

    const [name, setName] = useState(project?.name ?? undefined);
    const [workspaceId, setWorkspaceId] = useState<string | undefined>();

    const { id } = useParams();
    const { duplicateProjectById } = useDuplicateProject();
    const { refetchProjects } = useProjects(id);
    const { workspaces } = useWorkspace();

    const handleChange = (event: SelectChangeEvent): void => {
        setWorkspaceId(event.target.value as string);
    };

    async function onSubmit(): Promise<void> {
        try {
            await duplicateProjectById(project.id, workspaceId, name);
            await refetchProjects();

            onClose();
        } catch {
            onClose();
        }
    }

    /** Minimum length of project name */
    const MinNameLength = 3;

    const writebleWorkspaces = workspaces?.filter((ws) => !ws.readOnly);

    return (
        <Modal open={open} headline={headline} onClose={() => onClose()} className='h-fit max-w-xl'>
            <>
                <TextField
                    className='mb-[20px]'
                    margin='dense'
                    type='text'
                    fullWidth
                    variant='standard'
                    error={name?.length < MinNameLength}
                    label='Project name'
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)}
                    helperText={
                        name?.length < MinNameLength && `Project name should have minimum ${MinNameLength} characters.`
                    }
                />
                <WorkspaceSelector
                    title='Duplicate to Workspace'
                    errorMessage='Please select a project to add the new layout to'
                    workspaceId={workspaceId ?? ''}
                    workspaces={writebleWorkspaces as Workspace[]}
                    onChange={handleChange}
                />
                <div className='flex flex-row justify-between pt-6'>
                    <button type='button' onClick={() => onClose()}>
                        Cancel
                    </button>
                    <Button
                        label='Confirm'
                        onClick={() => onSubmit()}
                        disabled={name?.length < MinNameLength || !workspaceId}
                    />
                </div>
            </>
        </Modal>
    );
}

ProjectDuplicateModal.defaultProps = {
    project: undefined,
};
