import { gql, useMutation } from '@apollo/client';

import { CreateBackgroundMutation, CreateBackgroundMutationVariables } from '../generated/gql/graphql';

import useLocale from './use-locale';
import useNotification from './use-notification';

type AssetApi = {
    uploadBackground(name: string, image: File): void;
};

const CREATE_BACKGROUND = gql`
    mutation CreateBackground($input: CreateBackgroundInput!) {
        createBackground(input: $input) {
            ... on CreateBackgroundSuccess {
                background {
                    id
                    name
                    tags
                    thumbnailUrl
                    url
                }
            }
            ... on CreateBackgroundError {
                message
            }
        }
    }
`;

export default function useAsset(): AssetApi {
    const notification = useNotification();
    const { getText } = useLocale();
    const [createBackground] = useMutation<CreateBackgroundMutation, CreateBackgroundMutationVariables>(
        CREATE_BACKGROUND,
    );

    async function uploadBackground(name: string, image: File): Promise<void> {
        const response = await createBackground({
            variables: {
                input: {
                    name,
                    image,
                },
            },
        });

        if (response.errors && response.errors.length > 0) {
            notification.addError(
                getText('sections.assetUpload.notification.errors.singleAssetUpload.body'),
                getText('sections.assetUpload.notification.errors.singleAssetUpload.headline'),
            );
            throw response.errors;
        }

        const result = response.data?.createBackground;
        if (result?.__typename === 'CreateBackgroundError') {
            notification.addError(
                result.message,
                getText('sections.assetUpload.notification.errors.singleAssetUpload.headline'),
            );
            throw new Error(result.message);
        }

        notification.addSuccess(
            getText('sections.assetUpload.notification.success.body'),
            getText('sections.assetUpload.notification.success.headline'),
        );
    }

    return {
        uploadBackground,
    };
}
