import { useQuery } from '@apollo/client';

import { graphql } from '../generated/gql/gql';
import { ProductInput } from '../generated/gql/graphql';

type ProductInputsApi = {
    loading: boolean;
    error: string | undefined;
    inputs: ProductInput[];
};

const GET_PRODUCT_INPUTS = graphql(`
    query ProductInputs($productId: ID!) {
        product(id: $productId) {
            inputs {
                id
                identifier
                name
            }
        }
    }
`);

type Props = {
    productId: string;
};

export default function useProductInputs(props: Props): ProductInputsApi {
    const { productId } = props;

    const { loading, error, data } = useQuery(GET_PRODUCT_INPUTS, {
        variables: {
            productId,
        },
    });

    return {
        loading,
        error: error?.message,
        inputs: data?.product?.inputs || [],
    };
}
