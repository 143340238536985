import React, { ReactElement, useState } from 'react';

import SearchInput from '../components/inputs/search-input';
import Filters from '../components/lists/filters';
import ProductList from '../components/lists/product/product-list';
import Modal from '../components/modal/modal';
import Panel from '../components/panels/v2/panel';
import PanelContent from '../components/panels/v2/panel-content';
import PanelHeader from '../components/panels/v2/panel-header';
import { FieldFilterDefinition } from '../generated/gql/graphql';
import useProductFilters from '../hooks/use-product-filters';
import { Product } from '../hooks/use-product-presentations';

export type SwitchProductProps = {
    headline: string;
    selectedProductId: string;
    productSelected: (product: Product) => void;
    onClose(): void;
};

export default function SwitchProduct(props: SwitchProductProps): ReactElement {
    const { headline, selectedProductId, productSelected, onClose } = props;
    const { options: availableFilters } = useProductFilters();

    const [activeFilters, setActiveFilters] = useState<FieldFilterDefinition[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');

    return (
        <Modal open headline={headline} onClose={() => onClose()} className='h-fit max-w-3xl'>
            <section className='mx-[-24px]'>
                <Panel>
                    <PanelHeader>
                        <SearchInput value={searchQuery} onChange={setSearchQuery} />
                    </PanelHeader>
                    <PanelContent>
                        <Filters
                            filters={availableFilters}
                            selectedFilters={activeFilters}
                            onChangeFilters={setActiveFilters}
                        />
                        <ProductList
                            query={searchQuery}
                            filters={activeFilters}
                            gridSize={5}
                            selectedProductId={selectedProductId}
                            productSelected={(product) => {
                                productSelected(product);
                            }}
                        />
                    </PanelContent>
                </Panel>
            </section>
        </Modal>
    );
}
