import { useMutation } from '@apollo/client';

import { graphql } from '../generated/gql';

import useNotification from './use-notification';

type ProjectApi = {
    deleteLayoutById(id: string): Promise<void>;
};

const DELETE_LAYOUT = graphql(`
    mutation DeleteLayout($deleteLayoutId: ID!) {
        deleteLayout(id: $deleteLayoutId) {
            id
        }
    }
`);

export default function useDeleteLayout(): ProjectApi {
    const notification = useNotification();
    const [deleteLayout] = useMutation(DELETE_LAYOUT);

    async function deleteLayoutById(deleteLayoutId: string): Promise<void> {
        const result = await deleteLayout({
            variables: {
                deleteLayoutId,
            },
        });

        if (result.errors && result.errors.length > 0) {
            notification.addError('Something was wrong.');
            throw result.errors;
        }
    }

    return {
        deleteLayoutById,
    };
}
