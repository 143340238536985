import { ApolloError, useQuery } from '@apollo/client';

import { graphql } from '../generated/gql/gql';
import { MeQuery } from '../generated/gql/graphql';

export type User = MeQuery['me'];
type MeApi = {
    loading: boolean;
    error?: ApolloError;
    user?: User;
};

const GET_ME_OPTIONS = graphql(`
    query Me {
        me {
            name
            activeClient {
                logoUrl
                canUseShadowsAndReflections
                canAdaptLabel
            }
        }
    }
`);

export default function useUser(): MeApi {
    const { data, loading, error } = useQuery(GET_ME_OPTIONS);

    return {
        loading,
        error,
        user: data?.me,
    };
}
