import { ApolloError, useSubscription } from '@apollo/client';

import { graphql } from '../generated/gql';
import { LayoutRenderingProgressChangedSubscription } from '../generated/gql/graphql';

export type LayoutUpdated = LayoutRenderingProgressChangedSubscription['layoutRenderingProgressChanged'];

type Api = {
    loading: boolean;
    error?: ApolloError;
    layout?: LayoutUpdated;
};

const GET_SUBSCRIPTION = graphql(`
    subscription LayoutRenderingProgressChanged($layoutRenderingProgressChangedId: ID) {
        layoutRenderingProgressChanged(id: $layoutRenderingProgressChangedId) {
            id
            status
            renderingProgress
            readOnly
            previewRenderingJob {
                result {
                    renderingUrl
                }
            }
            latestRenderingJob {
                result {
                    renderingUrl
                }
            }
        }
    }
`);

export default function useLayoutRenderingProgressChangedSubscription(id: string): Api {
    const { data, loading, error } = useSubscription(GET_SUBSCRIPTION, {
        variables: {
            layoutRenderingProgressChangedId: id,
        },
    });

    return {
        loading,
        error,
        layout: data?.layoutRenderingProgressChanged,
    };
}
