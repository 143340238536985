import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { ReactElement } from 'react';

import { Layout, LayoutRenderingProgress } from '../../generated/gql/graphql';
import useLayoutRenderingProgressChangedSubscription from '../../hooks/use-layout-rendering-progress-changed';
import useLocale from '../../hooks/use-locale';

import TextIconButton from './text-icon-button';

export type GeneratePreviewButtonProps = {
    layout: Pick<Layout, 'id' | 'renderingProgress' | 'status' | 'readOnly'>;
    submit: () => void;
};

export default function GeneratePreviewButton(props: GeneratePreviewButtonProps): ReactElement {
    const { layout, submit } = props;
    const { getText } = useLocale();
    const { layout: refetchLayout } = useLayoutRenderingProgressChangedSubscription(layout.id);

    const updatedLayout = { ...layout, ...refetchLayout };

    const disabled = 
    updatedLayout.renderingProgress === LayoutRenderingProgress.InProgress ||
    updatedLayout.renderingProgress === LayoutRenderingProgress.PreviewInProgress ||
    updatedLayout.renderingProgress === LayoutRenderingProgress.PreviewAvailable;

    return (
        <TextIconButton
            label={getText('sections.imageComposer.generatePreview')}
            type='primary'
            onClick={async () => {
                submit();
            }}
            disabled={disabled}
            icon={<VisibilityIcon width={16} height={16} />}
        />
    );
}
