import React, { ReactElement, useEffect, useState } from 'react';

type Props = {
    file: File;
};

export default function AssetUploadPreview(props: Props): ReactElement {
    const { file } = props;
    const [url, setUrl] = useState('');

    useEffect(() => {
        if (/\.(jpe?g|png|webp)$/i.test(file.name)) {
            const reader = new FileReader();

            reader.addEventListener(
                'load',
                () => {
                    const resultString = reader.result as string;
                    if (resultString) {
                        setUrl(resultString);
                    }
                },
                false,
            );

            reader.readAsDataURL(file);
        }
    });

    return (
        <img
            src={url}
            alt='asset'
            width='100%'
            height='100%'
            className='h-40 w-40 rounded-xl border border-company object-contain'
        />
    );
}
