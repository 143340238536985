import React, { ReactElement } from 'react';

type Props = {
    children: string;
};

export default function Header(props: Props): ReactElement {
    const { children } = props;

    return <h2 className='font-bold uppercase text-companyMediumGrey'>{children}</h2>;
}
