import React, { PropsWithChildren, ReactElement } from 'react';

import { classNames } from '../../utils/classes';

export function LoadingOverlay(props: PropsWithChildren): ReactElement {
    return (
        <div
            className={classNames('absolute')}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: '#66666666',
            }}
        >
            {props.children}
        </div>
    );
}
