import './styles/globals.css';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './app';
import GeneratorProvider from './providers/generator-provider';
import GraphqlProvider from './providers/graphql-provider';
import KeycloakProvider from './providers/keycloak-provider';
import MarkerIoProvider from './providers/marker-io-provider';
import NotificationProvider from './providers/notification-provider';
import ProjectSceneProvider from './providers/project-scene-provider';

const root = createRoot(document.querySelector('#root') as HTMLElement);
root.render(
    <MarkerIoProvider>
        <NotificationProvider>
            <KeycloakProvider>
                <GraphqlProvider>
                    <ProjectSceneProvider>
                        <GeneratorProvider>
                            <StyledEngineProvider injectFirst>
                                <CssBaseline />
                                <App />
                            </StyledEngineProvider>
                        </GeneratorProvider>
                    </ProjectSceneProvider>
                </GraphqlProvider>
            </KeycloakProvider>
        </NotificationProvider>
    </MarkerIoProvider>,
);
