import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import mime from 'mime';

export interface DownloadFileItem {
    /** Url to download the file from */
    url: string;

    /** Fixed basename for this item */
    basename?: string;
}

export async function downloadFiles(items: DownloadFileItem[], basename?: string): Promise<void> {
    if (items.length === 0) {
        return;
    }

    if (items.length === 1) {
        const item = items[0];
        return saveAs(item.url, item.basename ?? basename);
    }

    const zip = new JSZip();
    for (const item of items) {
        const { url } = item;
        const fileResponse = await fetch(url, { method: 'GET' });

        const itemBasename = item.basename ?? new URL(url).pathname.split('/').pop()?.split('.').shift();
        if (!itemBasename) {
            throw new Error(`Can not determine filename for file from '${url}'`);
        }

        const mimeType = fileResponse.headers.get('content-type');
        if (!mimeType) {
            throw new Error(`No content type sent for file from '${url}'`);
        }
        const fileExtension = mime.getExtension(mimeType);
        if (!fileExtension) {
            throw new Error(`Can not determine file extension for file from '${url}' (mime type: ${mimeType})`);
        }
        const filename = `${itemBasename}.${fileExtension}`;

        zip.file(filename, fileResponse.arrayBuffer());
    }
    saveAs(await zip.generateAsync({ type: 'blob', compression: 'STORE' }), basename);
}
