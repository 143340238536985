import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { Card, CardContent, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Workspace } from '../../../hooks/use-workspace';

type Props = {
    workspaces: Workspace[] | undefined;
};

export default function WorkspacesScene(props: Props): ReactElement {
    const { workspaces } = props;

    const yourWorkspaces = workspaces?.filter((ws) => !ws.readOnly);
    const otherWorkspaces = workspaces?.filter((ws) => ws.readOnly);

    return (
        <div className='flex basis-[100%] flex-wrap content-start'>
            <div className='mb-5 flex basis-[100%] flex-wrap content-start gap-[20px] rounded-[15px] bg-white p-[50px] shadow'>
                <div className='flex h-fit w-full justify-items-center'>
                    <h2 className='text-[24px] text-rose-600'>
                        Your Workspaces{' '}
                        <span className='text-[16px] text-slate-400'> ({yourWorkspaces?.length ?? '0'})</span>
                    </h2>
                </div>
                {yourWorkspaces &&
                    yourWorkspaces.map((ws: any) => (
                        <Card
                            key={ws.id}
                            style={{ border: '1px solid #E8E8E8', boxShadow: 'none' }}
                            className='border-1 relative w-[calc(33%_-_10px)] cursor-pointer rounded-[15px]'
                        >
                            <Link to={`/projects/${ws.id}`} state={{ workspaceName: ws.name }}>
                                <div className='cursor-pointer bg-slate-100 text-center'>
                                    <FolderOutlinedIcon className='m-10 text-[150px] text-slate-400' />
                                </div>
                                <CardContent className='p-[20px] leading-[17px] last:pb-[10px]'>
                                    <Typography className='cursor-pointer text-[21px]'>{ws.name}</Typography>
                                    <Typography className='mb-[10px] text-[13px] text-slate-400'>
                                        {ws.projects?.length} Project(s)
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                    ))}
            </div>
            <div className='flex basis-[100%] flex-wrap content-start gap-[20px] rounded-[15px] bg-white p-[50px] shadow'>
                <div className='flex h-fit w-full justify-items-center'>
                    <h2 className='text-[24px] text-rose-600'>
                        Other Workspaces{' '}
                        <span className='text-[16px] text-slate-400'> ({otherWorkspaces?.length ?? '0'})</span>
                    </h2>
                </div>
                {otherWorkspaces &&
                    otherWorkspaces.map((ws: any) => (
                        <Card
                            key={ws.id}
                            style={{ border: '1px solid #E8E8E8', boxShadow: 'none' }}
                            className='border-1 relative w-[calc(33%_-_10px)] cursor-pointer rounded-[15px]'
                        >
                            <Link to={`/projects/${ws.id}`} state={{ workspaceId: ws.id }}>
                                <div className='cursor-pointer bg-slate-100 text-center'>
                                    <FolderOutlinedIcon className='m-10 text-[150px] text-slate-400' />
                                </div>
                                <CardContent className='p-[20px] leading-[17px] last:pb-[10px]'>
                                    <Typography className='cursor-pointer text-[21px]'>{ws.name}</Typography>
                                    <Typography className='mb-[10px] text-[13px] text-slate-400'>
                                        {ws.projects?.length} Project(s)
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                    ))}
            </div>
        </div>
    );
}
