import React, { ReactElement, useState } from 'react';

import useLocale from '../../hooks/use-locale';
import { ProductAnimation } from '../../hooks/use-product-presentations';
import { classNames } from '../../utils/classes';

export type AnimationListProps = {
    animations: ProductAnimation[];
    gridSize: number;
    selectedAnimationId?: string;
    onSelectAnimation(presentation: ProductAnimation): void;
};

export default function AnimationList(props: AnimationListProps): ReactElement {
    const { gridSize, animations, selectedAnimationId, onSelectAnimation } = props;
    const { getText } = useLocale();

    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
            {animations.length === 0 && (
                <div className='flex h-48 flex-col items-center justify-center'>
                    <p className='text-sm text-gray-500'>{getText('sections.presentation.animation.noResults')}</p>
                </div>
            )}
            <ul
                style={{
                    gridTemplateColumns: `repeat(${gridSize}, minmax(0, 1fr))`,
                }}
                className='grid gap-5 overflow-y-scroll p-5'
            >
                {animations.map((item) => (
                    <li key={item.id} className='cursor-pointer'>
                        <button
                            type='button'
                            className='flex w-full flex-col space-y-2'
                            onClick={() => onSelectAnimation(item)}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            {!isHovered && (
                                <img
                                    src={item.thumbnailUrl}
                                    alt={item.name || ''}
                                    style={{
                                        width: '100%',
                                        aspectRatio: '1/1',
                                    }}
                                    className={classNames(
                                        'rounded object-contain',
                                        selectedAnimationId === item.id ? 'ring-4 ring-company ring-offset-4' : '',
                                    )}
                                />
                            )}
                            {isHovered && (
                                <video
                                    muted
                                    autoPlay
                                    loop
                                    style={{
                                        width: '100%',
                                        aspectRatio: '1/1',
                                    }}
                                    className={classNames(
                                        'rounded object-contain',
                                        selectedAnimationId === item.id ? 'ring-4 ring-company ring-offset-4' : '',
                                    )}
                                >
                                    <source src={item.url} type='video/webm' />
                                    <track kind='captions' />
                                </video>
                            )}
                            <p className='text-sm text-gray-500'>{item.name}</p>
                        </button>
                    </li>
                ))}
            </ul>
        </>
    );
}

AnimationList.defaultProps = {
    selectedAnimationId: undefined,
};
