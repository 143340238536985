import { ApolloError, useQuery } from '@apollo/client';

import { graphql } from '../generated/gql';
import { RenderingSetting } from '../generated/gql/graphql';

type SettingsApi = {
    loading: boolean;
    error?: ApolloError;
    settings?: RenderingSetting[];
};

const GET_RENDERING_SETTINGS = graphql(`
    query RenderingSettings {
        renderingSettings {
            settings {
                identifier
                label
                description
                options {
                    label
                    value
                }
            }
        }
    }
`);

export default function useRenderingSettings(): SettingsApi {
    const { data, loading, error } = useQuery(GET_RENDERING_SETTINGS);
    return {
        loading,
        error,
        settings: data?.renderingSettings.settings,
    };
}

export const RenderingSettingOutputIdentifierPrefix = 'output-';
export const RenderingSettingOutputDisabled = 'disabled';
