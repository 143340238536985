import { Edit } from '@mui/icons-material';
import React, { ReactElement, useState } from 'react';

import useLocale from '../../hooks/use-locale';
import useProductInputs from '../../hooks/use-product-inputs';
import AdaptionSelectionModal, {
    NewOrExistingRenderingObjectProductInput,
} from '../../modals/adaption-selection-modal';
import { CanvasObject } from '../../types/canvas-object';

import TextIconButton from './text-icon-button';

export interface AdaptLabelProps {
    canvasObject: Pick<CanvasObject, 'productInputs' | 'presentation'> | undefined;
    productInputsChange: (productInputs: NewOrExistingRenderingObjectProductInput[]) => Promise<void>;
}

export default function AdaptLabelButton(props: AdaptLabelProps): ReactElement {
    const { canvasObject, productInputsChange } = props;
    const { getText } = useLocale();

    const { inputs } = useProductInputs({ productId: canvasObject?.presentation?.productId ?? '' });

    const [showAdaptLabelModal, setShowAdaptLabelModal] = useState<boolean>(false);

    function handleOnOpen(): void {
        setShowAdaptLabelModal(true);
    }

    return (
        <div>
            <TextIconButton
                onClick={() => handleOnOpen()}
                icon={<Edit width={16} height={16} />}
                label={getText('sections.imageComposer.adaptLabel')}
                type='tertiary'
                iconPosition='left'
                disabled={!inputs || inputs.length === 0}
            />
            {showAdaptLabelModal && (
                <AdaptionSelectionModal
                    inputs={inputs}
                    productInputs={canvasObject?.productInputs ?? undefined}
                    submit={productInputsChange}
                    cancel={() => setShowAdaptLabelModal(false)}
                />
            )}
        </div>
    );
}
