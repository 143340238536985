import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function InfoIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={width} height={height}>
            <path
                d='M11 17H13V11H11V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM11 9H13V7H11V9Z'
                fill='#323232'
            />
        </svg>
    );
}

InfoIcon.defaultProps = {
    width: 24,
    height: 24,
    className: '',
};
