import React, { ReactElement, useState } from 'react';

import Button from '../components/button/button';
import LabelList from '../components/lists/label/label-list';
import Modal from '../components/modal/modal';
import { ProductInput } from '../generated/gql/graphql';
import { LayoutRenderingObjectProductInput } from '../hooks/use-layout';
import useLocale from '../hooks/use-locale';
import { UpdateRenderingObjectProductInput } from '../hooks/use-update-layout';

export interface RenderingObjectNewProductInput {
    identifier: string;
    file: File;
}

export function isNewRenderingObjectProductInput(
    value: NewOrExistingRenderingObjectProductInput,
): value is RenderingObjectNewProductInput {
    return 'file' in value;
}

export function isExistingRenderingObjectProductInput(
    value: NewOrExistingRenderingObjectProductInput,
): value is LayoutRenderingObjectProductInput {
    return 'fileUrl' in value;
}

export type NewOrExistingRenderingObjectProductInput =
    | LayoutRenderingObjectProductInput
    | UpdateRenderingObjectProductInput;

export type AdaptionSelectionModalProps = {
    inputs: ProductInput[];
    productInputs: NewOrExistingRenderingObjectProductInput[] | undefined;
    submit(productInputs: NewOrExistingRenderingObjectProductInput[]): Promise<void>;
    cancel(): void;
};

export default function AdaptionSelectionModal(props: AdaptionSelectionModalProps): ReactElement {
    const { inputs, productInputs: initialProductInputs, submit, cancel } = props;

    const [productFiles, setProductFiles] = useState<NewOrExistingRenderingObjectProductInput[]>(
        initialProductInputs ?? [],
    );

    const { getText } = useLocale();

    const [submitting, setSubmitting] = useState<boolean>(false);

    function handleSelectFile(input: ProductInput, file: File): void {
        const index = productFiles ? productFiles.findIndex((obj) => obj.identifier === input.identifier) : -1;
        const updatedFiles = productFiles ? [...productFiles] : [];
        const updatedProductInput = { identifier: input.identifier, file };
        if (index === -1) {
            updatedFiles.push(updatedProductInput);
        } else {
            updatedFiles[index] = updatedProductInput;
        }
        setProductFiles(updatedFiles);
    }

    async function handleAdapt(): Promise<void> {
        setSubmitting(true);
        try {
            await submit(productFiles);
            cancel();
        } catch (error) {
            // TODO Show error
            alert(`Error submitting adaption labels: ${error}`);
        } finally {
            setSubmitting(false);
        }
    }

    function handleClose(): void {
        cancel();
    }

    function handleCancel(): void {
        cancel();
    }

    return (
        <Modal
            open={true}
            headline={getText('sections.adaption.headline')}
            onClose={() => handleClose()}
            className='max-w-3xl'
            overlay={submitting ? 'Submitting...' : undefined}
        >
            <LabelList
                inputs={inputs}
                select={(i, f) => handleSelectFile(i, f)}
                selectedFiles={productFiles}
                reset={() => setProductFiles([])}
            />
            <div className='mt-6 flex flex-row justify-end space-x-3'>
                <Button
                    onClick={() => handleCancel()}
                    label={getText('sections.adaption.cancel')}
                    type='secondary'
                    outline={false}
                />
                <Button onClick={() => handleAdapt()} label={getText('sections.adaption.submit')} outline={false} />
            </div>
        </Modal>
    );
}
