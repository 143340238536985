import { TextField } from '@mui/material';
import React, { ReactElement, useState } from 'react';

import Button from '../components/button/button';
import Modal from '../components/modal/modal';
import { Layout } from '../generated/gql/graphql';
import useUpdateLayout from '../hooks/use-update-layout';

type Props = {
    headline: string;
    open: boolean;
    layout: Pick<Layout, 'id' | 'name'>;
    onClose(nameChanged: boolean): void;
};

export default function LayoutRenameModal(props: Props): ReactElement {
    const { headline, open, layout, onClose } = props;

    const [value, setValue] = useState<string>(layout.name ?? '');

    const { renameLayoutById } = useUpdateLayout();

    async function onSubmit(): Promise<void> {
        try {
            if (layout?.id) {
                await renameLayoutById(layout.id, value);
            }

            onClose(true);
        } catch {
            onClose(false);
        }
    }

    return (
        <Modal open={open} headline={headline} onClose={() => onClose(false)} className='h-fit max-w-xl'>
            <>
                <TextField
                    fullWidth
                    margin='dense'
                    type='text'
                    variant='standard'
                    error={value?.length < 4}
                    label='Layout name'
                    defaultValue={value}
                    onChange={(e) => setValue(e.target.value)}
                    helperText={value?.length < 4 && 'Project name should have minimum 3 characters.'}
                />
                <div className='flex flex-row justify-between pt-6'>
                    <button type='button' onClick={() => onClose(false)}>
                        Cancel
                    </button>
                    <Button label='Confirm' onClick={() => onSubmit()} disabled={value?.length < 4} />
                </div>
            </>
        </Modal>
    );
}
