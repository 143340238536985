import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function ImageIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={width} height={height}>
            <path d='M19.1999 4.08008H4.7999C3.47444 4.08008 2.3999 5.15462 2.3999 6.48008V17.2801C2.3999 18.6055 3.47444 19.6801 4.7999 19.6801H19.1999C20.5254 19.6801 21.5999 18.6055 21.5999 17.2801V6.48008C21.5999 5.15462 20.5254 4.08008 19.1999 4.08008ZM19.7999 17.2801C19.7999 17.6109 19.5307 17.8801 19.1999 17.8801H4.7999C4.46906 17.8801 4.1999 17.6109 4.1999 17.2801V6.48008C4.1999 6.14924 4.46906 5.88008 4.7999 5.88008H19.1999C19.5307 5.88008 19.7999 6.14924 19.7999 6.48008V17.2801ZM17.6352 12.1153C17.8687 12.3489 17.9999 12.6656 17.9999 12.9958V15.64C17.9999 15.883 17.8029 16.08 17.5599 16.08H6.43988C6.19688 16.08 5.9999 15.883 5.9999 15.64V14.7958C5.9999 14.4656 6.13112 14.1489 6.36464 13.9153L7.7888 12.4912C7.96064 12.3193 8.23922 12.3193 8.41106 12.4912L9.2888 13.3689C9.46064 13.5408 9.73922 13.5408 9.91106 13.3689L14.0888 9.19118C14.2606 9.01934 14.5392 9.01934 14.7111 9.19118L17.6352 12.1153H17.6352ZM7.1999 9.18008C7.1999 8.35166 7.87148 7.68008 8.6999 7.68008C9.52832 7.68008 10.1999 8.35166 10.1999 9.18008C10.1999 10.0085 9.52832 10.6801 8.6999 10.6801C7.87148 10.6801 7.1999 10.0085 7.1999 9.18008Z' />
        </svg>
    );
}

ImageIcon.defaultProps = {
    width: 24,
    height: 24,
    className: '',
};
