import { ApolloError, useQuery } from '@apollo/client';

import { graphql } from '../generated/gql';
import { ProjectSortField } from '../generated/gql/graphql';
import { StringBoolean } from '../types/enums/string-boolean';
import { Project } from '../types/project';

type ProjectApi = {
    loading: boolean;
    error?: ApolloError;
    fetchProjects?: Project;
    refetchProjects: () => void;
    searchProjects: (params: string | undefined) => Promise<Project[] | undefined>;
    sortProjects: (field: string) => Promise<Project[] | undefined>;
};

const GET_PROJECTS = graphql(`
    query Projects($query: ProjectQuery) {
        projects(query: $query) {
            items {
                id
                name
                layoutCount
                lastEditedAt
                readOnly
                workspace {
                    name
                    id
                }
            }
        }
    }
`);

export default function useProjects(id: string | undefined): ProjectApi {
    const { data, loading, error, refetch } = useQuery(GET_PROJECTS, {
        variables: {
            query: {
                filter: {
                    workspace: {
                        equals: id,
                        null: false,
                    },
                },
                sort: [
                    {
                        field: 'CreatedAt' as ProjectSortField,
                        descending: true,
                    },
                ],
            },
        },
    });

    async function search(params: string | undefined): Promise<Project[] | undefined> {
        const result = await refetch({
            query: {
                filter: {
                    name: {
                        contains: params,
                    },
                    workspace: {
                        equals: id,
                    },
                },
            },
        });

        return result.data.projects.items ?? undefined;
    }

    async function sort(field: string): Promise<Project[] | undefined> {
        const fields = field.split('_');

        const result = await refetch({
            query: {
                filter: {
                    workspace: {
                        equals: id,
                    },
                },
                sort: [
                    {
                        field: fields[0] as ProjectSortField,
                        descending: fields[1] === StringBoolean.True,
                    },
                ],
            },
        });

        return result.data.projects.items ?? undefined;
    }

    return {
        loading,
        error,
        fetchProjects: data?.projects?.items ?? undefined,
        refetchProjects: refetch,
        searchProjects: search,
        sortProjects: sort,
    };
}
