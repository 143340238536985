import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function IconDownload(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            className={className}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
            width={width}
            height={height}
        >
            <g>
                <path
                    d='M484.078,27.923C466.072,9.918,442.131,0.002,416.667,0.002L95.334,0C42.766,0.003,0,42.77,0.001,95.334L0,416.668
                    C0.001,469.234,42.768,512,95.335,512h321.332C469.234,512,512,469.234,512,416.667V95.335
                    C512,69.87,502.084,45.929,484.078,27.923z M482,416.667C482,452.692,452.692,482,416.667,482H95.335
                    C59.31,482,30.001,452.691,30,416.667l0.001-321.334C30,59.31,59.309,30.002,95.335,30l321.332,0.002
                    c17.451,0,33.858,6.795,46.198,19.134C475.205,61.476,482,77.883,482,95.335V416.667z'
                />
                <path
                    d='M346.131,217.492c-5.857-5.857-15.355-5.858-21.213,0L271,271.412L270.999,111.4c0-8.284-6.716-15-15-15s-15,6.716-15,15
                    L241,271.415l-53.922-53.921c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213l79.528,79.526
                    c2.813,2.814,6.628,4.394,10.606,4.394s7.794-1.581,10.607-4.394l79.526-79.528C351.989,232.847,351.989,223.35,346.131,217.492z'
                />
                <path
                    d='M400.602,385.601h-289.2c-8.284,0-15,6.716-15,15s6.716,15,15,15h289.2c8.284,0,15-6.716,15-15
                    C415.602,392.316,408.886,385.601,400.602,385.601z'
                />
            </g>
        </svg>
    );
}

IconDownload.defaultProps = {
    width: 24,
    height: 24,
    className: '',
};
