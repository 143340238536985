import React, { ReactElement, SyntheticEvent } from 'react';

type Props = {
    imageSrc: string;
    classes: string;
    onLoad(e: SyntheticEvent<HTMLImageElement, Event>): void;
};

export default function Packshot(props: Props): ReactElement {
    const { imageSrc, classes, onLoad } = props;

    return (
        <img
            src={imageSrc}
            onLoad={onLoad}
            className={`pointer-events-none w-full select-none border ${classes}`}
            alt='packshot'
        />
    );
}
