import React, { ReactElement } from 'react';

type Props = {
    id?: string;
    placeholder: string;
    value: string;
    disabled?: boolean;
    onChange: (value: string) => void;
};

export default function TextInput(props: Props): ReactElement {
    const { id, placeholder, value, disabled, onChange } = props;

    return (
        <div className='flex max-w-xs items-center space-x-2 rounded-xl bg-white p-2 px-4 outline outline-1 outline-companyMediumGrey'>
            <input
                id={id}
                placeholder={placeholder}
                className='w-full outline-none'
                value={value}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
            />
        </div>
    );
}

TextInput.defaultProps = {
    id: '',
    disabled: false,
};
