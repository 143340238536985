import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function Brightness(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={width} height={height}>
            <path
                d='M19.9999 8.69141V4.00141H15.3099L11.9999 0.691406L8.68994 4.00141H3.99994V8.69141L0.689941 12.0014L3.99994 15.3114V20.0014H8.68994L11.9999 23.3114L15.3099 20.0014H19.9999V15.3114L23.3099 12.0014L19.9999 8.69141ZM17.9999 14.4814V18.0014H14.4799L11.9999 20.4814L9.51994 18.0014H5.99994V14.4814L3.51994 12.0014L5.99994 9.52141V6.00141H9.51994L11.9999 3.52141L14.4799 6.00141H17.9999V9.52141L20.4799 12.0014L17.9999 14.4814ZM11.9999 6.50141V17.5014C15.0299 17.5014 17.4999 15.0314 17.4999 12.0014C17.4999 8.97141 15.0299 6.50141 11.9999 6.50141Z'
                fill='#7A7676'
            />
        </svg>
    );
}

Brightness.defaultProps = {
    width: 24,
    height: 24,
    className: '',
};
