import { gql, useQuery } from '@apollo/client';

import { FieldFilterDefinition } from '../generated/gql/graphql';

type ProductFiltersApi = {
    loading: boolean;
    error: string | undefined;
    options: FieldFilterDefinition[];
};

const GET_PRODUCT_FILTERS = gql`
    query ProductFilters {
        productFilters {
            fieldName
            name
            options {
                label
                value
            }
        }
    }
`;

export default function useProductFilters(): ProductFiltersApi {
    const { loading, error, data } = useQuery(GET_PRODUCT_FILTERS);

    return {
        loading,
        error: error?.message,
        options: data?.productFilters || [],
    };
}
