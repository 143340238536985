import React, { ReactElement } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import EditorPage from './pages/editor-page';
import Layouts from './pages/layouts';
import Projects from './pages/projects';
import Workspaces from './pages/workspaces';
import { Routes as RoutesPath } from './types/enums/routes';

function App(): ReactElement {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={`${RoutesPath.EDITOR}/:id`} element={<EditorPage />} />
                <Route path={`${RoutesPath.PROJECTS}/:id`} element={<Projects />} />
                <Route path={RoutesPath.WORKSPACES} element={<Workspaces />} />
                <Route path={`${RoutesPath.PROJECT}/:id`} element={<Layouts />} />
                <Route path='*' element={<Workspaces />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
