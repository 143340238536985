import React, { ReactElement } from 'react';

import { ProductPerspective } from '../../hooks/use-product-presentations';
import { classNames } from '../../utils/classes';

export type PerspectiveListProps = {
    perspectives: ProductPerspective[];
    gridSize: number;
    selectedPerspectiveId?: string;
    onSelectPerspective(presentation: ProductPerspective): void;
};

export default function PerspectiveList(props: PerspectiveListProps): ReactElement {
    const { gridSize, perspectives, selectedPerspectiveId, onSelectPerspective } = props;

    return (
        <ul
            style={{
                gridTemplateColumns: `repeat(${gridSize}, minmax(0, 1fr))`,
            }}
            className='grid gap-5 overflow-y-scroll p-5'
        >
            {perspectives.map((item) => (
                <li key={item.id} className='cursor-pointer'>
                    <button
                        type='button'
                        className='flex w-full flex-col space-y-2'
                        onClick={() => onSelectPerspective(item)}
                    >
                        <img
                            src={item.thumbnailUrl}
                            alt={item.name || ''}
                            style={{
                                width: '100%',
                                aspectRatio: '1/1',
                            }}
                            className={classNames(
                                'rounded object-contain',
                                selectedPerspectiveId === item.id ? 'ring-4 ring-company ring-offset-4' : '',
                            )}
                        />
                        <p className='text-sm text-gray-500'>{item.name}</p>
                    </button>
                </li>
            ))}
        </ul>
    );
}

PerspectiveList.defaultProps = {
    selectedPerspectiveId: undefined,
};
