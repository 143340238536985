import { ApolloError, useQuery } from '@apollo/client';

import { graphql } from '../generated/gql';
import { GetProjectByIdQuery } from '../generated/gql/graphql';

export type Project = GetProjectByIdQuery['project'];

type ProjectApi = {
    loading: boolean;
    error?: ApolloError;
    project?: Project;
};

const GET_PROJECT_BY_ID = graphql(`
    query GetProjectById($projectId: ID!) {
        project(id: $projectId) {
            id
            name
            workspaceId
            readOnly
        }
    }
`);

export default function useProject(id: string): ProjectApi {
    const { data, loading, error } = useQuery(GET_PROJECT_BY_ID, {
        variables: {
            projectId: id,
        },
    });

    return {
        loading,
        error,
        project: data?.project ?? undefined,
    };
}
