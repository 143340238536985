import { Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../components/button/button';
import Modal from '../components/modal/modal';
import { Layout, LayoutRenderingProgress } from '../generated/gql/graphql';
import Routes from '../types/enums/routes';

type Props = {
    headline: string;
    open: boolean;
    layout: Pick<Layout, 'id' | 'renderingProgress' | 'readOnly'>;
    onClose(): void;
    onDuplicate(): void;
};

export default function LayoutActionsModal(props: Props): ReactElement {
    const { headline, open, layout, onClose, onDuplicate } = props;

    const navigate = useNavigate();

    function handleEditAction(): void {
        navigate(`${Routes.EDITOR}/${layout.id}`);
        onClose();
    }

    function handleDuplicateAction(): void {
        onDuplicate();
        onClose();
    }

    const { renderingProgress, readOnly } = layout;
    const canEditLayout =
        renderingProgress !== LayoutRenderingProgress.InProgress &&
        renderingProgress !== LayoutRenderingProgress.Generated &&
        renderingProgress !== LayoutRenderingProgress.PreviewInProgress;

    return (
        <Modal open={open} headline={headline} onClose={() => onClose()} className='h-fit max-w-xl'>
            <Typography>
                {canEditLayout
                    ? readOnly
                        ? 'This layout is not part of your workspace. ' +
                          'Please duplicate it to one of your workspace`s projects, ' +
                          'so that you can start using this layout as a template.'
                        : 'Do you want to edit this Layout or create a duplicate?'
                    : renderingProgress === LayoutRenderingProgress.InProgress ||
                        renderingProgress === LayoutRenderingProgress.PreviewInProgress
                      ? 'This layout is currently being generated, please wait. If you want to re-use this layout you can duplicate it with the button below.'
                      : 'This layout has already been completed and is locked for editing. Please duplicate it, to reuse this layout.'}
            </Typography>
            <div className='flex flex-row justify-end pt-6'>
                {canEditLayout && !readOnly && (
                    <Button className='mr-[10px]' label='Edit' onClick={() => handleEditAction()} />
                )}
                <Button label='Duplicate' onClick={() => handleDuplicateAction()} />
            </div>
        </Modal>
    );
}
