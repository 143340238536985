import { ApolloError, useQuery } from '@apollo/client';

import { graphql } from '../generated/gql/gql';
import { WorkspacesQuery } from '../generated/gql/graphql';

type ArrayElement<ArrayType extends readonly unknown[] | null> = ArrayType extends readonly (infer ElementType)[]
    ? ElementType
    : never;

export type Workspaces = WorkspacesQuery['workspaces'];
export type Workspace = ArrayElement<Workspaces>;

type WorkspaceApi = {
    loading: boolean;
    error?: ApolloError;
    workspaces?: Workspaces;
    refetchWorkspaces: () => void;
};

const GET_ME_WORKSPACES = graphql(`
    query Workspaces {
        workspaces {
            id
            name
            projects {
                id
                name
            }
            subsidiary
            readOnly
        }
    }
`);

export default function useWorkspace(): WorkspaceApi {
    const { data, loading, error, refetch } = useQuery(GET_ME_WORKSPACES, {
        fetchPolicy: 'network-only',
    });

    return {
        loading,
        error,
        workspaces: data?.workspaces ?? undefined,
        refetchWorkspaces: refetch,
    };
}
