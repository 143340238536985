import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import React, { ChangeEvent, ReactElement, useState } from 'react';

type Props = {
    label: string;
    search: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export default function Search(props: Props): ReactElement {
    const { label, search } = props;
    const [value, setValue] = useState<string>();

    return (
        <div className='relative w-full'>
            <span className='absolute left-[15px] top-[7px] z-10 text-[12px] text-slate-500'>{label}</span>
            <TextField
                onChange={(e) => {
                    search(e);
                    setValue(e.target.value);
                }}
                type='primary'
                defaultValue={value ?? ''}
                className='w-full rounded-[15px] border-0 border-rose-600 bg-white shadow '
                placeholder='Enter keyword'
                sx={{
                    '& .MuiInputBase-input': { paddingTop: '24px', paddingBottom: '9px' },
                    '.MuiOutlinedInput-notchedOutline': { border: 0, borderRadius: '15px' },
                }}
                InputProps={{
                    endAdornment: <SearchIcon className='text-[28px] text-slate-400' />,
                }}
            />
        </div>
    );
}
