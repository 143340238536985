import { Edit, VideoLibrary } from '@mui/icons-material';
import React, { ReactElement } from 'react';

import { PresentationType } from '../../generated/gql/graphql';
import useLocale from '../../hooks/use-locale';
import useUser from '../../hooks/use-user';
import { CanvasObject } from '../../types/canvas-object';
import SpanPackshotButton, { SpanPackshotButtonProps } from '../button/span-packshot-button';
import IconTooltip from '../icons/icon-tooltip';

import { canvasSize } from './layout-editor';

export type CanvasPanelHeaderProps = {
    title: string;

    selectedCanvasObject:
        | (Pick<CanvasObject, 'presentation' | 'productInputs'> & SpanPackshotButtonProps['canvasObject'])
        | undefined;
    selectedCanvasObjectChange: SpanPackshotButtonProps['canvasObjectChange'];
};

export default function CanvasPanelHeader(props: CanvasPanelHeaderProps): ReactElement {
    const { title, selectedCanvasObject, selectedCanvasObjectChange } = props;
    const { user } = useUser();

    const { getText } = useLocale();

    return (
        <div className='border-b-1 flex items-center justify-between space-x-5 border-companyDarkGrey px-5 py-5'>
            <p className='font-bold uppercase text-companyMediumGrey'>{title}</p>
            <div className='flex flex-row items-center space-x-5'>
                <SpanPackshotButton
                    canvasSize={canvasSize}
                    canvasObject={selectedCanvasObject}
                    canvasObjectChange={selectedCanvasObjectChange}
                />
                {user?.activeClient?.canAdaptLabel && (
                    <IconTooltip
                        icon={<Edit />}
                        activeTooltip={getText('sections.canvas.header.adaptIndicator.tooltip.active')}
                        inactiveTooltip={getText('sections.canvas.header.adaptIndicator.tooltip.inactive')}
                        active={
                            selectedCanvasObject &&
                            !!selectedCanvasObject.productInputs &&
                            selectedCanvasObject.productInputs.length > 0
                        }
                    />
                )}
                <IconTooltip
                    icon={<VideoLibrary />}
                    activeTooltip={getText('sections.canvas.header.animationIndicator.tooltip.active')}
                    inactiveTooltip={getText('sections.canvas.header.animationIndicator.tooltip.inactive')}
                    active={selectedCanvasObject?.presentation?.type === PresentationType.Animation}
                />
            </div>
        </div>
    );
}
