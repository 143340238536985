import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { ReactElement } from 'react';

import { LayoutRenderingProgress } from '../../../generated/gql/graphql';

export type GenerationStatusSelectorProps = {
    renderingProgress: LayoutRenderingProgress | undefined;
    renderingProgressChange: (value: LayoutRenderingProgress | undefined) => void;
};

export default function GenerationStatusSelector({
    renderingProgress,
    renderingProgressChange,
}: GenerationStatusSelectorProps): ReactElement {
    const handleSelectChange = async (event: SelectChangeEvent): Promise<void> => {
        const newValue = event.target.value;
        renderingProgressChange(newValue ? (newValue as LayoutRenderingProgress) : undefined);
    };

    return (
        <FormControl style={{ minWidth: 150, maxWidth: 200 }} size='small'>
            <InputLabel className='text-[14px]'>Filter</InputLabel>
            <Select
                className='rounded-[15px] border-0 text-left text-[14px]'
                value={renderingProgress}
                label='Filter'
                onChange={handleSelectChange}
            >
                <MenuItem defaultValue=''>No Filter</MenuItem>
                <MenuItem value={LayoutRenderingProgress.Generated}>Completed</MenuItem>
                <MenuItem value={LayoutRenderingProgress.InProgress}>In Progress</MenuItem>
                <MenuItem value={LayoutRenderingProgress.NotStarted}>Not Started</MenuItem>
                <MenuItem value={LayoutRenderingProgress.Failed}>Failed</MenuItem>
                <MenuItem value={LayoutRenderingProgress.PreviewAvailable}>Preview Available</MenuItem>
                <MenuItem value={LayoutRenderingProgress.PreviewFailed}>Preview Failed</MenuItem>
                <MenuItem value={LayoutRenderingProgress.PreviewInProgress}>Preview In Progress</MenuItem>
            </Select>
        </FormControl>
    );
}
