import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import React, { ReactElement, useState } from 'react';

import useDeleteProject from '../../hooks/use-delete-project';
import useLocale from '../../hooks/use-locale';
import useProjectScene from '../../hooks/use-project-scene';
import useWorkspace from '../../hooks/use-workspace';
import ProjectDuplicateModal from '../../modals/project-duplicate-modal';
import ProjectModal from '../../modals/project-modal';
import { Project } from '../../types/project';

type Props = {
    project: Project;
};

export default function ProjectDropdownSelector(props: Props): ReactElement {
    const { project } = props;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
    const [isRenameModalOpen, setisRenameModalOpen] = useState(false);
    const [isDuplicateModalOpen, setisDuplicateModalOpen] = useState(false);

    const { projects, selectProjects } = useProjectScene().selection;
    const { getText } = useLocale();
    const { deleteProjectById } = useDeleteProject();
    const { refetchWorkspaces } = useWorkspace();

    const handleToggleRenameModal = (): void => {
        setisRenameModalOpen(!isRenameModalOpen);
    };

    const handleToggleDuplicateModal = (): void => {
        setisDuplicateModalOpen(!isDuplicateModalOpen);
    };

    const handleDeleteProject = async (): Promise<void> => {
        alert(getText('notifications.projects.removeProject'));
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you would like to delete this project folder?')) {
            const newProjects = projects?.filter(p => p.id !== project.id);
            selectProjects(newProjects);

            await deleteProjectById(project.id);
            await refetchWorkspaces();
        }
    };

    const toggleDropdown = (event: React.MouseEvent<HTMLElement>): void => {
        setIsDropdownOpen(!isDropdownOpen);
        setAnchorEl(event.currentTarget ?? undefined);
    };

    return (
        <div className='absolute !right-[17px] !top-[17px] rounded-[25px] bg-white'>
            <IconButton onClick={toggleDropdown}>
                <MoreVertIcon style={{ color: isDropdownOpen ? '#DD2127' : '' }} />
            </IconButton>
            {isDropdownOpen && (
                <Menu
                    id='long-menu'
                    className='rounded-[15px] shadow'
                    MenuListProps={{ 'aria-labelledby': 'long-button' }}
                    anchorEl={anchorEl}
                    open={isDropdownOpen}
                    onClose={toggleDropdown}
                    PaperProps={{
                        style: {
                            width: 'auto',
                            padding: 0,
                            borderRadius: '15px',
                        },
                    }}
                >
                    <MenuItem
                        onClick={(e) => {
                            handleToggleDuplicateModal();
                            toggleDropdown(e);
                        }}
                        className='hover:text-[#999999] hover:[&>svg]:fill-company'
                    >
                        <Tooltip title='Duplicate' placement='right' arrow>
                            <FolderCopyOutlinedIcon />
                        </Tooltip>
                    </MenuItem>
                    {!project.readOnly && (
                        <MenuItem
                            onClick={(e) => {
                                handleToggleRenameModal();
                                toggleDropdown(e);
                            }}
                            className='hover:text-[#999999] hover:[&>svg]:fill-company'
                        >
                            <Tooltip title='Rename' placement='right' arrow>
                                <ModeEditOutlineOutlinedIcon />
                            </Tooltip>
                        </MenuItem>
                    )}
                    {!project.readOnly && (
                        <MenuItem
                            onClick={handleDeleteProject}
                            className='hover:text-[#999999] hover:[&>svg]:fill-company'
                        >
                            <Tooltip title='Delete' placement='right' arrow>
                                <DeleteOutlineOutlinedIcon />
                            </Tooltip>
                        </MenuItem>
                    )}
                </Menu>
            )}
            <ProjectModal
                open={isRenameModalOpen}
                headline='Rename Project'
                onClose={handleToggleRenameModal}
                project={project}
            />
            <ProjectDuplicateModal
                open={isDuplicateModalOpen}
                headline='Duplicate Project'
                onClose={handleToggleDuplicateModal}
                project={project}
            />
        </div>
    );
}
