import React, { ReactElement } from 'react';

type Props = {
    children: ReactElement | ReactElement[];
};

export default function PanelContent(props: Props): ReactElement {
    const { children } = props;

    return <div className='flex flex-col px-5'>{children}</div>;
}
