import { QueryResult, gql, useQuery } from '@apollo/client';

import { GetBackgroundQuery, GetBackgroundQueryVariables } from '../generated/gql/graphql';

const GET_BACKGROUND = gql`
    query GetBackground($id: ID!) {
        background(id: $id) {
            id
            name
            url
        }
    }
`;

export default function useBackground(id: string): QueryResult<GetBackgroundQuery, GetBackgroundQueryVariables> {
    return useQuery<GetBackgroundQuery, GetBackgroundQueryVariables>(GET_BACKGROUND, {
        fetchPolicy: 'no-cache',
        variables: {
            id,
        },
    });
}
