import { useMutation } from '@apollo/client';

import { graphql } from '../generated/gql';

import useNotification from './use-notification';

type ProjectApi = {
    deleteProjectById(id: string): void;
};

const DELETE_PROJECT = graphql(`
    mutation DeleteProject($deleteProjectId: ID!) {
        deleteProject(id: $deleteProjectId) {
            id
        }
    }
`);

export default function useDeleteProject(): ProjectApi {
    const notification = useNotification();
    const [deleteProject] = useMutation(DELETE_PROJECT);

    async function deleteProjectById(id: string): Promise<void> {
        const result = await deleteProject({
            variables: {
                deleteProjectId: id,
            },
        });

        if (result.errors && result.errors.length > 0) {
            notification.addError('Something was wrong.');
            throw result.errors;
        }
    }

    return {
        deleteProjectById,
    };
}
