import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { Button } from '@mui/material';
import React, { ReactElement, useState } from 'react';

import useProjectScene from '../../hooks/use-project-scene';
import ProjectModal from '../../modals/project-modal';

export default function CreateProjectButton(): ReactElement {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const { projects } = useProjectScene().selection;

    const isReadOnly = projects?.some((p) => p.readOnly);

    if (isReadOnly) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    function handleToggleModal(): void {
        setIsCreateModalOpen(!isCreateModalOpen);
    }

    return (
        <>
            <Button
                onClick={() => handleToggleModal()}
                className='h-full w-[240px] rounded-[15px] border-none bg-white px-[0px] text-[15px] font-bold text-company shadow'
                startIcon={<CreateNewFolderOutlinedIcon className='!text-[30px]' />}
            >
                NEW PROJECT
            </Button>
            <ProjectModal open={isCreateModalOpen} headline='Create Project' onClose={() => handleToggleModal()} />
        </>
    );
}
