import React, { ReactElement, ReactNode, useEffect } from 'react';

import useProjectScene from '../../../hooks/use-project-scene';
import useProjects from '../../../hooks/use-projects';

type Props = {
    workspaceId: string | undefined;
    children: ReactNode;
};

export default function ProjectsContainer(props: Props): ReactElement {
    const { workspaceId, children } = props;
    const { selectProjects } = useProjectScene().selection;
    const { fetchProjects } = useProjects(workspaceId);

    useEffect(() => {
        selectProjects(fetchProjects);
    }, [fetchProjects]);

    return <div className='flex w-full flex-row gap-[25px]'>{children}</div>;
}
