import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { Card, CardContent, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { Project } from '../../types/project';
import ProjectDropdownSelector from '../selectors/project-dropdown-selector';

type Props = {
    project: Project;
};

export default function ProjectItem(props: Props): ReactElement {
    const { project } = props;

    return (
        <Card
            style={{ border: '1px solid #E8E8E8', boxShadow: 'none' }}
            className='border-1 relative w-[calc(33%_-_10px)] cursor-pointer rounded-[15px]'
        >
            <ProjectDropdownSelector project={project} />
            <Link to={`/project/${project.id}`}>
                <div className='cursor-pointer bg-slate-100 text-center'>
                    <FolderOutlinedIcon className='m-10 text-[150px] text-slate-400' />
                </div>
                <CardContent className='p-[20px] leading-[17px] last:pb-[10px]'>
                    <Typography className='cursor-pointer text-[21px]'>{project.name}</Typography>
                    <Typography className='mb-[10px] text-[13px] text-slate-400'>
                        {project.layoutCount} Layout(s)
                    </Typography>
                    <Typography className='border-[#4a4a4a] text-[13px] font-light text-slate-400'>
                        Last edited: {project.lastEditedAt ? format(new Date(project.lastEditedAt), 'dd-MM-yyyy') : '-'}
                    </Typography>
                </CardContent>
            </Link>
        </Card>
    );
}
