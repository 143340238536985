import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { ReactElement } from 'react';

export interface LayoutSortBySelectProps {
    /** Current sort by value */
    sortBy: string;

    /** Sort by value changed */
    sortByChange: (value: string) => void;
}

export default function LayoutSortBySelect({ sortBy, sortByChange }: LayoutSortBySelectProps): ReactElement {
    const handleChange = async (e: SelectChangeEvent): Promise<void> => {
        if (e.target.value) {
            sortByChange(e.target.value);
        }
    };

    return (
        <FormControl style={{ minWidth: 100 }} size='small'>
            <InputLabel className='text-[14px]'>Sort by</InputLabel>
            <Select
                className='rounded-[15px] border-0 text-left text-[14px]'
                label='Sort by'
                value={sortBy}
                onChange={handleChange}
            >
                <MenuItem defaultChecked value='CreatedAt_true'>
                    Newest
                </MenuItem>
                <MenuItem value='CreatedAt_false'>Oldest</MenuItem>
                <MenuItem value='Name_false'>Sort A-Z</MenuItem>
                <MenuItem value='Name_true'>Sort Z-A</MenuItem>
            </Select>
        </FormControl>
    );
}
