import LocalizedStrings from 'react-localization';

import de from './de.json';
import en from './en.json';

const TextContainer = new LocalizedStrings({
    en: {
        ...en,
    },
    de: {
        ...de,
    },
});

export default TextContainer;
