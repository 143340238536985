import { useContext } from 'react';

import { NotificationContext } from '../providers/notification-provider';

type NotificationApi = {
    addError(message: string, title?: string): void;
    addSuccess(message: string, title?: string): void;
    addInfo(message: string, title?: string): void;
};

export default function useNotification(): NotificationApi {
    const notification = useContext(NotificationContext);

    return {
        addError: (msg, title) => notification.showError(msg, title),
        addSuccess: (msg, title) => notification.showSuccess(msg, title),
        addInfo: (msg, title) => notification.showInfo(msg, title),
    };
}
