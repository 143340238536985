import { useMutation } from '@apollo/client';

import { graphql } from '../generated/gql';

import useNotification from './use-notification';

type ProjectApi = {
    duplicateProjectById(projectId: string, workspaceId: string | undefined, name: string): void;
};

const DUPLICATE_PROJECT = graphql(`
    mutation DuplicateProject($input: DuplicateProjectInput!) {
        duplicateProject(input: $input) {
            name
        }
    }
`);

export default function useDuplicateLayout(): ProjectApi {
    const notification = useNotification();
    const [duplicateProject] = useMutation(DUPLICATE_PROJECT);

    async function duplicateProjectById(
        projectId: string,
        workspaceId: string | undefined,
        name: string,
    ): Promise<void> {
        const result = await duplicateProject({
            variables: {
                input: {
                    projectId,
                    workspaceId,
                    name,
                },
            },
        });

        if (result.errors && result.errors.length > 0) {
            notification.addError('Something was wrong.');
            throw result.errors;
        }
    }

    return {
        duplicateProjectById,
    };
}
