import { TextField } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '../components/button/button';
import Modal from '../components/modal/modal';
import useCreateProject from '../hooks/use-create-project';
import useProjects from '../hooks/use-projects';
import useUpdateProject from '../hooks/use-update-project';
import { Project } from '../types/project';

type Props = {
    headline: string;
    open: boolean;
    project?: Project;
    onClose(): void;
};

export default function ProjectModal(props: Props): ReactElement {
    const { headline, open, project, onClose } = props;

    const [value, setValue] = useState(project?.name ?? undefined);

    const { id } = useParams();
    const { createNewProject } = useCreateProject();
    const { updateProjectById } = useUpdateProject();
    const { refetchProjects } = useProjects(id);

    async function onSubmit(): Promise<void> {
        try {
            if (project?.id) {
                await updateProjectById(project.id, value);
            }

            if (!project) {
                await createNewProject(value, id);
            }

            refetchProjects();
            setValue(undefined);
            onClose();
        } catch {
            onClose();
        }
    }

    /** Minimum length of project name */
    const MinNameLength = 3;

    return (
        <Modal open={open} headline={headline} onClose={() => onClose()} className='h-fit max-w-xl'>
            <>
                <TextField
                    margin='dense'
                    type='text'
                    fullWidth
                    variant='standard'
                    error={value?.length < MinNameLength}
                    label='Project name'
                    defaultValue={value}
                    onChange={(e) => setValue(e.target.value)}
                    helperText={
                        value?.length < MinNameLength && `Project name should have minimum ${MinNameLength} characters.`
                    }
                />
                <div className='flex flex-row justify-between pt-6'>
                    <button type='button' onClick={() => onClose()}>
                        Cancel
                    </button>
                    <Button label='Confirm' onClick={() => onSubmit()} disabled={value?.length < MinNameLength} />
                </div>
            </>
        </Modal>
    );
}

ProjectModal.defaultProps = {
    project: undefined,
};
