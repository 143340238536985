import { gql, useQuery } from '@apollo/client';

import { Background, FieldFilterDefinition } from '../generated/gql/graphql';

type BackgroundsApi = {
    loading: boolean;
    error: string | undefined;
    backgrounds: Background[];
    refetch: () => void;
};

const GET_BACKGROUNDS = gql`
    query Backgrounds($query: BackgroundQuery) {
        backgrounds(query: $query) {
            id
            name
            tags
            thumbnailUrl
            url
        }
    }
`;

type Props = {
    searchQuery: string;
    filters: FieldFilterDefinition[];
};

export default function useBackgrounds(props: Props): BackgroundsApi {
    const { searchQuery, filters } = props;

    const { loading, error, data, refetch } = useQuery(GET_BACKGROUNDS, {
        fetchPolicy: 'no-cache',
        variables: {
            query: {
                filter: {
                    name: {
                        contains: searchQuery,
                    },
                    fields: filters.map((filter) => ({
                        fieldName: filter.fieldName,
                        anyOf: filter.options.map((option) => option.value),
                    })),
                },
            },
        },
    });

    async function handleRefetch(): Promise<void> {
        await refetch({
            query: {
                filter: {
                    name: {
                        contains: searchQuery,
                    },
                    fields: filters.map((filter) => ({
                        fieldName: filter.fieldName,
                        anyOf: filter.options.map((option) => option.value),
                    })),
                },
            },
        });
    }

    return {
        loading,
        error: error?.message,
        backgrounds: data?.backgrounds || [],
        refetch: handleRefetch,
    };
}
