import { FileUploadOutlined } from '@mui/icons-material';
import React, { ReactElement, useCallback, useRef } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { ProductInput } from '../../../generated/gql/graphql';
import useLocale from '../../../hooks/use-locale';
import useNotification from '../../../hooks/use-notification';
import {
    NewOrExistingRenderingObjectProductInput,
    isExistingRenderingObjectProductInput,
} from '../../../modals/adaption-selection-modal';
import { classNames } from '../../../utils/classes';

type Props = {
    productInputDefinition: ProductInput;
    productInput?: NewOrExistingRenderingObjectProductInput;
    onUpload(file: File): void;
};

export default function LabelListItem(props: Props): ReactElement {
    const { productInputDefinition, onUpload, productInput } = props;

    const fileNameRef = useRef<HTMLParagraphElement>(null);

    const notification = useNotification();

    const { getText } = useLocale();

    const onError = useCallback(() => {
        notification.addError(
            getText('sections.adaption.notification.errors.singleAssetSelect.body'),
            getText('sections.adaption.notification.errors.singleAssetSelect.headline'),
        );
    }, [notification, getText]);

    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            if (fileRejections.length > 0) {
                onError();
            }
            onUpload(acceptedFiles[0]);
        },
        [onError, onUpload],
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/pdf': [],
        },
        maxFiles: 1,
        maxSize: 104_857_600,
        noDrag: true,
        onDrop,
    });

    const productInputFilename = productInput
        ? isExistingRenderingObjectProductInput(productInput)
            ? productInput.fileUrl
            : productInput.file?.name
        : undefined;
    const isTruncated = fileNameRef.current && fileNameRef.current.offsetWidth < fileNameRef.current.scrollWidth;

    return (
        <li className='flex flex-row items-center justify-between space-x-3 bg-white p-3 drop-shadow'>
            <p className={classNames(productInput ? 'text-companyDarkGrey' : 'text-companyMediumGrey')}>
                {productInputDefinition.name}
            </p>
            {productInput && (
                <div className='group flex justify-center truncate'>
                    <p ref={fileNameRef} className='cursor-default truncate text-companyDarkGrey'>
                        {productInputFilename}
                    </p>
                    {isTruncated && (
                        <span
                            className={classNames(
                                'absolute bottom-8 z-50 scale-0 whitespace-nowrap rounded bg-companyMediumGrey p-1 text-xs text-white group-hover:scale-100',
                            )}
                        >
                            {productInputFilename}
                        </span>
                    )}
                </div>
            )}
            <div {...getRootProps({ className: 'dropzone' })} className='cursor-pointer'>
                <input {...getInputProps()} className='hidden' />
                <FileUploadOutlined className='z-10 !fill-companyMediumGrey' />
            </div>
        </li>
    );
}

LabelListItem.defaultProps = {
    file: undefined,
};
