import { useMutation } from '@apollo/client';

import { graphql } from '../generated/gql';
import { LayoutStatus, UpdateLayoutInput, UpdateLayoutMutation } from '../generated/gql/graphql';

import useNotification from './use-notification';
import { ArrayElement } from './utils';

type LayoutApi = {
    renameLayoutById(id: string, name: string): Promise<void>;
    archiveLayoutById(id: string): Promise<void>;
    updateLayoutById(updateLayoutId: string, layout: UpdateLayoutInput): Promise<void>;
};

export type UpdatedLayout = UpdateLayoutMutation['updateLayout'];
export type UpdateRenderingObjectProductInput = ArrayElement<
    ArrayElement<UpdateLayoutInput['renderingObjects']>['productInputs']
>;

const UPDATE_LAYOUT = graphql(`
    mutation UpdateLayout($updateLayoutId: ID!, $data: UpdateLayoutInput!) {
        updateLayout(id: $updateLayoutId, data: $data) {
            name
        }
    }
`);

export default function useUpdateLayout(): LayoutApi {
    const notification = useNotification();
    const [updateLayout] = useMutation(UPDATE_LAYOUT);

    async function renameLayoutById(updateLayoutId: string, name: string): Promise<void> {
        const result = await updateLayout({
            variables: {
                updateLayoutId,
                data: {
                    name,
                },
            },
        });

        if (result.errors && result.errors.length > 0) {
            notification.addError('Something was wrong.');
            throw result.errors;
        }
    }

    async function archiveLayoutById(updateLayoutId: string): Promise<void> {
        const result = await updateLayout({
            variables: {
                updateLayoutId,
                data: {
                    status: LayoutStatus.Archived,
                },
            },
        });

        if (result.errors && result.errors.length > 0) {
            notification.addError('Something was wrong.');
            throw result.errors;
        }
    }

    async function updateLayoutById(updateLayoutId: string, layout: UpdateLayoutInput): Promise<void> {
        const result = await updateLayout({
            variables: {
                updateLayoutId,
                data: {
                    ...layout,
                },
            },
        });

        if (result.errors && result.errors.length > 0) {
            notification.addError('Something was wrong.');
            throw result.errors;
        }
    }

    return {
        renameLayoutById,
        archiveLayoutById,
        updateLayoutById,
    };
}
