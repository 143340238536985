import { useMutation } from '@apollo/client';
import { useContext } from 'react';

import { graphql } from '../generated/gql';
import { RenderLayoutInput } from '../generated/gql/graphql';
import { GeneratorContext } from '../providers/generator-provider';

import useLocale from './use-locale';
import useNotification from './use-notification';

const createRenderingLayout = graphql(`
    mutation RenderLayout($input: RenderLayoutInput!) {
        renderLayout(input: $input) {
            ... on CreateRenderingJobSuccess {
                job {
                    id
                }
            }
            ... on CreateRenderingJobError {
                message
            }
        }
    }
`);

type RenderLayoutApi = {
    isRunning: boolean;
    renderLayout(input: RenderLayoutInput): Promise<void>;
};

export default function useRenderLayout(): RenderLayoutApi {
    const generator = useContext(GeneratorContext);

    const [layoutJobCreationFn] = useMutation(createRenderingLayout);
    const notification = useNotification();
    const { getText } = useLocale();

    async function renderLayout(input: RenderLayoutInput): Promise<void> {
        generator.setIsRunning(true);

        try {
            const result = await layoutJobCreationFn({
                variables: {
                    input,
                },
            });

            if (result.data?.renderLayout.__typename === 'CreateRenderingJobError') {
                throw new Error(result.data.renderLayout.message);
            }

            notification.addSuccess(
                getText('notifications.jobSubmission.successful.body'),
                getText('notifications.jobSubmission.successful.title'),
            );
        } catch (error) {
            notification.addError(
                getText('notifications.jobSubmission.failed.body'),
                getText('notifications.jobSubmission.failed.title'),
            );
            // eslint-disable-next-line no-console
            console.error(error);
        } finally {
            generator.setIsRunning(false);
        }
    }

    return {
        isRunning: generator.isRunning,
        renderLayout,
    };
}
