import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function ReplaceProductIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g>
                <path d='m227 0h-212c-8.284 0-15 6.716-15 15v211c0 8.284 6.716 15 15 15h212c8.284 0 15-6.716 15-15v-211c0-8.284-6.716-15-15-15zm-15 211h-182v-181h182z' />
                <path d='m287 91h60c24.813 0 45 20.187 45 45v45l-18-24c-4.971-6.627-14.373-7.97-21-3-6.627 4.971-7.971 14.373-3 21l45 60c6 7.992 18.029 7.952 24 0l45-60c4.971-6.627 3.627-16.029-3-21-6.626-4.971-16.03-3.626-21 3l-18 24v-45c0-41.355-33.645-75-75-75h-60c-8.284 0-15 6.716-15 15s6.716 15 15 15z' />
                <path d='m392 271c-66.168 0-120 53.832-120 120 0 66.72 53.832 121 120 121s120-54.28 120-121c0-66.168-53.832-120-120-120zm0 211c-49.626 0-90-40.822-90-91 0-49.626 40.374-90 90-90s90 40.374 90 90c0 50.178-40.374 91-90 91z' />
                <path d='m227 421h-60c-24.813 0-45-20.187-45-45v-45l18 24c4.947 6.595 14.341 7.994 21 3 6.627-4.971 7.971-14.373 3-21l-45-60c-.055-.073-.12-.137-.177-.209-.228-.292-.469-.574-.718-.849-6.239-6.898-17.196-6.513-22.929.849-.056.072-.121.136-.176.209l-45 60c-4.971 6.627-3.627 16.029 3 21 6.628 4.972 16.031 3.627 21-3l18-24v45c0 41.355 33.645 75 75 75h60c8.284 0 15-6.716 15-15s-6.716-15-15-15z' />
            </g>
        </svg>
    );
}

ReplaceProductIcon.defaultProps = {
    width: 16,
    height: 16,
    className: '',
};
