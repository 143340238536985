import React, { ReactElement, createContext, useMemo, useState } from 'react';

type GeneratorContextType = {
    setIsRunning(b: boolean): void;
    isRunning: boolean;
};

export const GeneratorContext = createContext<GeneratorContextType>({
    setIsRunning: () => {},
    isRunning: false,
});

type Props = {
    children: ReactElement;
};

export default function GeneratorProvider(props: Props): ReactElement {
    const { children } = props;

    const [isRunning, setIsRunning] = useState(false);

    const values = useMemo(
        () => ({
            setIsRunning,
            isRunning,
        }),
        [isRunning],
    );

    return <GeneratorContext.Provider value={values}>{children}</GeneratorContext.Provider>;
}
