import React, { ReactElement, useState } from 'react';

import AreaDivider from '../components/layout/editor/area-divider';
import CanvasArea from '../components/layout/editor/canvas-area';
import EditorContainer from '../components/layout/editor/editor-container';
import PanelArea from '../components/layout/editor/panel-area';
import CanvasPanel from '../components/panels/canvas-panel';
import { UpdateRenderingObjectInput } from '../generated/gql/graphql';
import { LayoutFull } from '../hooks/use-layout';
import { Product } from '../hooks/use-products';
import useUpdateLayout, { UpdateRenderingObjectProductInput } from '../hooks/use-update-layout';
import { isNewRenderingObjectProductInput } from '../modals/adaption-selection-modal';
import PresentationSelectorModal, { PresentationSelectorModalProps } from '../modals/presentation-selector-modal';
import BackgroundsPanel from '../sections/backgrounds-panel';
import ProductsPanel from '../sections/products-panel';
import { CanvasObject } from '../types/canvas-object';
import { Vector2 } from '../types/vector';

function getCanvasObjects(layout: Pick<LayoutFull, 'renderingObjects'> | undefined | null): CanvasObject[] | undefined {
    return layout?.renderingObjects?.map((object, index) => ({
        key: object.id ? `existing-${object.id}` : `new-${index}`,
        id: object.id,
        presentation: object.presentation,
        presentationId: object.presentationId,
        scale: object.scale || 1,
        offset: object.offset ?? null,
        productInputs: object.productInputs,
        packshotSize: undefined,
    }));
}

export interface EditorProps {
    layout: LayoutFull;
}

export default function Editor({ layout }: EditorProps): ReactElement {
    const { updateLayoutById } = useUpdateLayout();

    const [selectedCanvasObjectIndex, setSelectedCanvasObjectIndex] = useState<number>(-1);
    // eslint-disable-next-line prefer-const
    let [canvasObjects, setCanvasObjects] = useState<CanvasObject[]>(getCanvasObjects(layout) ?? []);
    const [backgroundId, setBackgroundId] = useState<string | undefined>(layout.renderingBackground?.id);
    const [scale, setScale] = useState<number | undefined>(layout.renderingBackground?.scale ?? 1);
    const initialOffset = layout.renderingBackground?.offset;
    const [offset, setOffset] = useState<Vector2 | undefined>(
        initialOffset ? { x: initialOffset.x, y: initialOffset.y } : { x: 0, y: 0 },
    );
    const [presentationSelectorModalProps, setPresentationSelectorModalProps] = useState<
        PresentationSelectorModalProps | undefined
    >();
    const [nextCanvasObjectId, setNextCanvasOjectId] = useState<number>(1);

    const handleBackgroundIdChange = async (newBackgroundId: string | undefined): Promise<void> => {
        setBackgroundId(newBackgroundId);
        await updateLayoutById(layout.id, {
            renderingBackground: newBackgroundId
                ? {
                      id: newBackgroundId,
                      scale,
                      offset,
                  }
                : null,
        });
    };

    const handleBackgroundScaleChange = async (newScale: number | undefined): Promise<void> => {
        setScale(newScale);
    };

    const handleBackgroundScaleChangeEnd = async (newScale: number | undefined): Promise<void> => {
        setScale(newScale);
        await updateLayoutById(layout.id, {
            renderingBackground: backgroundId
                ? {
                      id: backgroundId,
                      offset,
                      scale: newScale,
                  }
                : null,
        });
    };

    const handleBackgroundOffsetChange = async (newOffset: Vector2 | undefined): Promise<void> => {
        setOffset(newOffset);
        await updateLayoutById(layout.id, {
            renderingBackground: backgroundId
                ? {
                      id: backgroundId,
                      scale,
                      offset: newOffset,
                  }
                : null,
        });
    };

    const handleBackgroundScaleOffsetChange = async (
        newScale: number | undefined,
        newOffset: Vector2 | undefined,
    ): Promise<void> => {
        setScale(newScale);
        setOffset(newOffset);
        await updateLayoutById(layout.id, {
            renderingBackground: backgroundId
                ? {
                      id: backgroundId,
                      scale: newScale,
                      offset: newOffset,
                  }
                : null,
        });
    };

    const handleCanvasObjectsChanged = async (
        newCanvasObjects: CanvasObject[],
        saveRequired: boolean = true,
    ): Promise<void> => {
        setCanvasObjects(newCanvasObjects);

        // NOTE: Not nice, but currently required because multiple updates may occur in one frame
        canvasObjects = newCanvasObjects;

        if (saveRequired) {
            // Update on backend
            const renderingObjects: UpdateRenderingObjectInput[] = newCanvasObjects.map((obj) => ({
                id: obj.id,
                presentationId: obj.presentationId,
                offset: obj.offset
                    ? {
                          x: obj.offset.x,
                          y: obj.offset.y,
                      }
                    : undefined,
                scale: obj.scale,
                productInputs: obj.productInputs?.map((productInput) => {
                    if (isNewRenderingObjectProductInput(productInput)) {
                        return { identifier: productInput.identifier, file: productInput.file };
                    } else {
                        return { identifier: productInput.identifier };
                    }
                }),
            }));
            await updateLayoutById(layout.id, {
                renderingObjects,
            });
        }
    };

    const handleCanvasObjectChanged = async (index: number, changes: Partial<CanvasObject>): Promise<void> => {
        // Change canvas object
        const newCanvasObject = { ...canvasObjects[index], ...changes };
        const newCanvasObjects = [...canvasObjects];
        newCanvasObjects[index] = newCanvasObject;
        const saveRequired = !!changes.offset || !!changes.presentationId || !!changes.scale || !!changes.productInputs;
        await handleCanvasObjectsChanged(newCanvasObjects, saveRequired);
    };

    const handleCanvasObjectDuplicate = async (index: number): Promise<void> => {
        if (canvasObjects.length >= 10) {
            alert(
                'Maximum number of products reached. ' +
                    `You have already added ${canvasObjects.length}/10 products. ` +
                    'Please delete a product from the scene to add a new one.',
            );

            return;
        }

        const originalCanvasObject = canvasObjects[index];
        const originalOffset = originalCanvasObject.offset;

        // Add canvas object
        const newCanvasObject: CanvasObject = {
            key: `new-${nextCanvasObjectId}`,
            presentation: originalCanvasObject.presentation,
            presentationId: originalCanvasObject.presentationId,
            offset: {
                x: originalOffset ? originalOffset.x + 50 : 50,
                y: originalOffset ? originalOffset.y + 50 : 50,
            },
            scale: originalCanvasObject.scale,
            packshotSize: originalCanvasObject.packshotSize,
            productInputs: null,
        };
        setNextCanvasOjectId(nextCanvasObjectId + 1);
        const newCanvasObjects = [...canvasObjects];
        newCanvasObjects.push(newCanvasObject);

        await handleCanvasObjectsChanged(newCanvasObjects);

        setSelectedCanvasObjectIndex(newCanvasObjects.length - 1);
    };

    const handleCanvasObjectAdded = (presentationId: string, presentation: CanvasObject['presentation']): void => {
        // Add canvas object
        const newCanvasObject: CanvasObject = {
            key: `new-${nextCanvasObjectId}`,
            presentationId,
            presentation,
            offset: null,
            scale: 1,
            productInputs: null,
            packshotSize: undefined,
        };
        setNextCanvasOjectId(nextCanvasObjectId + 1);
        const newCanvasObjects = [...canvasObjects];
        newCanvasObjects.push(newCanvasObject);
        handleCanvasObjectsChanged(newCanvasObjects);
        setSelectedCanvasObjectIndex(newCanvasObjects.length - 1);
    };

    const handleCanvasObjectDeleted = (index: number): void => {
        const newCanvasObjects = [...canvasObjects];
        newCanvasObjects.splice(index, 1);
        handleCanvasObjectsChanged(newCanvasObjects);
        if (index === selectedCanvasObjectIndex) {
            setSelectedCanvasObjectIndex(-1);
        }
    };

    const handleCanvasObjectMoved = (index: number, newIndex: number): void => {
        const canvasObject = canvasObjects.at(index);
        if (!canvasObject) {
            return;
        }

        if (newIndex < 0 || newIndex >= canvasObjects.length) {
            return;
        }

        const newCanvasObjects = [...canvasObjects];
        newCanvasObjects.splice(index, 1);
        newCanvasObjects.splice(newIndex, 0, canvasObject);
        handleCanvasObjectsChanged(newCanvasObjects);
        if (index === selectedCanvasObjectIndex) {
            setSelectedCanvasObjectIndex(newIndex);
        }
    };

    const handleCanvasObjectSelected = (index: number): void => {
        setSelectedCanvasObjectIndex(index);
    };

    const handleSelectedCanvasObjectChange = (changes: Partial<CanvasObject>): void => {
        handleCanvasObjectChanged(selectedCanvasObjectIndex, changes);
    };

    const handleProductInputsChange = async (
        index: number,
        productInputs: UpdateRenderingObjectProductInput[],
    ): Promise<void> => {
        await handleCanvasObjectChanged(index, { productInputs });
    };

    const handleProductSelected = (product: Product): void => {
        const { perspectives, animations, id } = product;
        const hasPerspectives = perspectives && perspectives.length > 0;
        const hasAnimations = animations && animations.length > 0;

        if (perspectives && perspectives.length === 1 && !hasAnimations) {
            // Product has only one perspective
            const presentation = perspectives[0];
            handleCanvasObjectAdded(presentation.id, presentation);
        } else if (animations && animations.length === 1 && !hasPerspectives) {
            // Product has only one animation
            const presentation = animations[0];
            handleCanvasObjectAdded(presentation.id, presentation);
        } else {
            // Show modal for selection
            setPresentationSelectorModalProps({
                productId: id,
                selectedPresentationId: undefined,
                onClose: () => setPresentationSelectorModalProps(undefined),
                onSelect: (presentation) => {
                    handleCanvasObjectAdded(presentation.id, presentation);
                    setPresentationSelectorModalProps(undefined);
                },
            });
        }
    };

    const selectedCanvasObject =
        selectedCanvasObjectIndex >= 0 ? canvasObjects.at(selectedCanvasObjectIndex) : undefined;

    return (
        <EditorContainer>
            <CanvasArea>
                <CanvasPanel
                    layout={layout}
                    backgroundId={backgroundId}
                    scale={scale}
                    scaleChange={handleBackgroundScaleChange}
                    scaleChangeEnd={handleBackgroundScaleChangeEnd}
                    offset={offset}
                    offsetChange={handleBackgroundOffsetChange}
                    scaleOffsetChange={handleBackgroundScaleOffsetChange}
                    selectedCanvasObject={selectedCanvasObject}
                    selectedCanvasObjectChange={handleSelectedCanvasObjectChange}
                    selectedCanvasObjectIndex={selectedCanvasObjectIndex}
                    canvasObjects={canvasObjects}
                    objectChanged={handleCanvasObjectChanged}
                    objectDuplicated={handleCanvasObjectDuplicate}
                    objectDeleted={handleCanvasObjectDeleted}
                    objectMoved={handleCanvasObjectMoved}
                    objectSelected={handleCanvasObjectSelected}
                    handleProductInputsChange={handleProductInputsChange}
                />
            </CanvasArea>
            <AreaDivider />
            <PanelArea>
                <BackgroundsPanel
                    selectedBackgroundId={backgroundId}
                    selectedBackgroundIdChange={handleBackgroundIdChange}
                />
                <ProductsPanel
                    selectedProductId={selectedCanvasObject?.presentation?.productId ?? undefined}
                    productSelected={handleProductSelected}
                    editorNumberItems={canvasObjects.length}
                />
            </PanelArea>
            {presentationSelectorModalProps && <PresentationSelectorModal {...presentationSelectorModalProps} />}
        </EditorContainer>
    );
}
