import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

export default function EditorContainer(props: Props): ReactElement {
    const { children } = props;
    return <div className='relative flex flex-grow flex-row space-x-8'>{children}</div>;
}
