import markerSDK from '@marker.io/browser';
import React, { ReactElement, useEffect } from 'react';

type Props = {
    children: ReactElement | ReactElement[];
};

export default function MarkerIoProvider(props: Props): ReactElement {
    const { children } = props;

    useEffect(() => {
        markerSDK.loadWidget({
            project: '6461f1fbaa026eafeb02092d',
        });
    }, []);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}
