import React, { ReactElement, useEffect, useState } from 'react';

import { Project } from '../../../hooks/use-project';
import { NonPartial } from '../../../utils/types';
import LayoutsPanel, { LayoutsPanelProps } from '../../panels/layouts-panel';
import LayoutItem, { LayoutItemProps } from '../../project/layout/layout-item';

export type LayoutsSceneProps = {
    project: NonPartial<Pick<Project, 'id' | 'name' | 'workspaceId' | 'readOnly'>> | undefined;
    layouts: LayoutItemProps['layout'][];
} & Omit<LayoutsPanelProps, 'selectAll' | 'selectNone' | 'selectedLayoutIds'>;

export default function LayoutsScene(props: LayoutsSceneProps): ReactElement {
    const { project, layouts, layoutsChange, ...layoutsPanelProps } = props;

    const [selectedLayoutIds, setSelectedLayoutIds] = useState<string[]>([]);

    // Reset selected layouts when project changes
    useEffect(() => {
        setSelectedLayoutIds([]);
    }, [project]);

    const handleSelectAll = (): void => {
        setSelectedLayoutIds(layouts.map((l) => l.id));
    };

    const handleSelectNone = (): void => {
        setSelectedLayoutIds([]);
    };

    const handleSelect = (layoutId: string): void => {
        if (!selectedLayoutIds.includes(layoutId)) {
            setSelectedLayoutIds([...selectedLayoutIds, layoutId]);
        }
    };

    const handleUnselect = (layoutId: string): void => {
        setSelectedLayoutIds(selectedLayoutIds.filter((id) => id !== layoutId));
    };

    return (
        <div className='flex basis-[100%] flex-wrap content-start gap-[20px] rounded-[15px] bg-white p-[50px] shadow'>
            <div className='flex h-fit w-full justify-items-center'>
                <h2 className='text-[24px] text-rose-600'>Project: {project?.name}</h2>
            </div>
            <LayoutsPanel
                {...layoutsPanelProps}
                layouts={layouts}
                layoutsChange={layoutsChange}
                selectAll={handleSelectAll}
                selectNone={handleSelectNone}
                selectedLayoutIds={selectedLayoutIds}
            />
            {layouts.map((layout) => (
                <LayoutItem
                    layout={layout}
                    project={project}
                    layoutsChange={layoutsChange}
                    key={layout.id}
                    selected={selectedLayoutIds.includes(layout.id)}
                    select={() => handleSelect(layout.id)}
                    unselect={() => handleUnselect(layout.id)}
                />
            ))}
        </div>
    );
}
