import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function TrashIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox='-32 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='m303.089844 438.058594c8.285156 0 15-6.734375 15-15.035156v-182.4375c0-8.300782-6.714844-15.03125-15-15.03125s-15 6.730468-15 15.03125v182.4375c0 8.300781 6.714844 15.035156 15 15.035156zm0 0' />
            <path d='m220.75 438.058594c8.285156 0 15-6.734375 15-15.035156v-182.4375c0-8.300782-6.714844-15.03125-15-15.03125-8.28125 0-15 6.730468-15 15.03125v182.4375c0 8.300781 6.71875 15.035156 15 15.035156zm0 0' />
            <path d='m138.410156 438.058594c8.285156 0 15-6.734375 15-15.035156v-182.4375c0-8.300782-6.714844-15.03125-15-15.03125-8.28125 0-15 6.730468-15 15.03125v182.4375c0 8.300781 6.71875 15.035156 15 15.035156zm0 0' />
            <path d='m364.300781 68.75h-27.519531c-1.003906-13.671875-3.429688-25.503906-7.257812-35.300781-8.425782-21.570313-24.191407-33.449219-44.390626-33.449219h-134.386718c-29.453125.195312-48.144532 25.59375-51.488282 68.75h-22.054687c-42.570313 0-77.203125 35.398438-77.203125 78.902344v21.34375c0 8.300781 6.714844 15.03125 15 15.03125h5.054688l30.136718 258.527344c.007813.058593.015625.113281.023438.171874 2.757812 21.527344 8.640625 36.789063 18.511718 48.035157 12.367188 14.09375 30.863282 21.238281 54.96875 21.238281h190.191407c24.894531 0 44.109375-7.320312 57.109375-21.753906 10.613281-11.78125 16.882812-27.613282 19.726562-49.816406.007813-.039063.011719-.082032.015625-.125l30.667969-256.277344h5.097656c8.28125 0 15-6.730469 15-15.03125v-21.339844c0-43.511719-34.636718-78.90625-77.203125-78.90625zm-213.457031-38.683594h134.289062c10.851563 0 14.636719 9.6875 16.457032 14.34375 2.515625 6.4375 4.230468 14.707032 5.085937 24.339844h-177.253906c1.550781-16.230469 6.414063-38.582031 21.421875-38.683594zm-120.84375 117.585938c0-26.925782 21.175781-48.835938 47.203125-48.835938h287.097656c26.027344 0 47.203125 21.910156 47.203125 48.835938v6.308594h-381.503906zm330.960938 289.007812c-4.242188 33-17.007813 45.273438-47.074219 45.273438h-190.191407c-15.316406 0-25.933593-3.609375-32.445312-11.03125-5.722656-6.519532-9.304688-16.664063-11.269531-31.921875l-29.722657-254.953125h340.933594zm0 0' />
        </svg>
    );
}

TrashIcon.defaultProps = {
    width: 16,
    height: 16,
    className: '',
};
