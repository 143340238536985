import { ApolloError, useQuery } from '@apollo/client';

import { graphql } from '../generated/gql/gql';
import { GetLayoutQuery } from '../generated/gql/graphql';

import { ArrayElement } from './utils';

export type Layout = GetLayoutQuery['layout'];

type LayoutApi = {
    loading: boolean;
    error?: ApolloError;
    layout?: LayoutFull;
    refetchLayout: () => Promise<void>;
};

export type LayoutFull = GetLayoutQuery['layout'];

export type LayoutRenderingObject = ArrayElement<LayoutFull['renderingObjects']>;
export type LayoutRenderingObjectProductInput = ArrayElement<LayoutRenderingObject['productInputs']>;

const GET_LAYOUT = graphql(`
    query getLayout($layoutId: ID!) {
        layout(id: $layoutId) {
            id
            name
            status
            createdAt
            renderingProgress
            projectId
            readOnly
            project {
                workspace {
                    id
                }
            }
            previewRenderingJob {
                id
                status
                result {
                    renderingUrl
                }
            }
            latestRenderingJob {
                objects {
                    presentationId
                }
                id
                result {
                    renderingUrl
                }
                status
            }
            renderingBackground {
                id
                background {
                    id
                    name
                    url
                    tags
                }
                scale
                offset {
                    x
                    y
                }
            }
            renderingObjects {
                id
                presentationId
                presentation {
                    id
                    type
                    productId
                    url
                    name
                }
                offset {
                    x
                    y
                }
                scale
                productInputs {
                    identifier
                    fileUrl
                }
            }
        }
    }
`);

export default function useLayout(layoutId: string): LayoutApi {
    const { data, loading, error, refetch } = useQuery<GetLayoutQuery>(GET_LAYOUT, {
        fetchPolicy: 'network-only',
        variables: {
            layoutId,
        },
    });

    return {
        loading,
        error,
        layout: data?.layout,
        refetchLayout: async () => {
            await refetch();
        },
    };
}
