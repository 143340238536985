import React, { ReactElement } from 'react';

import { classNames } from '../../utils/classes';

type Props = {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
    type?: 'primary' | 'secondary';
    outline?: boolean;
};

export default function Button(props: Props): ReactElement {
    const { label, onClick, disabled, className, type, outline } = props;
    return (
        <button
            type='button'
            className={classNames(
                'flex h-8 flex-row items-center space-x-3 rounded-xl pb-1 pl-3 pr-3 pt-1 hover:outline-2',
                disabled
                    ? 'pointer-events-none cursor-default outline outline-companyGrey [&>svg]:fill-companyGrey'
                    : 'cursor-pointer ',
                type === 'primary'
                    ? 'outline-company [&>svg]:fill-company'
                    : 'outline-companyDarkGrey [&>svg]:fill-companyDarkGrey',
                outline ? 'outline outline-1' : 'outline-0',
                className || '',
            )}
            onClick={onClick}
            disabled={disabled}
        >
            <p
                className={classNames(
                    'text-xs',
                    disabled ? 'text-companyGrey' : 'text-company',
                    type === 'primary' ? 'text-company' : 'text-companyDarkGrey',
                )}
            >
                {label}
            </p>
        </button>
    );
}

Button.defaultProps = {
    disabled: false,
    className: '',
    type: 'primary',
    outline: true,
};
