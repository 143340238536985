import React, { ReactElement } from 'react';

import useLocale from '../../hooks/use-locale';
import ErrorIcon from '../icons/outlines/error';

type Props = {
    error: string;
};

export default function RetryError(props: Props): ReactElement {
    const { error } = props;

    const { getText } = useLocale();

    return (
        <div className='flex h-full w-full items-center justify-center'>
            <div className='flex flex-col justify-center space-y-2'>
                <ErrorIcon className='mx-auto' />
                <p>{error}</p>
                <button
                    type='button'
                    className='mt-2 rounded-full border border-company px-3 py-2 text-company hover:bg-company hover:bg-opacity-5'
                    onClick={() => window.location.reload()}
                >
                    {getText('components.assets.error.action')}
                </button>
            </div>
        </div>
    );
}
