import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function SearchIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' width={width} height={height}>
            <path d='M12.755 11.255H11.965L11.685 10.985C12.665 9.845 13.255 8.365 13.255 6.755C13.255 3.165 10.345 0.255005 6.755 0.255005C3.165 0.255005 0.255005 3.165 0.255005 6.755C0.255005 10.345 3.165 13.255 6.755 13.255C8.365 13.255 9.845 12.665 10.985 11.685L11.255 11.965V12.755L16.255 17.745L17.745 16.255L12.755 11.255ZM6.755 11.255C4.26501 11.255 2.255 9.245 2.255 6.755C2.255 4.26501 4.26501 2.255 6.755 2.255C9.245 2.255 11.255 4.26501 11.255 6.755C11.255 9.245 9.245 11.255 6.755 11.255Z' />
        </svg>
    );
}

SearchIcon.defaultProps = {
    width: 24,
    height: 24,
    className: '',
};
