import React, { ReactElement, createContext, useMemo, useState } from 'react';

import { Layout, LayoutStatus, PackshotOffsetInput, Project } from '../generated/gql/graphql';
import { FilterLayout } from '../types/filter-layout';
import { Size } from '../types/size';

type ProjectSceneContextType = {
    selection: {
        backgroundGrid: number;
        setBackgroundGrid(value: number): void;

        backgroundOverlayColor: string;
        setBackgroundOverlayColor(value: string): void;

        projects: Project[] | undefined;
        setProjects(p?: Project[]): void;

        aspectRatio: number;
        setAspectRatio(a: number): void;

        disableShadowsAndReflections: boolean | undefined;
        setDisableShadowsAndReflections(disableShadowsAndReflections: boolean): void;
    };
};

export const ProjectSceneContext = createContext<ProjectSceneContextType>({
    selection: {
        backgroundOverlayColor: '#000000',
        setBackgroundOverlayColor: () => {},

        backgroundGrid: 1,
        setBackgroundGrid: () => {},

        projects: undefined,
        setProjects: () => {},

        aspectRatio: 1 / 1,
        setAspectRatio: () => {},

        disableShadowsAndReflections: undefined,
        setDisableShadowsAndReflections: () => {},
    },
});

type Props = {
    children: ReactElement | ReactElement[];
};

export default function ProjectSceneProvider(props: Props): ReactElement {
    const { children } = props;

    const [backgroundOverlayColor, setBackgroundOverlayColor] = useState<string>('#000000');
    const [projects, setProjects] = useState<Project[]>();
    const [layouts, setLayouts] = useState<Layout[]>();
    const [filter, setFilter] = useState<FilterLayout>({ status: LayoutStatus.Active, generation: '', name: '' });
    const [offset, setOffset] = useState<PackshotOffsetInput>({ x: 0, y: 0 });
    const [scale, setScale] = useState<number>(1);
    const [aspectRatio, setAspectRatio] = useState<number>(1 / 1);
    const [backgroundGrid, setBackgroundGrid] = useState<number>(1);
    const [packshotSize, setPackshotSize] = useState<Size | undefined>(undefined);
    const [disableShadowsAndReflections, setDisableShadowsAndReflections] = useState<boolean | undefined>(true);

    const value = useMemo(
        () => ({
            selection: {
                backgroundOverlayColor,
                setBackgroundOverlayColor,

                backgroundGrid,
                setBackgroundGrid,

                projects,
                setProjects,

                layouts,
                setLayouts,

                filter,
                setFilter,

                offset,
                setOffset,

                scale,
                setScale,

                aspectRatio,
                setAspectRatio,

                packshotSize,
                setPackshotSize,

                disableShadowsAndReflections,
                setDisableShadowsAndReflections,
            },
        }),
        [
            backgroundOverlayColor,
            backgroundGrid,
            projects,
            layouts,
            filter,
            offset,
            scale,
            aspectRatio,
            packshotSize,
            disableShadowsAndReflections,
        ],
    );

    return <ProjectSceneContext.Provider value={value}>{children}</ProjectSceneContext.Provider>;
}
