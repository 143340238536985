import React, { ReactElement, ReactNode } from 'react';

import useProjectScene from '../../hooks/use-project-scene';
import { Size } from '../../types/size';

type Props = {
    children: ReactNode;
    aspectRatio: number;
    canvasSize: Size;
};

export default function BackgroundRatioOverlay(props: Props): ReactElement {
    const { aspectRatio, children, canvasSize } = props;

    const { selection } = useProjectScene();

    return (
        <div className='absolute left-0 top-0 z-10 h-full w-full'>
            {canvasSize.width / aspectRatio < canvasSize.height && (
                <div
                    style={{
                        height: `${(canvasSize.width - canvasSize.width / aspectRatio) / 2}px`,
                        background: selection.backgroundColor,
                    }}
                    className='absolute left-0 top-0 z-10 w-full opacity-50'
                />
            )}
            {canvasSize.width / aspectRatio > canvasSize.width && (
                <div
                    style={{
                        width: `${(canvasSize.height - canvasSize.height * aspectRatio) / 2}px`,
                        background: selection.backgroundColor,
                    }}
                    className='absolute left-0 top-0 z-10 h-full opacity-50'
                />
            )}
            {canvasSize.width / aspectRatio < canvasSize.height && (
                <div
                    style={{
                        height: `${(canvasSize.width - canvasSize.width / aspectRatio) / 2}px`,
                        background: selection.backgroundColor,
                    }}
                    className='absolute bottom-0 left-0 z-10 w-full opacity-50'
                />
            )}
            {canvasSize.width / aspectRatio > canvasSize.width && (
                <div
                    style={{
                        width: `${(canvasSize.height - canvasSize.height * aspectRatio) / 2}px`,
                        background: selection.backgroundColor,
                    }}
                    className='absolute right-0 top-0 z-10 h-full opacity-50'
                />
            )}
            {children}
        </div>
    );
}
