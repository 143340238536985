import BorderClearIcon from '@mui/icons-material/BorderClear';
import FullscreenSharpIcon from '@mui/icons-material/FullscreenSharp';
import React, { ReactElement, useState } from 'react';

import useLocale from '../../hooks/use-locale';
import { CanvasObject } from '../../types/canvas-object';
import { Size } from '../../types/size';
import { Vector2 } from '../../types/vector';
import { classNames } from '../../utils/classes';
import SideNavigation from '../button/side-navigation';
import BackgroundAspectRatioActions from '../composer/background-aspect-ratio-actions';
import BackgroundElement from '../composer/background-element';
import BackgroundGridActions from '../composer/background-grid-actions';
import BackgroundZoom from '../composer/background-zoom';
import PackshotElement from '../composer/packshot-element';

// Default Canvas Size
export const canvasSize: Size = {
    width: 600,
    height: 600,
};

export type LayoutEditorProps = {
    /** Background id */
    backgroundId: string | undefined;

    /** Background scale */
    scale: number;
    scaleChange: (value: number) => void;
    scaleChangeEnd: (value: number) => void;

    /** Background offset */
    offset: Vector2;
    offsetChange: (offset: Vector2) => void;

    scaleOffsetChange: (scale: number, offset: Vector2) => void;

    /** Objects */
    canvasObjects: CanvasObject[];
    selectedCanvasObjectIndex: number;
    objectChanged: (index: number, changes: Partial<CanvasObject>) => void;
    objectDeleted: (index: number) => void;
    objectDuplicated: (index: number) => void;
    objectMoved: (index: number, newIndex: number) => void;
    objectSelected: (index: number) => void;

    /** Indicates if layout can be edited */
    locked: boolean;
};

export default function LayoutEditor(props: LayoutEditorProps): ReactElement {
    const {
        backgroundId,
        scale,
        scaleChange,
        scaleChangeEnd,
        offset,
        offsetChange,
        scaleOffsetChange,
        canvasObjects,
        selectedCanvasObjectIndex,
        objectChanged,
        objectDuplicated,
        objectDeleted,
        objectMoved,
        objectSelected,
        locked,
    } = props;

    const { getText } = useLocale();
    const [leftSideNavigationExpanded, setLeftSideNavigationExpanded] = useState<boolean>(false);
    const [rightSideNavigationExpanded, setRightSideNavigationExpanded] = useState<boolean>(false);

    return (
        <div
            className={classNames(
                'relative mb-6 ml-5 mr-5 flex',
                'items-center justify-center',
                `${backgroundId ? 'rounded-none' : 'rounded-2xl bg-companyLightGrey'}`,
            )}
            style={{
                width: `${canvasSize.width}px`,
                height: `${canvasSize.height}px`,
            }}
        >
            <SideNavigation
                expanded={leftSideNavigationExpanded}
                setExpanded={setLeftSideNavigationExpanded}
                icon={<BorderClearIcon />}
                position='left-[-78px]'
                disabled={!backgroundId}
            >
                <BackgroundGridActions />
                <BackgroundAspectRatioActions />
            </SideNavigation>
            <SideNavigation
                expanded={rightSideNavigationExpanded}
                setExpanded={setRightSideNavigationExpanded}
                icon={<FullscreenSharpIcon />}
                position='right-[-78px]'
                disabled={!backgroundId}
            >
                <BackgroundZoom
                    scale={scale}
                    scaleChange={scaleChange}
                    scaleChangeEnd={scaleChangeEnd}
                    scaleOffsetChange={scaleOffsetChange}
                />
            </SideNavigation>
            {backgroundId ? (
                <div className='relative h-full w-full overflow-hidden bg-companyMediumGrey'>
                    {backgroundId && (
                        <BackgroundElement
                            key={backgroundId}
                            backgroundId={backgroundId}
                            isDraggable={rightSideNavigationExpanded}
                            scale={scale}
                            offset={offset}
                            offsetChange={offsetChange}
                            canvasSize={canvasSize}
                            objectSelected={objectSelected}
                        />
                    )}
                    {canvasObjects.map((object, index) => (
                        <PackshotElement
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            packshot={object}
                            index={index}
                            objectChanged={(changes) => objectChanged(index, changes)}
                            objectDeleted={() => objectDeleted(index)}
                            objectDuplicated={() => objectDuplicated(index)}
                            objectMovedDown={() => objectMoved(index, index - 1)}
                            objectMovedUp={() => objectMoved(index, index + 1)}
                            objectSelected={() => objectSelected(index)}
                            isGenerated={locked}
                            selected={index === selectedCanvasObjectIndex}
                            canvasSize={canvasSize}
                        />
                    ))}
                </div>
            ) : (
                <p className='m-auto px-5 text-center text-xl text-companyMediumGrey'>
                    {getText('sections.canvas.placeholder')}
                </p>
            )}
        </div>
    );
}
