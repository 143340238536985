import { TextField } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';

import Button from '../components/button/button';
import Modal from '../components/modal/modal';
import ProjectSelector from '../components/selectors/project-selector';
import WorkspaceSelector from '../components/selectors/workspace-selector';
import { CreateLayoutInput, Workspace } from '../generated/gql/graphql';
import useWorkspace from '../hooks/use-workspace';

export type CreateLayoutModalProps = {
    open: boolean;
    initialProjectId?: string;
    initialWorkspaceId?: string;

    submit: (input: CreateLayoutInput) => void | Promise<void>;
    onClose(): void;
};

export default function CreateLayoutModal(props: CreateLayoutModalProps): ReactElement {
    const { open, initialProjectId, initialWorkspaceId, submit, onClose } = props;

    const { workspaces } = useWorkspace();

    const writebleWorkspaces = workspaces?.filter((ws) => !ws.readOnly);

    const [name, setName] = useState<string>('');
    const [projects, setProjects] = useState<{ id: string; name: string | null }[] | undefined>();
    const [workspaceId, setWorkspaceId] = useState<string>(initialWorkspaceId ?? '');
    const [projectId, setProjectId] = useState<string>(initialProjectId ?? '');

    useEffect(() => {
        setProjectId(initialProjectId ?? '');
        setWorkspaceId(initialWorkspaceId ?? '');
    }, [initialProjectId, initialWorkspaceId]);

    useEffect(() => {
        const currentWorkspace = workspaces?.find((ws) => ws.id === workspaceId);

        setProjects(currentWorkspace?.projects ?? undefined);
    }, [workspaceId, workspaces]);

    async function onSubmit(): Promise<void> {
        await submit({ projectId, name });
        onClose();
    }

    // Validate inputs
    const nameTooShort = name.length < 3;
    const noProjectSelected = !projectId;
    const submitDisabled = nameTooShort || noProjectSelected;

    return (
        <Modal open={open} headline='New Layout' onClose={() => onClose()} className='h-fit max-w-xl'>
            <>
                <TextField
                    className='mb-[20px]'
                    margin='dense'
                    type='text'
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    fullWidth
                    focused
                    variant='standard'
                    error={nameTooShort}
                    label='Layout name'
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)}
                    helperText={nameTooShort && 'Please give your layout a name with at least 3 characters.'}
                />
                <WorkspaceSelector
                    workspaceId={workspaceId}
                    workspaces={writebleWorkspaces as Workspace[]}
                    title='Create in Workspace'
                    onChange={(e) => {
                        setWorkspaceId(e.target.value as string);
                        setProjectId('');
                    }}
                />
                {projects && (
                    <ProjectSelector
                        title='Create in Project'
                        errorMessage='Please select a project to add the new layout to'
                        initialProjectId={projectId ?? ''}
                        projects={projects}
                        onChange={(e) => setProjectId(e.target.value as string)}
                    />
                )}
                <div className='flex flex-row justify-between pt-6'>
                    <button type='button' onClick={() => onClose()}>
                        Cancel
                    </button>
                    <Button label='Confirm' onClick={() => onSubmit()} disabled={submitDisabled} />
                </div>
            </>
        </Modal>
    );
}

CreateLayoutModal.defaultProps = {
    initialProjectId: undefined,
    initialWorkspaceId: undefined,
};
