import React, { ReactElement } from 'react';

import { classNames } from '../../../utils/classes';

type Props = {
    children: ReactElement | ReactElement[];
    classNames?: string;
};

export default function PanelFooter(props: Props): ReactElement {
    const { children } = props;

    return <div className={classNames(props.classNames || '')}>{children}</div>;
}

PanelFooter.defaultProps = {
    classNames: '',
};
