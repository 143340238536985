import { ApolloClient, ApolloProvider, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { useKeycloak } from '@react-keycloak/web';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { createClient } from 'graphql-ws';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { getEnv } from '../utils/environment-variables';

const cache = new InMemoryCache();

let GraphQlClient = new ApolloClient({ cache });

type Props = {
    children: ReactNode;
};

export default function GraphqlProvider(props: Props): ReactElement {
    const { children } = props;
    const { keycloak } = useKeycloak();
    const [graphQlInitialized, setGraphQlInitialized] = useState(false);

    useEffect(() => {
        if (keycloak.authenticated) {
            const httpLink = createUploadLink({
                uri: getEnv('REACT_APP_METACOMP_BACKEND_URL'),
            });

            const authLink = setContext((_, { headers }) => ({
                headers: {
                    ...headers,
                    authorization: `Bearer ${keycloak.token}`,
                    'Apollo-Require-Preflight': 'true',
                },
            }));

            const wsLink = new GraphQLWsLink(
                createClient({
                    url: getEnv('REACT_APP_METACOMP_SUBSCRIPTION_BACKEND_URL'),
                    connectionParams: async () => ({ Authorization: `Bearer ${keycloak.token}` }),
                }),
            );

            const splitLink = split(
                ({ query }) => {
                    const definition = getMainDefinition(query);
                    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
                },
                wsLink,
                // @ts-ignore
                authLink.concat(httpLink),
            );

            GraphQlClient = new ApolloClient({
                cache: new InMemoryCache(),
                link: splitLink,
            });

            setGraphQlInitialized(true);
        }
    }, [keycloak, GraphQlClient]);

    if (keycloak.authenticated && graphQlInitialized) {
        return <ApolloProvider client={GraphQlClient}>{children}</ApolloProvider>;
    }

    return <div />;
}
