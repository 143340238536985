import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function ArchivedIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            className={className}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
            width={width}
            height={height}
        >
            <g xmlns='http://www.w3.org/2000/svg'>
                <path d='m507.53 129.124-126.611-124.806c-2.807-2.767-6.589-4.318-10.53-4.318h-295.557c-8.284 0-15 6.716-15 15v114.959c-34.333 8.63-59.832 39.751-59.832 76.724v157.483c0 36.973 25.499 68.094 59.832 76.725v56.109c0 8.284 6.716 15 15 15h422.168c8.284 0 15-6.716 15-15v-357.194c0-4.016-1.61-7.863-4.47-10.682zm-122.141-78.275 75.027 73.957h-60.037c-8.266 0-14.99-6.729-14.99-15zm-355.389 313.318v-157.484c0-27.08 22.031-49.111 49.111-49.111h157.395c27.08 0 49.111 22.031 49.111 49.111v157.483c0 27.08-22.031 49.111-49.111 49.111h-157.395c-27.08.001-49.111-22.03-49.111-49.11zm59.832 117.833v-38.722h146.674c43.622 0 79.111-35.489 79.111-79.111v-157.484c0-43.622-35.489-79.111-79.111-79.111h-146.674v-97.572h265.557v79.806c0 24.813 20.183 45 44.99 45h81.621v327.194z' />
                <path d='m147.202 368.884c5.585 5.811 15.628 5.81 21.213 0l46.233-46.233c5.858-5.858 5.858-15.355 0-21.213-5.856-5.858-15.354-5.858-21.213 0l-20.627 20.627v-108.937c-.793-19.902-29.215-19.887-30 0v108.937l-20.626-20.626c-5.856-5.858-15.354-5.858-21.213 0-5.858 5.858-5.858 15.355 0 21.213z' />
            </g>
        </svg>
    );
}

ArchivedIcon.defaultProps = {
    width: 24,
    height: 24,
    className: '',
};
