import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { ReactElement, useState } from 'react';

import {
    CreateRenderingJobSetting,
    Layout,
    LayoutRenderingProgress,
    RenderLayoutInput,
} from '../../generated/gql/graphql';
import useRenderLayouts from '../../hooks/use-render-layouts';
import useRenderingSettings from '../../hooks/use-rendering-settings';
import useUpdateLayout from '../../hooks/use-update-layout';
import GeneratorModal from '../../modals/generator-modal';
import { NonPartial } from '../../utils/types';

export type LayoutActionsSelectorProps = {
    selectedLayouts: NonPartial<Pick<Layout, 'id' | 'renderingProgress' | 'readOnly'>>[];
    layoutsChange: () => void | Promise<void>;
};

export default function LayoutActionsSelector({
    selectedLayouts,
    layoutsChange,
}: LayoutActionsSelectorProps): ReactElement {
    const [action, setAction] = useState<boolean>(false);
    const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
    const { settings } = useRenderingSettings();

    const { archiveLayoutById } = useUpdateLayout();

    const handleArchiveLayouts = async (): Promise<void> => {
        await Promise.all(
            selectedLayouts.map(async (layout) => {
                await archiveLayoutById(layout.id);
            }),
        );

        await layoutsChange();

        setAction(!action);
    };

    const handleGenerateLayouts = async (): Promise<void> => {
        // Open GeneratorModal for selected layouts
        setIsGenerateModalOpen(true);

        setAction(!action);
    };

    const { renderLayouts } = useRenderLayouts();
    const handleGenerateSubmit = async (
        renderingSettings: CreateRenderingJobSetting[],
        disableShadowsAndReflections: boolean | undefined,
    ): Promise<void> => {
        setIsGenerateModalOpen(false);
        const items: RenderLayoutInput[] = selectedLayouts.map((layout) => ({ layoutId: layout.id }));
        await renderLayouts({
            items,
            settings: renderingSettings,
            disableShadowsAndReflections,
        });
        await layoutsChange();
    };
    const handleGenerateCancel = (): void => {
        setIsGenerateModalOpen(false);
    };

    const menuItems = [];

    // Generate
    const generateDisabled =
        selectedLayouts.length === 0 ||
        selectedLayouts.some(
            (layout) =>
                layout.readOnly ||
                (layout.renderingProgress &&
                    [
                        LayoutRenderingProgress.Generated,
                        LayoutRenderingProgress.InProgress,
                        LayoutRenderingProgress.PreviewInProgress,
                    ].includes(layout.renderingProgress)),
        );
    menuItems.push(
        <MenuItem key='generate' onClick={handleGenerateLayouts} disabled={generateDisabled}>
            Generate selected
        </MenuItem>,
    );

    const archiveDisabled = selectedLayouts.length === 0 || selectedLayouts.some((layout) => layout.readOnly);
    menuItems.push(
        <MenuItem key='archive' onClick={handleArchiveLayouts} disabled={archiveDisabled}>
            Archive selected
        </MenuItem>,
    );

    return (
        <>
            <FormControl style={{ minWidth: 150 }} size='small'>
                <InputLabel className='text-[14px]'>Actions</InputLabel>
                <Select
                    className='mr-[15px] rounded-[15px] border-0 text-left text-[14px]'
                    label='Action'
                    open={action}
                    value=''
                    onClick={() => setAction(!action)}
                >
                    {menuItems}
                </Select>
            </FormControl>
            {!!settings?.length && (
                <GeneratorModal
                    open={isGenerateModalOpen}
                    settings={settings}
                    submit={handleGenerateSubmit}
                    cancel={() => handleGenerateCancel()}
                />
            )}
        </>
    );
}
