import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CreateRenderingJobSetting, LayoutRenderingProgress, LayoutStatus } from '../../generated/gql/graphql';
import { Layout } from '../../hooks/use-layout';
import usePreviewLayout from '../../hooks/use-preview-layout';
import useProjectScene from '../../hooks/use-project-scene';
import useRenderLayout from '../../hooks/use-render-layout';
import useRenderingSettings from '../../hooks/use-rendering-settings';
import useUser from '../../hooks/use-user';
import { NewOrExistingRenderingObjectProductInput } from '../../modals/adaption-selection-modal';
import GeneratorModal from '../../modals/generator-modal';
import { CanvasObject } from '../../types/canvas-object';
import Routes from '../../types/enums/routes';
import { Vector2 } from '../../types/vector';
import AdaptLabelButton, { AdaptLabelProps } from '../button/adapt-label-button';
import GeneratePreviewButton from '../button/generate-preview-button';
import GeneratorButton from '../button/generator-button';

import LayoutEditor, { LayoutEditorProps } from './layout-editor';

export type CanvasPanelContentProps = {
    layout: Pick<Layout, 'id' | 'projectId' | 'renderingProgress' | 'status' | 'readOnly'>;
    scale: number | undefined;
    scaleChange: (value: number | undefined) => void;
    scaleChangeEnd: (value: number | undefined) => void;
    offset: Vector2 | undefined;
    offsetChange: (value: Vector2 | undefined) => void;
    scaleOffsetChange: (scale: number | undefined, offset: Vector2 | undefined) => void;
    selectedCanvasObject: AdaptLabelProps['canvasObject'];
    selectedCanvasObjectIndex: number;
    canvasObjects: CanvasObject[];
    handleProductInputsChange: (
        canvasObjectIndex: number,
        productInputs: NewOrExistingRenderingObjectProductInput[],
    ) => Promise<void>;
} & Pick<
    LayoutEditorProps,
    'objectChanged' | 'objectDeleted' | 'objectMoved' | 'objectSelected' | 'objectDuplicated' | 'backgroundId'
>;

export default function CanvasPanelContent({
    layout,
    backgroundId,
    scale,
    scaleChange,
    scaleChangeEnd,
    offset,
    offsetChange,
    scaleOffsetChange,
    selectedCanvasObject,
    selectedCanvasObjectIndex,
    canvasObjects,
    objectChanged,
    objectDeleted,
    objectDuplicated,
    objectMoved,
    objectSelected,
    handleProductInputsChange,
}: CanvasPanelContentProps): ReactElement {
    const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);

    const { settings } = useRenderingSettings();
    const { disableShadowsAndReflections } = useProjectScene().selection;
    const { user } = useUser();
    const { renderLayout, isRunning } = useRenderLayout();
    const { renderPreviewLayoutById } = usePreviewLayout();

    const navigate = useNavigate();
    const currentLocation = window.location.pathname;

    const handleGenerate = async (renderingSettings: CreateRenderingJobSetting[]): Promise<void> => {
        await renderLayout({
            layoutId: layout.id,
            settings: renderingSettings,
            disableShadowsAndReflections,
        });

        if (currentLocation === `${Routes.EDITOR}/${layout.id}`) {
            // eslint-disable-next-line no-alert
            alert(
                'Your rendering job will now be processed, ' +
                    'further editing is now locked. You will be redirected to the project.',
            );
            navigate(`${Routes.PROJECT}/${layout.projectId}`);
        }

        setIsGenerateModalOpen(false);
    };

    const handleGeneratePreview = async (): Promise<void> => {
        await renderPreviewLayoutById(layout.id);

        if (currentLocation === `${Routes.EDITOR}/${layout.id}`) {
            // eslint-disable-next-line no-alert
            alert(
                'Your rendering job will now be processed, ' +
                    'further editing is now locked. You will be redirected to the project.',
            );
            navigate(`${Routes.PROJECT}/${layout.projectId}`);
        }
    };

    function handleCancelGenerate(): void {
        setIsGenerateModalOpen(false);
    }

    const { renderingProgress, status, readOnly } = layout;
    const generateButtonDisabled = isRunning || !backgroundId || !canvasObjects[0];
    const isGeneratePreviewButton =
        renderingProgress !== LayoutRenderingProgress.Generated && status !== LayoutStatus.Archived && !readOnly;

    return (
        <div className='flex h-full w-full flex-col items-center justify-between p-5 pl-[80px] pr-[80px]'>
            {scale && offset && (
                <LayoutEditor
                    backgroundId={backgroundId}
                    scale={scale}
                    scaleChange={scaleChange}
                    scaleChangeEnd={scaleChangeEnd}
                    offset={offset}
                    offsetChange={offsetChange}
                    scaleOffsetChange={scaleOffsetChange}
                    canvasObjects={canvasObjects}
                    selectedCanvasObjectIndex={selectedCanvasObjectIndex}
                    objectChanged={objectChanged}
                    objectDuplicated={objectDuplicated}
                    objectDeleted={objectDeleted}
                    objectMoved={objectMoved}
                    objectSelected={objectSelected}
                    locked={layout.renderingProgress === LayoutRenderingProgress.Generated}
                />
            )}
            <div className='flex w-full flex-row justify-end space-x-3 px-5'>
                {user?.activeClient?.canAdaptLabel && selectedCanvasObject && (
                    <AdaptLabelButton
                        canvasObject={selectedCanvasObject}
                        productInputsChange={async (productFiles) =>
                            handleProductInputsChange(selectedCanvasObjectIndex, productFiles)
                        }
                    />
                )}
                {isGeneratePreviewButton && <GeneratePreviewButton layout={layout} submit={handleGeneratePreview} />}
                <GeneratorButton
                    disabled={generateButtonDisabled}
                    loading={isRunning}
                    submit={() => {
                        if (settings?.length) {
                            setIsGenerateModalOpen(true);
                        } else {
                            handleGenerate([]);
                        }
                    }}
                />
            </div>
            {!!settings?.length && (
                <GeneratorModal
                    open={isGenerateModalOpen}
                    settings={settings}
                    submit={(renderingSettings) => handleGenerate(renderingSettings)}
                    cancel={() => handleCancelGenerate()}
                />
            )}
        </div>
    );
}
