import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { ReactElement } from 'react';

type Props = {
    projects: {
        id: string;
        name: string | null;
    }[];
    initialProjectId: string;
    title: string;
    errorMessage: string;

    onChange: (event: SelectChangeEvent) => void;
};

export default function ProjectSelector(props: Props): ReactElement {
    const { projects, title, initialProjectId, errorMessage, onChange } = props;

    return (
        <Box>
            <FormControl className='min-w-[220px]' size='small' error={!initialProjectId}>
                <InputLabel>{title}</InputLabel>
                <Select
                    className='rounded-[15px] border-0 text-left text-[14px]'
                    value={initialProjectId}
                    label={title}
                    onChange={onChange}
                    error={!initialProjectId}
                >
                    {projects.map((p) => (
                        <MenuItem key={p.id} value={p.id}>
                            {p.name}
                        </MenuItem>
                    ))}
                </Select>
                {!initialProjectId && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControl>
        </Box>
    );
}
