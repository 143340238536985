import { useMutation } from '@apollo/client';

import { graphql } from '../generated/gql';

import useNotification from './use-notification';

type ProjectApi = {
    duplicateLayoutById(layoutId: string, projectId?: string, name?: string): Promise<void>;
};

const DUPLICATE_LAYOUT = graphql(`
    mutation DuplicateLayout($input: DuplicateLayoutInput!) {
        duplicateLayout(input: $input) {
            name
            id
            createdAt
            projectId
            status
            thumbnailUrl
            renderingBackground {
                id
                offset {
                    y
                    x
                }
                scale
                background {
                    id
                    name
                    tags
                    url
                    thumbnailUrl
                }
            }
            renderingObjects {
                offset {
                    x
                    y
                }
                presentationId
                scale
            }
            renderingProgress
        }
    }
`);

export default function useDuplicateLayout(): ProjectApi {
    const notification = useNotification();
    const [duplicateLayout] = useMutation(DUPLICATE_LAYOUT);

    async function duplicateLayoutById(layoutId: string, projectId: string, name: string): Promise<void> {
        const result = await duplicateLayout({
            variables: {
                input: {
                    layoutId,
                    projectId,
                    name,
                },
            },
        });

        if (result.errors && result.errors.length > 0) {
            notification.addError('Something was wrong.');
            throw result.errors;
        }
    }

    return {
        duplicateLayoutById,
    };
}
