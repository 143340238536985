import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { CreateLayoutInput, Layout } from '../../generated/gql/graphql';
import useCreateLayout from '../../hooks/use-create-layout';
import CreateLayoutModal from '../../modals/create-layout-modal';
import Routes from '../../types/enums/routes';
import { classNames } from '../../utils/classes';
import { NonPartial } from '../../utils/types';
import ImageIcon from '../icons/outlines/image';

type MainSidebarProps = {
    layout?: NonPartial<Pick<Layout, 'id' | 'projectId'>>;
    workspaceId?: string | undefined;
    layoutsChange?: () => void;
};

export default function MainSidebar({ layout, layoutsChange, workspaceId }: MainSidebarProps): ReactElement {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const navigate = useNavigate();
    const currentLocation = window.location.pathname;
    const projectId = currentLocation === `${Routes.PROJECT}/${id}` ? id : undefined;

    const routeItems = [
        {
            icon: <WorkspacesIcon />,
            route: Routes.WORKSPACES,
            label: 'Workspaces',
        },
    ];

    function handleToggleModal(): void {
        setIsCreateModalOpen(!isCreateModalOpen);
    }

    function handleClick(route: string): void {
        if (route !== Routes.EDITOR) {
            navigate(`${route}?${searchParams}`);
        }
    }

    const { createNewLayout } = useCreateLayout();
    const handleCreateNewLayout = async (input: CreateLayoutInput): Promise<void> => {
        const newLayout = await createNewLayout(input);
        if (newLayout) {
            layoutsChange?.();
            navigate(`${Routes.EDITOR}/${newLayout.id}`);
        }
    };

    return (
        <>
            <Drawer
                variant='permanent'
                anchor='left'
                open={isOpen}
                PaperProps={{
                    className: `fixed transition-all overflow-hidden shadow border ${
                        isOpen ? 'w-[120px]' : 'w-[50px]'
                    }`,
                }}
                className={`sticky z-50 overflow-hidden transition-all ${isOpen ? 'w-[120px]' : 'w-[50px]'}`}
            >
                <List>
                    <ListItem disablePadding className='border-b'>
                        <ListItemButton onClick={() => setOpen(!isOpen)} className='min-h-[75px] justify-center'>
                            {isOpen ? <ChevronLeftOutlined /> : <ChevronRightOutlined />}
                        </ListItemButton>
                    </ListItem>
                    {routeItems.map((element) => (
                        <ListItem key={element.route} disablePadding className='justify-center border-b'>
                            <ListItemButton
                                onClick={() => handleClick(element.route)}
                                className={`relative min-h-[75px] flex-col items-center justify-center transition-all ${
                                    isOpen ? 'w-100' : 'w-0'
                                }`}
                            >
                                <ListItemIcon
                                    className={`justify-center ${
                                        currentLocation === element.route
                                            ? 'fill-company [&>svg]:fill-company'
                                            : 'fill-companyDarkGrey [&>svg]:fill-companyDarkGrey'
                                    }`}
                                >
                                    {element.icon}
                                </ListItemIcon>
                                <ListItemText
                                    className={classNames(
                                        `flex-none overflow-hidden text-center`,
                                        `text-lg transition-all [&>span]:text-[14px]`,
                                        isOpen ? 'max-h-32 opacity-100' : 'max-h-0 opacity-0',
                                        currentLocation === element.route ? 'text-company' : 'text-companyDarkGrey',
                                    )}
                                >
                                    {element.label}
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <ListItem
                        key={Routes.PROJECTS}
                        disablePadding
                        disabled={currentLocation === Routes.WORKSPACES}
                        className='justify-center border-b'
                    >
                        <ListItemButton
                            onClick={(e) => {
                                if (currentLocation === Routes.WORKSPACES) {
                                    e.preventDefault();
                                } else {
                                    navigate(`${Routes.PROJECTS}/${workspaceId}`);
                                }
                            }}
                            className={`relative min-h-[75px] flex-col items-center justify-center transition-all ${
                                isOpen ? 'w-100' : 'w-0'
                            }`}
                        >
                            <ListItemIcon
                                className={`justify-center ${
                                    currentLocation === `${Routes.PROJECTS}/${workspaceId}`
                                        ? 'fill-company [&>svg]:fill-company'
                                        : 'fill-companyDarkGrey [&>svg]:fill-companyDarkGrey'
                                }`}
                            >
                                <FolderOpenIcon />
                            </ListItemIcon>
                            <ListItemText
                                className={classNames(
                                    `flex-none overflow-hidden text-center text-lg transition-all [&>span]:text-[14px]`,
                                    isOpen ? 'max-h-32 opacity-100' : 'max-h-0 opacity-0',
                                    currentLocation === Routes.PROJECTS ? 'text-company' : 'text-companyDarkGrey',
                                )}
                            >
                                Projects
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={Routes.EDITOR} disablePadding className='justify-center border-b'>
                        <ListItemButton
                            onClick={() => {
                                if (layout && currentLocation === `${Routes.EDITOR}/${layout?.id}`) {
                                    navigate(`${Routes.PROJECT}/${layout.projectId}`);
                                } else {
                                    handleToggleModal();
                                }
                            }}
                            className={`relative min-h-[75px] flex-col items-center justify-center transition-all ${
                                isOpen ? 'w-100' : 'w-0'
                            }`}
                        >
                            <ListItemIcon
                                className={`justify-center ${
                                    currentLocation === Routes.EDITOR
                                        ? 'fill-company [&>svg]:fill-company'
                                        : 'fill-companyDarkGrey [&>svg]:fill-companyDarkGrey'
                                }`}
                            >
                                {(currentLocation === `${Routes.EDITOR}/${layout?.id}` && <ArrowBackIcon />) || (
                                    <ImageIcon />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                className={classNames(
                                    `flex-none overflow-hidden text-center text-lg transition-all [&>span]:text-[14px]`,
                                    isOpen ? 'max-h-32 opacity-100' : 'max-h-0 opacity-0',
                                    currentLocation === Routes.EDITOR ? 'text-company' : 'text-companyDarkGrey',
                                )}
                            >
                                Editor
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <CreateLayoutModal
                initialProjectId={projectId}
                initialWorkspaceId={workspaceId}
                open={isCreateModalOpen}
                submit={handleCreateNewLayout}
                onClose={() => handleToggleModal()}
            />
        </>
    );
}

MainSidebar.defaultProps = {
    layout: undefined,
    layoutsChange: undefined,
    workspaceId: undefined,
};
