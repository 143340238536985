import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

export default function Sidebar(props: Props): ReactElement {
    const { children } = props;
    return <div className='shrink-0 basis-[320px]'>{children}</div>;
}
