import { useMutation } from '@apollo/client';

import { graphql } from '../generated/gql';

import useNotification from './use-notification';

type ProjectApi = {
    createNewProject(name: string, workspaceId: string | undefined): void;
};

const CREATE_PROJECT = graphql(`
    mutation CreateProject($input: CreateProjectInput!) {
        createProject(input: $input) {
            name
        }
    }
`);

export default function useCreateProject(): ProjectApi {
    const notification = useNotification();
    const [createProject] = useMutation(CREATE_PROJECT);

    async function createNewProject(name: string, workspaceId: string | undefined): Promise<void> {
        const result = await createProject({
            variables: {
                input: {
                    name,
                    workspaceId,
                },
            },
        });

        if (result.errors && result.errors.length > 0) {
            notification.addError('Something was wromg.');
            throw result.errors;
        }
    }

    return {
        createNewProject,
    };
}
