import { Divider, IconButton } from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';

import { classNames } from '../../utils/classes';

export type SideNavigationProps = {
    position: string;
    children: ReactNode;
    icon: ReactNode;
    expanded: boolean;
    setExpanded: (value: boolean) => void;
    disabled?: boolean;
};

export default function SideNavigation(props: SideNavigationProps): ReactElement {
    const { position, children, icon, disabled, expanded, setExpanded } = props;

    return (
        <div className={`shadow-lg absolute top-0 max-w-[56px] overflow-hidden rounded-lg bg-white shadow ${position}`}>
            <IconButton
                disabled={disabled}
                className='!rounded-none !p-[16px] text-[#999999]'
                onClick={() => setExpanded(!expanded)}
            >
                {icon}
            </IconButton>
            {expanded && (
                <>
                    <Divider />
                    <div
                        className={classNames(
                            'text-center [&>button>svg]:text-[#D0D0D0] [&>button]:w-full',
                            '[&>button]:rounded-none [&>button]:p-[11px] [&>button]:text-[13px]',
                            '[&>button]:text-[#D0D0D0] [&>hr]:m-[5px]',
                        )}
                    >
                        {children}
                    </div>
                </>
            )}
        </div>
    );
}

SideNavigation.defaultProps = {
    disabled: undefined,
};
