import React, { ReactElement } from 'react';

import CloseIcon from '../icons/outlines/close';
import SearchIcon from '../icons/outlines/search';

type Props = {
    value: string;
    onChange: (value: string) => void;
};

export default function SearchInput(props: Props): ReactElement {
    const { value, onChange } = props;

    return (
        <div className='flex w-full items-center space-x-2 rounded-xl p-2 px-4 outline outline-1 outline-companyLightGrey'>
            <SearchIcon width={18} height={18} className='fill-companyMediumGrey' />
            <input
                placeholder='Search'
                className='w-full outline-none'
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
            {value.length > 0 && (
                <button
                    type='button'
                    className='flex items-center justify-center rounded-full bg-companyMediumGrey'
                    onClick={() => onChange('')}
                >
                    <CloseIcon width={12} height={12} className='fill-white' />
                </button>
            )}
        </div>
    );
}
