import GridOnIcon from '@mui/icons-material/GridOn';
import GridViewIcon from '@mui/icons-material/GridView';
import { IconButton, Slider } from '@mui/material';
import React, { ReactElement } from 'react';

import useProjectScene from '../../hooks/use-project-scene';

export default function BackgroundGridActions(): ReactElement {
    const { selection } = useProjectScene();
    const maxGridNumber = 12;
    const minGridNumber = 1;

    return (
        <>
            <IconButton
                onClick={() =>
                    selection.selectBackgroundGrid(
                        selection.backgroundGrid < maxGridNumber
                            ? ++selection.backgroundGrid
                            : selection.backgroundGrid,
                    )
                }
            >
                <GridOnIcon className='!text-[#7a7676]' />
            </IconButton>
            <Slider
                className='my-[5px] !h-[100px] !text-[#7a7676] text-slate-500'
                sx={{
                    '&': {
                        color: '#64748b',
                    },
                    '& .MuiSlider-thumb': {
                        borderRadius: 0,
                        height: '5px',
                    },
                    '& input[type="range"]': {
                        WebkitAppearance: 'slider-vertical',
                    },
                }}
                max={maxGridNumber}
                min={minGridNumber}
                step={1}
                orientation='vertical'
                value={selection.backgroundGrid}
                onChange={(e, value) => {
                    selection.selectBackgroundGrid(value as number);
                }}
            />
            <IconButton
                onClick={() =>
                    selection.selectBackgroundGrid(
                        selection.backgroundGrid <= minGridNumber
                            ? selection.backgroundGrid
                            : --selection.backgroundGrid,
                    )
                }
            >
                <GridViewIcon className='!text-[#7a7676]' />
            </IconButton>
        </>
    );
}
