import React, { ReactElement, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

export default function PanelArea(props: Props): ReactElement {
    const { children } = props;
    return <div className='mb-[25px] flex w-full gap-[25px] pl-[345px]'>{children}</div>;
}
