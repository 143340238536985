import React, { ReactElement, useState } from 'react';
import Draggable, { ControlPosition, DraggableData, DraggableEvent } from 'react-draggable';

import useBackground from '../../hooks/use-background';
import useProjectScene from '../../hooks/use-project-scene';
import { Size } from '../../types/size';
import { classNames } from '../../utils/classes';

import BackgroundGrid from './background-grid';
import BackgroundRatioOverlay from './background-overlay';

export type BackgroundElementProps = {
    /** Current background */
    backgroundId: string;

    isDraggable: boolean;

    /** Current offset */
    offset: ControlPosition;

    /** Called when offset changing ended, e.g. dragging ended */
    offsetChange?: (value: ControlPosition) => void;
    
    /** Called when click on element and current packshot lost focus */
    objectSelected: (index: number) => void;

    /** Current scale */
    scale: number;

    /** Size of canvas (in px) */
    canvasSize: Size;
};

export default function BackgroundElement(props: BackgroundElementProps): ReactElement {
    const { backgroundId, isDraggable, offset, offsetChange, objectSelected, scale, canvasSize } = props;

    const { selection } = useProjectScene();

    const [isDragged, setIsDragged] = useState<boolean>(false);
    const [dragOffset, setDragOffset] = useState<ControlPosition | undefined>(undefined);
    const [imageSize, setImageSize] = useState<Size | undefined>(undefined);

    const backgroundQuery = useBackground(backgroundId);

    const { backgroundGrid, aspectRatio } = selection;

    const handleDragStart = (): void => {
        setIsDragged(true);
        setDragOffset(offset);
    };

    const handleDrag = (e: DraggableEvent, data: DraggableData): void => {
        e.preventDefault();
        const newOffset = { x: data.x, y: data.y };
        setDragOffset(newOffset);
    };

    const handleDragStop = (_e: DraggableEvent, data: DraggableData): void => {
        const newOffset = { x: data.x, y: data.y };
        offsetChange?.(newOffset);
        setIsDragged(false);
        setDragOffset(undefined);
    };

    const handleBackgroundImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>): void => {
        const image = event.target as HTMLImageElement;
        setImageSize({ width: image.naturalWidth, height: image.naturalHeight });
    };

    const currentOffset = dragOffset ?? offset;
    let maxWidth, maxHeight;
    if (imageSize) {
        maxWidth = 'none';
        maxHeight = 'none';
        if (imageSize.width < imageSize.height) {
            maxWidth = `${canvasSize.width}px`;
        } else {
            maxHeight = `${canvasSize.height}px`;
        }
    }

    return (
        <div
            onClick={() => objectSelected(-1)}
            className='relative h-full overflow-hidden'
            style={{
                width: `${canvasSize.width}px`,
                height: `${canvasSize.height}px`,
            }}
        >
            <BackgroundRatioOverlay aspectRatio={aspectRatio} canvasSize={canvasSize}>
                <BackgroundGrid itemCount={backgroundGrid} canvasSize={canvasSize} />
                {!backgroundQuery.loading && (
                    <Draggable
                        disabled={!isDraggable}
                        onStart={handleDragStart}
                        onStop={handleDragStop}
                        position={currentOffset}
                        onDrag={handleDrag}
                    >
                        {/* Needs an outer div because `Draggable` sets the `transform` CSS property for moving 
                    and would overwrite the scaling */}
                        <div>
                            <div
                                style={{
                                    transformOrigin: 'top left',
                                    transform: `scale(${scale})`,
                                }}
                            >
                                <img
                                    alt={backgroundQuery.data?.background.name ?? undefined}
                                    className={classNames(
                                        `${isDraggable ? 'cursor-grab' : 'cursor-default'}`,
                                        `${isDragged ? 'cursor-grabbing' : ''}`,
                                    )}
                                    style={{
                                        minWidth: `${canvasSize.width}px`,
                                        maxWidth,
                                        minHeight: `${canvasSize.height}px`,
                                        maxHeight,
                                    }}
                                    draggable={false}
                                    src={backgroundQuery.data?.background.url}
                                    onLoad={(event) => handleBackgroundImageLoad(event)}
                                />
                            </div>
                        </div>
                    </Draggable>
                )}
            </BackgroundRatioOverlay>
        </div>
    );
}

BackgroundElement.defaultProps = {
    offsetChange: undefined,
};
