import '../styles/accordion.css';

import React, { ReactElement, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CreateLayoutButton from '../components/button/create-layout-button';
import LayoutsScene from '../components/layout/layouts/layouts-scene';
import MainContainer from '../components/layout/main-container';
import MainHeader from '../components/layout/main-header';
import MainSidebar from '../components/layout/main-sidebar';
import PanelArea from '../components/layout/projects/panel-area';
import Sidebar from '../components/layout/projects/sidebar';
import ViewContainer from '../components/layout/view-container';
import ProjectList from '../components/lists/project/workspaces-list';
import SearchLayout from '../components/project/layout/search-layout';
import { CreateLayoutInput, LayoutRenderingProgress, LayoutStatus } from '../generated/gql/graphql';
import useCreateLayout from '../hooks/use-create-layout';
import useLayouts from '../hooks/use-layouts';
import useProject from '../hooks/use-project';
import Routes from '../types/enums/routes';

export default function Layouts(): ReactElement {
    const { id: projectId } = useParams();
    const { project } = useProject(projectId ?? '');

    const [search, setSearch] = useState<string>('');
    const [status, setStatus] = useState<LayoutStatus>(LayoutStatus.Active);
    const [renderingProgress, setRenderingProgress] = useState<LayoutRenderingProgress | undefined>(undefined);
    const [sortBy, setSortBy] = useState<string>('CreatedAt_true');

    const { fetchLayouts: layouts, refetchLayouts } = useLayouts(
        projectId,
        {
            name: search,
            status,
            generation: renderingProgress ?? '',
        },
        sortBy,
    );

    const handleSearch = (newSearch: string): void => {
        setSearch(newSearch);
    };

    const { createNewLayout } = useCreateLayout();
    const navigate = useNavigate();
    const handleCreateNewLayout = async (input: CreateLayoutInput): Promise<void> => {
        const layout = await createNewLayout(input);
        if (layout) {
            // Refetch layouts
            await refetchLayouts();
            navigate(`${Routes.EDITOR}/${layout.id}`);
        }
    };

    const handleStatusChange = (newStatus: LayoutStatus): void => {
        setStatus(newStatus);
    };

    const handleRenderingProgressChange = (newRenderingProgress: LayoutRenderingProgress | undefined): void => {
        setRenderingProgress(newRenderingProgress);
    };

    const handleSortByChange = (newSortBy: string): void => {
        setSortBy(newSortBy);
    };

    const handleLayoutsChange = async (): Promise<void> => {
        // Refetch layouts
        await refetchLayouts();
    };

    return (
        <MainContainer>
            <MainSidebar workspaceId={project?.workspaceId ?? undefined} layoutsChange={handleLayoutsChange} />
            <ViewContainer>
                <MainHeader />
                <PanelArea>
                    <SearchLayout submit={handleSearch} />
                    {!project?.readOnly && (
                        <CreateLayoutButton
                            initialWorkspaceId={project?.workspaceId ?? undefined}
                            initialProjectId={projectId}
                            submit={handleCreateNewLayout}
                        />
                    )}
                </PanelArea>
                <div className='flex w-full flex-row gap-[25px]'>
                    <Sidebar>
                        <ProjectList />
                    </Sidebar>
                    <LayoutsScene
                        project={project}
                        layouts={layouts ?? []}
                        layoutsChange={handleLayoutsChange}
                        status={status}
                        statusChange={handleStatusChange}
                        renderingProgress={renderingProgress}
                        renderingProgressChange={handleRenderingProgressChange}
                        sortBy={sortBy}
                        sortByChange={handleSortByChange}
                    />
                </div>
            </ViewContainer>
        </MainContainer>
    );
}
