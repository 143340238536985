import { gql, useQuery } from '@apollo/client';

import { FieldFilterDefinition } from '../generated/gql/graphql';

type BackgroundFilterApi = {
    loading: boolean;
    error: any;
    fieldFilters: FieldFilterDefinition[];
    selected: any;
};

const GET_BACKGROUND_FILTER_OPTIONS = gql`
    query BackgroundFilters {
        backgroundFilters {
            fieldName
            name
            options {
                label
                value
            }
        }
    }
`;

export default function useBackgroundFilters(): BackgroundFilterApi {
    const { data, loading, error } = useQuery(GET_BACKGROUND_FILTER_OPTIONS);

    return {
        loading,
        error,
        fieldFilters: data?.backgroundFilters || [],
        selected: [],
    };
}
