import { Alert } from '@mui/material';
import React, { ReactElement, useCallback, useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';

import Button from '../components/button/button';
import CloudUpload from '../components/icons/cloud-upload.svg';
import ArrowDownIcon from '../components/icons/outlines/arrow-down';
import TextInput from '../components/inputs/text-input';
import Modal from '../components/modal/modal';
import AssetUploadPreview from '../components/upload/asset-upload-preview';
import useAsset from '../hooks/use-asset';
import useLocale from '../hooks/use-locale';
import useNotification from '../hooks/use-notification';
import { classNames } from '../utils/classes';

type Props = {
    headline: string;
    open: boolean;
    onClose(): void;
};

export default function AssetUploadModal(props: Props): ReactElement {
    const { headline, open, onClose } = props;
    const { getText } = useLocale();

    const notification = useNotification();
    const asset = useAsset();

    const [files, setFiles] = useState<File[]>([]);
    const [isUploading, setIsUploading] = useState(false);

    const onError = useCallback(
        (errorCode: ErrorCode | string) => {
            let errorTextPrefix;
            switch (errorCode) {
                case ErrorCode.FileInvalidType: {
                    errorTextPrefix = 'sections.assetUpload.notification.errors.fileInvalidType';
                    break;
                }
                case ErrorCode.TooManyFiles: {
                    errorTextPrefix = 'sections.assetUpload.notification.errors.singleAssetSelect';
                    break;
                }
                case ErrorCode.FileTooLarge: {
                    errorTextPrefix = 'sections.assetUpload.notification.errors.fileTooLarge';
                    break;
                }
                default: {
                    errorTextPrefix = 'sections.assetUpload.notification.errors.genericError';
                    break;
                }
            }

            notification.addError(getText(`${errorTextPrefix}.body`), getText(`${errorTextPrefix}.headline`));
        },
        [notification, getText],
    );

    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            if (fileRejections.length > 0) {
                const fileRejection = fileRejections[0];
                onError(fileRejection.errors[0].code);
            } else {
                setFiles([...files, ...acceptedFiles]);
            }
        },
        [files, onError],
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/jpg': [],
            'image/png': [],
            'image/webp': [],
        },
        maxFiles: 1,
        maxSize: 10_485_760,
        onDrop,
    });

    const [assetName, setAssetName] = useState<string>('');

    function resetValues(): void {
        setFiles([]);
        setAssetName('');
        setIsUploading(false);
    }

    function handleOnClose(): void {
        if (isUploading) {
            // eslint-disable-next-line no-alert
            alert('Please wait for the upload to finish, before you close this window.');

            return;
        }

        onClose();
        resetValues();
    }

    async function onSubmit(): Promise<void> {
        try {
            setIsUploading(true);
            await asset.uploadBackground(assetName, files[0]);
            resetValues();
        } catch {
            setIsUploading(false);
        }
    }

    return (
        <Modal open={open} headline={headline} onClose={() => handleOnClose()} className='h-fit max-w-xl'>
            {files.length > 0 ? (
                <form className='flex h-full flex-col'>
                    <div className='flex flex-1 flex-col items-center justify-center space-y-5 rounded-xl p-3'>
                        <AssetUploadPreview file={files[0]} />
                        <div className='flex flex-col space-y-1'>
                            <p className='text-base text-companyDarkGrey'>
                                {getText('sections.assetUpload.background.form.name')}
                            </p>
                            <TextInput
                                placeholder=''
                                id='name'
                                onChange={(v) => setAssetName(v)}
                                value={assetName}
                                disabled={isUploading}
                            />
                            <p className='text-xs text-companyDarkGrey'>
                                {getText('sections.assetUpload.background.form.nameExample')}
                            </p>
                        </div>
                    </div>
                    <div
                        className={classNames(
                            'mt-3 flex space-x-2',
                            files.length > 0 ? 'justify-between' : 'justify-end',
                        )}
                    >
                        {files.length > 0 && (
                            <button
                                className='flex cursor-pointer flex-row items-center'
                                onClick={() => setFiles([])}
                                type='button'
                                disabled={isUploading}
                            >
                                <ArrowDownIcon className='rotate-90 fill-companyDarkGrey' />
                                <p className='text-base uppercase text-companyDarkGrey'>
                                    {getText('sections.assetUpload.back')}
                                </p>
                            </button>
                        )}
                        <Button
                            onClick={() => onSubmit()}
                            label={getText('sections.assetUpload.upload')}
                            disabled={assetName === '' || isUploading}
                        />
                    </div>
                </form>
            ) : (
                <>
                    <div
                        {...getRootProps({ className: 'dropzone' })}
                        className='flex flex-col items-center space-y-3 rounded-xl bg-companyLightGrey p-3'
                    >
                        <img src={CloudUpload} alt='' width={220} height={220} />
                        <input {...getInputProps()} />
                        <p className='text-center text-xl text-companyMediumGrey'>
                            {getText('sections.assetUpload.drag')}
                            <br />
                            {getText('sections.assetUpload.or')}
                        </p>
                        <Button onClick={() => {}} label={getText('sections.assetUpload.action')} />
                    </div>
                    <Alert className='mt-5 rounded-xl' severity='info'>
                        Supported files are images in jpg or png with sRGB colour profile, up to sizes of 6000px px or
                        10 MB.
                    </Alert>
                </>
            )}
        </Modal>
    );
}
