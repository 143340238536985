import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import CreateProjectButton from '../components/button/create-project-button';
import MainContainer from '../components/layout/main-container';
import MainHeader from '../components/layout/main-header';
import MainSidebar from '../components/layout/main-sidebar';
import PanelArea from '../components/layout/projects/panel-area';
import ProjectsContainer from '../components/layout/projects/projects-container';
import ProjectsScene from '../components/layout/projects/projects-scene';
import Sidebar from '../components/layout/projects/sidebar';
import ViewContainer from '../components/layout/view-container';
import ProjectList from '../components/lists/project/workspaces-list';
import SearchProject from '../components/project/search-project';
import '../styles/accordion.css';

export default function Projects(): ReactElement {
    const { id: workspaceId } = useParams();

    return (
        <MainContainer>
            <MainSidebar workspaceId={workspaceId} />
            <ViewContainer>
                <MainHeader />
                <PanelArea>
                    <SearchProject />
                    <CreateProjectButton />
                </PanelArea>
                <ProjectsContainer workspaceId={workspaceId}>
                    <Sidebar>
                        <ProjectList />
                    </Sidebar>
                    <ProjectsScene />
                </ProjectsContainer>
            </ViewContainer>
        </MainContainer>
    );
}
