import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { ReactElement } from 'react';

import { LayoutStatus } from '../../../generated/gql/graphql';

export type StatusSelectorProps = {
    status: LayoutStatus;
    statusChange: (value: LayoutStatus) => void;
};

export default function StatusSelector({ status, statusChange }: StatusSelectorProps): ReactElement {
    const handleChange = async (event: SelectChangeEvent): Promise<void> => {
        if (event.target.value) {
            statusChange(event.target.value as LayoutStatus);
        }
    };

    return (
        <FormControl style={{ minWidth: 150, maxWidth: 200 }} size='small'>
            <InputLabel className='text-[14px]'>Status</InputLabel>
            <Select
                className='mr-[10px] rounded-[15px] border-0 text-left text-[14px]'
                value={status}
                label='Status'
                onChange={handleChange}
            >
                <MenuItem defaultValue={LayoutStatus.Active} value={LayoutStatus.Active}>
                    {LayoutStatus.Active}
                </MenuItem>
                <MenuItem value={LayoutStatus.Archived}>{LayoutStatus.Archived}</MenuItem>
            </Select>
        </FormControl>
    );
}
