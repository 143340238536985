import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { DraggableData, Rnd } from 'react-rnd';

import PresentationSelectorModal, { PresentationSelectorModalProps } from '../../modals/presentation-selector-modal';
import SwitchProduct, { SwitchProductProps } from '../../modals/switch-product';
import { CanvasObject } from '../../types/canvas-object';
import { Size } from '../../types/size';

import DraggableHandle from './draggable-handle';
import PackshotImage from './packshot';
import PackshotMenu from './packshot-menu';

export type PackshotElementProps = {
    packshot: CanvasObject;
    index: number;
    isGenerated: boolean;
    selected: boolean;
    canvasSize: Size;

    objectChanged: (
        data: Partial<Pick<CanvasObject, 'scale' | 'offset' | 'packshotSize' | 'presentationId' | 'presentation'>>,
    ) => void;
    objectDeleted: () => void;
    objectDuplicated: () => void;
    objectMovedUp: () => void;
    objectMovedDown: () => void;
    objectSelected: () => void;
};

export default function PackshotElement(props: PackshotElementProps): ReactElement {
    const {
        packshot,
        index,
        isGenerated,
        selected,
        canvasSize,
        objectChanged,
        objectDuplicated,
        objectDeleted,
        objectMovedUp,
        objectMovedDown,
        objectSelected,
    } = props;
    const canvasObjectScale = packshot.scale ?? 1;
    const canvasObjectWidth = packshot.packshotSize?.width ?? 0;
    const canvasObjectHeight = packshot.packshotSize?.height ?? 0;
    const canvasObjectOffsetX = packshot.offset?.x ?? 0;
    const canvasObjectOffsetY = packshot.offset?.y ?? 0;

    const [isDrag, setIsDrag] = useState<boolean>(false);
    const [productModal, setProductModal] = useState<Omit<SwitchProductProps, 'headline'> | undefined>(undefined);
    const [presentationModal, setPresentationModal] = useState<PresentationSelectorModalProps | undefined>();

    function updateScaleFactor(newPackshotWidth: number, _newPackshotHeight: number, x: number, y: number): void {
        objectChanged({
            scale: (newPackshotWidth * 100) / canvasObjectWidth / 100,
            offset: { x, y },
        });
    }

    function handleImageLoaded(e: SyntheticEvent<HTMLImageElement, Event>): void {
        const target = e.target as HTMLImageElement;
        const newPackshotSize = { width: target.naturalWidth, height: target.naturalHeight };

        const changes: Partial<CanvasObject> = {
            packshotSize: newPackshotSize,
        };
        if (!packshot.offset) {
            changes.offset = {
                x: (canvasSize.width - newPackshotSize.width) / 2,
                y: (canvasSize.height - newPackshotSize.height) / 2,
            };
        }
        objectChanged(changes);
    }

    const handlePresentationSelect = (presentation: CanvasObject['presentation']): void => {
        objectChanged({
            presentationId: presentation?.id,
            presentation,
        });

        setPresentationModal(undefined);
    };

    const handleProductChanged = (productId: string): void => {
        setPresentationModal({
            name: packshot.presentation?.name ?? undefined,
            productId,
            onClose: () => setPresentationModal(undefined),
            onSelect: (presentation) => handlePresentationSelect(presentation),
            selectedPresentationId: undefined,
        });
        setProductModal(undefined);
    };

    function handleDragStop(_e: any, d: DraggableData): void {
        let positionX: number = d.x;
        let positionY: number = d.y;

        if (d.x > canvasSize.width - 40) {
            positionX = canvasSize.width - 40;
        }

        if (d.x < -(canvasObjectWidth * canvasObjectScale - 40)) {
            positionX = -(canvasObjectWidth * canvasObjectScale - 40);
        }

        if (d.y > canvasSize.height - 40) {
            positionY = canvasSize.height - 40;
        }

        if (d.y < -(canvasObjectHeight * canvasObjectScale - 40)) {
            positionY = -(canvasObjectHeight * canvasObjectScale - 40);
        }

        objectChanged({
            offset: { x: positionX, y: positionY },
        });

        setIsDrag(false);
    }

    if (!packshot.presentation) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    const { url, productId } = packshot.presentation;
    const menuPosition = {
        x: canvasObjectOffsetX + canvasObjectWidth * canvasObjectScale,
        y: canvasObjectOffsetY + (canvasObjectHeight * canvasObjectScale) / 2,
    };

    return (
        <>
            <Rnd
                style={{ zIndex: index }}
                size={{
                    width: canvasObjectWidth * canvasObjectScale,
                    height: canvasObjectHeight * canvasObjectScale,
                }}
                disableDragging={isGenerated}
                position={packshot.offset ?? { x: 0, y: 0 }}
                enableResizing={!isGenerated ?? { topLeft: true, topRight: true, bottomLeft: true, bottomRight: true }}
                lockAspectRatio
                onMouseDown={() => (isGenerated ? undefined : objectSelected())}
                onDragStop={(e, d) => handleDragStop(e, d)}
                onDrag={() => {
                    setIsDrag(true);
                }}
                onResize={() => setIsDrag(true)}
                onResizeStop={(_e, _dir, a, _d, position) => {
                    updateScaleFactor(a.clientWidth, a.clientHeight, position.x, position.y);
                    setIsDrag(false);
                }}
                resizeHandleComponent={
                    selected
                        ? {
                              topLeft: <DraggableHandle />,
                              topRight: <DraggableHandle />,
                              bottomLeft: <DraggableHandle />,
                              bottomRight: <DraggableHandle />,
                          }
                        : undefined
                }
            >
                {url && (
                    <PackshotImage
                        classes={selected ? 'border-white' : 'border-transparent'}
                        imageSrc={url}
                        onLoad={(e) => handleImageLoaded(e)}
                    />
                )}
            </Rnd>
            {!isDrag && selected && !isGenerated && (
                <PackshotMenu
                    onDelete={() => objectDeleted()}
                    onMoveUp={() => objectMovedUp()}
                    onMoveDown={() => objectMovedDown()}
                    onChangePresentation={() => {
                        if (productId) {
                            setPresentationModal({
                                productId,
                                selectedPresentationId: packshot.presentationId,
                                onClose: () => setPresentationModal(undefined),
                                onSelect: (presentation) => handlePresentationSelect(presentation),
                            });
                        }
                    }}
                    onChangeProduct={() => {
                        if (productId) {
                            setProductModal({
                                selectedProductId: productId,
                                productSelected: (product) => handleProductChanged(product.id),
                                onClose: () => setProductModal(undefined),
                            });
                        }
                    }}
                    onDuplicateProduct={() => objectDuplicated()}
                    position={menuPosition}
                />
            )}
            {presentationModal && <PresentationSelectorModal {...presentationModal} />}
            {productModal && <SwitchProduct {...productModal} headline='Switch Product' />}
        </>
    );
}
