import { Replay } from '@mui/icons-material';
import React, { ReactElement } from 'react';

import { ProductInput } from '../../../generated/gql/graphql';
import useLocale from '../../../hooks/use-locale';
import { NewOrExistingRenderingObjectProductInput } from '../../../modals/adaption-selection-modal';
import TextIconButton from '../../button/text-icon-button';

import LabelListItem from './label-list-item';

type Props = {
    inputs: ProductInput[] | undefined;
    selectedFiles: NewOrExistingRenderingObjectProductInput[];
    select(input: ProductInput, file: File): void;
    reset(): void;
};

export default function LabelList(props: Props): ReactElement {
    const { inputs, selectedFiles, select, reset } = props;
    const { getText } = useLocale();

    if (!inputs) {
        return <>{getText('sections.adaption.noAvailable')}</>;
    }

    return (
        <ul className='h-full flex-col space-y-3 overflow-y-scroll rounded-xl bg-companyLightGrey p-5'>
            {inputs.map((i) => (
                <LabelListItem
                    key={`${i.id.toString()}`}
                    productInputDefinition={i}
                    productInput={selectedFiles.find((o) => o.identifier === i.identifier)}
                    onUpload={(f) => select(i, f)}
                />
            ))}
            <li className='flex flex-row justify-end'>
                <TextIconButton
                    onClick={() => reset()}
                    icon={<Replay width={16} height={16} />}
                    label={getText('sections.adaption.reset')}
                    iconPosition='left'
                    type='tertiary'
                    disabled={selectedFiles.length === 0}
                />
            </li>
        </ul>
    );
}
