import React, { ReactElement } from 'react';
import { useLocation } from 'react-router';

import useProjectScene from '../../../hooks/use-project-scene';
import { Project } from '../../../types/project';
import ProjectItem from '../../project/project-item';

import SortList from './sort-list';

export default function ProjectsScene(): ReactElement {
    const { projects } = useProjectScene().selection;
    const data = useLocation().state;
    const workspaceName = projects?.length ? projects[0].workspace?.name : data?.workspaceName || '';

    return (
        <div className='flex basis-[100%] flex-wrap content-start gap-[20px] rounded-[15px] bg-white p-[50px] shadow'>
            <div className='flex h-fit w-full justify-items-center'>
                <h2 className='text-[24px] text-rose-600'>
                    {workspaceName} Projects{' '}
                    <span className='text-[16px] text-slate-400'> ({projects?.length ?? '0'})</span>
                </h2>
            </div>
            <div className='mb-[25px] w-full text-right'>
                <SortList />
            </div>
            {projects && projects.map((project: Project) => <ProjectItem project={project} key={project.id} />)}
        </div>
    );
}
