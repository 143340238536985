import React, { ReactElement, useEffect, useState } from 'react';

import AnimationList from '../components/lists/animation-list';
import GridSizer from '../components/lists/grid-sizer';
import PerspectiveList from '../components/lists/perspective-list';
import ModalWithTabNavigation from '../components/modal/modal-with-tab-navigation';
import { Presentation, PresentationType } from '../generated/gql/graphql';
import useLocale from '../hooks/use-locale';
import useProductPresentations from '../hooks/use-product-presentations';

export type PresentationSelectorItem = Pick<
    Presentation,
    'id' | 'type' | 'productId' | 'name' | 'thumbnailUrl' | 'url'
>;

export type PresentationSelectorModalProps = {
    name?: string;
    productId: string;
    selectedPresentationId?: string;
    onSelect(presentation: PresentationSelectorItem): void;
    onClose(): void;
};

export default function PresentationSelectorModal(props: PresentationSelectorModalProps): ReactElement {
    const { productId: selectedProductId, selectedPresentationId, name, onSelect, onClose } = props;

    const { getText } = useLocale();

    const [gridSize, setGridSize] = useState(4);
    const [activeTab, setActiveTab] = useState(0);

    // Get perspectives and animations of product
    const { data } = useProductPresentations(selectedProductId);

    const perspectives = data?.product?.perspectives ?? [];
    const animations = data?.product?.animations ?? [];
    const identicalPerspective = perspectives.find((p) => p.name === name);

    useEffect(() => {
        if (identicalPerspective) {
            onSelect({ ...identicalPerspective, productId: selectedProductId, type: PresentationType.Perspective });
            onClose();
        }
    }, [data]);

    if (!data) {
        return <>Loading...</>;
    }

    function getActiveList(): ReactElement {
        if (activeTab === 0) {
            return (
                <PerspectiveList
                    gridSize={gridSize}
                    perspectives={perspectives}
                    selectedPerspectiveId={selectedPresentationId}
                    onSelectPerspective={(presentation) =>
                        onSelect({ ...presentation, productId: selectedProductId, type: PresentationType.Perspective })
                    }
                />
            );
        }
        return (
            <AnimationList
                gridSize={gridSize}
                animations={animations}
                selectedAnimationId={selectedPresentationId}
                onSelectAnimation={(presentation) =>
                    onSelect({ ...presentation, productId: selectedProductId, type: PresentationType.Perspective })
                }
            />
        );
    }

    function render(): ReactElement {
        if (!identicalPerspective) {
            return (
                <ModalWithTabNavigation
                    open
                    headlines={[
                        getText('sections.presentation.perspective.headline'),
                        getText('sections.presentation.animation.headline'),
                    ]}
                    activeIndex={activeTab}
                    onSelectTab={(i) => setActiveTab(i)}
                    onClose={onClose}
                >
                    {getActiveList()}
                    <GridSizer gridSize={gridSize} setGridSize={(v) => setGridSize(v)} />
                </ModalWithTabNavigation>
            );
        }

        return <></>;
    }

    return render();
}

PresentationSelectorModal.defaultProps = {
    selectedPresentationId: undefined,
};
