import React, { ReactElement } from 'react';

import useLocale from '../../hooks/use-locale';

export default function NoResults(): ReactElement {
    const { getText } = useLocale();

    return (
        <div className='flex h-48 flex-col items-center justify-center'>
            <p className='text-sm text-gray-500'>{getText('components.assets.noResults')}</p>
        </div>
    );
}
