import { CloseRounded } from '@mui/icons-material';
import React, { ReactElement, ReactNode } from 'react';

import { classNames } from '../../utils/classes';
import IconButton from '../button/icon-button';
import { LoadingOverlay } from '../utils/loading-overlay';

type Props = {
    children: ReactNode;
    open: boolean;
    headline: string;
    onClose(): void;
    className?: string;
    overlay?: ReactNode;
};

export default function Modal(props: Props): ReactElement {
    const { children, open, headline, onClose, className, overlay } = props;

    if (!open) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    return (
        <div className='fixed bottom-0 left-0 right-0 top-0 z-50 !mt-0 flex bg-black bg-opacity-30 p-32'>
            <div
                className={classNames('mx-auto flex w-full flex-col rounded-xl bg-white', className || '')}
                style={{
                    position: 'relative',
                }}
            >
                <div className='flex flex-row justify-between p-6 pb-0'>
                    <p className='font-bold uppercase text-companyMediumGrey'>{headline}</p>
                    <IconButton icon={<CloseRounded className='!fill-companyMediumGrey' />} onClick={onClose} />
                </div>
                <div className='flex h-full max-h-[calc(100%_-_30px)] w-full flex-col justify-between p-6'>
                    {children}
                </div>
                {overlay && <LoadingOverlay>{overlay}</LoadingOverlay>}
            </div>
        </div>
    );
}

Modal.defaultProps = {
    className: '',
    overlay: undefined,
};
