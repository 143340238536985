import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Button } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CreateLayoutInput } from '../../generated/gql/graphql';
import CreateLayoutModal, { CreateLayoutModalProps } from '../../modals/create-layout-modal';

export type CreateLayoutButtonProps = Pick<
    CreateLayoutModalProps,
    'initialProjectId' | 'initialWorkspaceId' | 'submit'
>;

export default function CreateLayoutButton({
    initialProjectId,
    initialWorkspaceId,
    submit,
}: CreateLayoutButtonProps): ReactElement {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const { id } = useParams();

    const handleSubmit = async (input: CreateLayoutInput): Promise<void> => {
        setIsCreateModalOpen(false);
        await submit(input);
    };
    function handleCancel(): void {
        setIsCreateModalOpen(false);
    }

    return (
        <>
            <Button
                disabled={!id}
                onClick={() => setIsCreateModalOpen(true)}
                className={
                    'h-full w-[210px] rounded-[15px] border-none bg-white ' +
                    'px-[0px] text-[15px] font-bold text-company shadow'
                }
                startIcon={<AddOutlinedIcon className='!text-[30px]' />}
            >
                NEW LAYOUT
            </Button>
            <CreateLayoutModal
                open={isCreateModalOpen}
                initialProjectId={initialProjectId}
                initialWorkspaceId={initialWorkspaceId}
                submit={handleSubmit}
                onClose={() => handleCancel()}
            />
        </>
    );
}
