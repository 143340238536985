import React, { ReactElement } from 'react';

type Props = {
    gridSize: number;
};

export default function SkeletonList(props: Props): ReactElement {
    const { gridSize } = props;

    return (
        <ul
            style={{
                gridTemplateColumns: `repeat(${gridSize}, minmax(0, 1fr))`,
            }}
            className='grid h-48 gap-5 overflow-y-auto px-5 py-3'
        >
            {Array.from({ length: 15 })
                .fill(0)
                .map((_, i) => (
                    <li key={`${i.toString()}`} className='cursor-pointer space-y-2'>
                        <div
                            className='animate-pulse rounded-lg bg-gray-50'
                            style={{
                                width: gridSize === 2 ? '100%' : 'auto',
                                aspectRatio: '1/1',
                            }}
                        />
                        <div className='h-6 w-full animate-pulse rounded-full bg-gray-50' />
                    </li>
                ))}
        </ul>
    );
}
