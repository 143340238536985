import { CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import * as React from 'react';

import { ProjectLayout } from '../hooks/use-layouts';
import { NonPartial } from '../utils/types';

export interface PreviewRenderingJobProps {
    isOpen: boolean;
    layout: NonPartial<Pick<ProjectLayout, 'name' | 'previewRenderingJob' | 'latestRenderingJob'>>;
    onClose: () => void;
}

export default function PreviewRenderingPicture(props: PreviewRenderingJobProps): React.ReactElement {
    const { onClose, isOpen, layout } = props;

    const [isPreviewImagesLoaded, setIsPreviewImagesLoaded] = React.useState<boolean>(false);
    const preview = layout.previewRenderingJob?.result?.renderingUrl;
    const generated = layout.latestRenderingJob?.result?.renderingUrl;

    if (!preview && !generated) {
        return <></>;
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={false}
            open={isOpen}
            onClose={onClose}
            sx={{
                '& .MuiPaper-root': {
                    minWidth: '100%',
                    minHeight: '100%',
                    background: 'none',
                },
            }}
        >
            <DialogContent onClick={onClose} className='flex flex-wrap items-center justify-center text-center'>
                <>
                    {!isPreviewImagesLoaded && <CircularProgress />}
                    <img
                        onLoad={() => setIsPreviewImagesLoaded(true)}
                        className={`${isPreviewImagesLoaded ? 'inline-block' : 'hidden'}`}
                        src={generated || preview}
                        alt={layout.name || ''}
                    />
                </>
            </DialogContent>
        </Dialog>
    );
}
