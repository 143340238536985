import { LogoutOutlined } from '@mui/icons-material';
import { useKeycloak } from '@react-keycloak/web';
import React, { ReactElement } from 'react';

import useLocale from '../../hooks/use-locale';
import useUser from '../../hooks/use-user';
import TextIconButton from '../button/text-icon-button';

export default function MainHeader(): ReactElement {
    const { getText } = useLocale();
    const { keycloak } = useKeycloak();
    const { user } = useUser();
    return (
        <div className='mb-10 flex w-full flex-row justify-between'>
            <div className='flex items-center'>
                {user && user.activeClient?.logoUrl && (
                    <img
                        alt=''
                        src={user.activeClient.logoUrl}
                        width='100%'
                        height='100%'
                        className='max-h-16 max-w-xl'
                    />
                )}
            </div>
            <div className='flex flex-row'>
                <TextIconButton
                    type='secondary'
                    label={getText('components.header.logout')}
                    icon={<LogoutOutlined className='fill-companyDarkGrey' />}
                    onClick={() => {
                        keycloak.logout();
                    }}
                />
            </div>
        </div>
    );
}
