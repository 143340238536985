import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';

import useProjectScene from '../../../hooks/use-project-scene';
import useProjects from '../../../hooks/use-projects';

export default function SortList(): ReactElement {
    const [sorting, setSorting] = useState<string>('CreatedAt_true');

    const { id } = useParams();
    const { selectProjects } = useProjectScene().selection;
    const { sortProjects } = useProjects(id);

    const handleSortChange = async (e: SelectChangeEvent): Promise<void> => {
        if (e.target.value) {
            selectProjects(await sortProjects(e.target.value));
            setSorting(e.target.value);
        }
    };

    return (
        <FormControl style={{ minWidth: 100 }} size='small'>
            <InputLabel className='text-[14px]'>Sort by</InputLabel>
            <Select
                className='rounded-[15px] border-0 text-left text-[14px]'
                label='Sort by'
                value={sorting}
                onChange={handleSortChange}
            >
                <MenuItem defaultChecked value='CreatedAt_true'>
                    Newest
                </MenuItem>
                <MenuItem value='CreatedAt_false'>Oldest</MenuItem>
                <MenuItem value='Name_false'>Sort A-Z</MenuItem>
                <MenuItem value='Name_true'>Sort Z-A</MenuItem>
            </Select>
        </FormControl>
    );
}
