import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { ChangeEvent, ReactElement, useContext, useEffect, useState } from 'react';

import GeneratorButton from '../components/button/generator-button';
import ResetButton from '../components/button/reset-button';
import ShadowsReflectionsSwitch from '../components/button/shadows-reflections-switch';
import InfoIcon from '../components/icons/outlines/info';
import ResetIcon from '../components/icons/outlines/reset';
import Modal from '../components/modal/modal';
import { CreateRenderingJobSetting, RenderingSetting } from '../generated/gql/graphql';
import useLocale from '../hooks/use-locale';
import useProjectScene from '../hooks/use-project-scene';
import {
    RenderingSettingOutputIdentifierPrefix,
    RenderingSettingOutputDisabled,
} from '../hooks/use-rendering-settings';
import useUser from '../hooks/use-user';
import { GeneratorContext } from '../providers/generator-provider';

type Props = {
    open: boolean;
    settings: RenderingSetting[];

    /** Called when user confirms rendering */
    submit: (settings: CreateRenderingJobSetting[], disableShadowsAndReflections: boolean | undefined) => void;

    /** Called when user cancels rendering */
    cancel(): void;
};

export default function GeneratorModal(props: Props): ReactElement {
    const { open, settings, submit, cancel } = props;
    const { user } = useUser();

    const [options, setOptions] = useState<CreateRenderingJobSetting[]>([]);
    const [reRender, setReRender] = useState<number>(Math.floor(Math.random() * 10));
    const { disableShadowsAndReflections } = useProjectScene().selection;

    const canUseShadowsAndReflections = user?.activeClient?.canUseShadowsAndReflections ?? true;

    useEffect(() => {
        if (settings.length > 0) {
            setOptions(
                settings.map((item) => ({
                    identifier: item.identifier,
                    value: item.options[0].value,
                })),
            );
        }
    }, [settings, open, reRender]);

    const { getText } = useLocale();

    function onChangeOption(event: ChangeEvent<HTMLInputElement>): void {
        setOptions((prev) => [
            ...prev.filter((item) => item.identifier !== event.target.name),
            {
                identifier: event.target.name,
                value: event.target.value,
            },
        ]);
    }

    function onResetOptions(): void {
        setOptions([]);
        setReRender(Math.floor(Math.random() * 10));
    }
    const { isRunning } = useContext(GeneratorContext);

    const handleSubmit = async (): Promise<void> => {
        submit(options, disableShadowsAndReflections);
    };

    const hasOutputs = settings.some((setting) =>
        setting.identifier.startsWith(RenderingSettingOutputIdentifierPrefix),
    );
    const noOutputEnabled =
        hasOutputs &&
        !options.some(
            (option) =>
                option.identifier.startsWith(RenderingSettingOutputIdentifierPrefix) &&
                option.value !== RenderingSettingOutputDisabled,
        );
    const submitDisabled = isRunning || noOutputEnabled;

    return (
        <Modal
            open={open}
            headline={getText('sections.generateModal.title')}
            onClose={() => cancel()}
            className='max-w-3xl'
            key={reRender}
        >
            <div className='overflow-y-scroll'>
                {settings?.length &&
                    settings.map((setting) => (
                        <fieldset key={setting.identifier} className='mt-3'>
                            <div className='flex text-sm leading-6 text-gray-900'>
                                <div>{setting.label}</div>
                                <Tooltip placement='right-end' title={setting.description}>
                                    <IconButton className='!ml-2 !p-0'>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className='mt-3 flex space-x-6'>
                                {setting.options.map((option, index) => (
                                    <div key={option.label} className='flex items-center gap-x-3'>
                                        <input
                                            onChange={onChangeOption}
                                            name={setting.identifier}
                                            value={option.value}
                                            defaultChecked={index === 0}
                                            type='radio'
                                            className='h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                        />
                                        <span className='block text-sm font-medium leading-6 text-gray-900'>
                                            {option.label}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </fieldset>
                    ))}
            </div>
            <div className='mt-6 flex flex-row justify-between space-x-3 border-t-[1px] border-slate-300 pt-6'>
                <ResetButton
                    label={getText('sections.generateModal.reset')}
                    onClick={() => onResetOptions()}
                    icon={<ResetIcon className='mr-[10px]' />}
                />
                <div className='flex'>
                    {canUseShadowsAndReflections ? <ShadowsReflectionsSwitch /> : undefined}
                    <GeneratorButton disabled={submitDisabled} loading={isRunning} submit={handleSubmit} />
                </div>
            </div>
        </Modal>
    );
}
