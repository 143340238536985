import React, { ReactElement } from 'react';

type IconProps = {
    width?: number;
    height?: number;
    className?: string;
};

export default function GeneratedIcon(props: IconProps): ReactElement {
    const { width, height, className } = props;

    return (
        <svg
            className={className}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
            width={width}
            height={height}
        >
            <path
                xmlns='http://www.w3.org/2000/svg'
                d='m510.858 121.727c-2.322-5.605-7.791-9.26-13.858-9.26h-482c-6.067 0-11.537 3.654-13.858 9.26-2.321 5.605-1.038 12.057 3.252 16.347l59.873 59.873v186.587c0 5.435 2.939 10.443 7.683 13.095 4.74 2.648 10.548 2.53 15.179-.32l109.261-67.238 49.004 49.004c5.857 5.857 15.355 5.857 21.213 0l241-241c4.289-4.292 5.572-10.743 3.251-16.348zm-426.359 54.025-33.285-33.285h249.639zm9.768 28.851 254.312-39.125-210.171 76.426c-3.607 1.312-6.574 3.956-8.291 7.389l-35.85 71.7zm21.944 139.583 31.432-62.863 26.941 26.941zm139.789 3.068-85.206-85.206 267.79-97.378z'
            />
        </svg>
    );
}

GeneratedIcon.defaultProps = {
    width: 24,
    height: 24,
    className: '',
};
