import { useContext } from 'react';

import { Project } from '../generated/gql/graphql';
import { ProjectSceneContext } from '../providers/project-scene-provider';

type ProjectSceneApi = {
    selection: {
        backgroundGrid: number;
        selectBackgroundGrid(value: number): void;

        backgroundColor: string;
        selectBackgroundColor(value: string): void;

        projects?: Project[];
        selectProjects(p: Project[] | undefined): void;
        unselectProjects(): void;

        aspectRatio: number;
        setAspectRatio(a: number): void;

        disableShadowsAndReflections?: boolean;
        setDisableShadowsAndReflections(disableShadowsAndReflections: boolean): void;
    };
};

export default function useProjectScene(): ProjectSceneApi {
    const { selection } = useContext(ProjectSceneContext);

    function selectBackgroundGrid(value: number): void {
        selection.setBackgroundGrid(value === selection.backgroundGrid ? 1 : value);
    }

    function selectBackgroundColor(value: string): void {
        selection.setBackgroundOverlayColor(value);
    }

    function unselectProjects(): void {
        selection.setProjects(undefined);
    }

    function selectProjects(p: Project[]): void {
        selection.setProjects(p);
    }

    function setAspectRatio(a: number): void {
        selection.setAspectRatio(a);
    }

    function setDisableShadowsAndReflections(disableShadowsAndReflections: boolean): void {
        selection.setDisableShadowsAndReflections(disableShadowsAndReflections);
    }

    return {
        selection: {
            backgroundColor: selection.backgroundOverlayColor,
            selectBackgroundColor,

            backgroundGrid: selection.backgroundGrid,
            selectBackgroundGrid,

            projects: selection.projects,
            selectProjects,
            unselectProjects,

            aspectRatio: selection.aspectRatio,
            setAspectRatio,

            disableShadowsAndReflections: selection.disableShadowsAndReflections,
            setDisableShadowsAndReflections,
        },
    };
}
