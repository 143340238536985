import React, { ReactElement } from 'react';

import { Size } from '../../types/size';

type Props = {
    itemCount: number;
    canvasSize: Size;
};

export default function BackgroundGrid(props: Props): ReactElement {
    const { itemCount, canvasSize } = props;

    const items = Array.from({ length: itemCount }, (_, index) => ({ id: index + 1 }));

    return (
        <div>
            {items.map((item) => (
                <div key={`${item.id}-y`}>
                    <div
                        key={`${item.id}-y`}
                        className='absolute z-10 h-full w-[2px] bg-black opacity-25'
                        style={{ left: `${(canvasSize.width / itemCount) * item.id}px` }}
                    />
                    <div
                        key={`${item.id}-x`}
                        className='absolute z-10 h-[2px] w-full bg-black opacity-25'
                        style={{ top: `${(canvasSize.width / itemCount) * item.id}px` }}
                    />
                </div>
            ))}
        </div>
    );
}
